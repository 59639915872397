import { SvgIcon } from './svg-icon';
import { SvgIconProps } from './svg-icon.d';
import { mergeProps } from 'solid-js';

const defaultProps: SvgIconProps = {
    viewBox: '0 0 71 40',
    width: 4.67,
    height: 2.27,
};

export const AtosLogoPdfFooter = (componentProps: SvgIconProps) => {
    const props = mergeProps(defaultProps, componentProps);

    return (
        <SvgIcon {...props}>

            <g clip-path="url(#clip0_1_51)">
                <rect width="71" height="40" fill="white" />
                <path id="Vector" d="M3.08 32.06C3.08 32.72 2.55 33.15 1.87 33.15H0.259998V29.29H1.71C2.44 29.29 2.94 29.64 2.94 30.3C2.94 30.97 2.34 31.16 2.34 31.16C2.34 31.16 3.08 31.33 3.08 32.06ZM0.91 29.87V30.95H1.63C2 30.95 2.29 30.71 2.29 30.39C2.29 30.07 2.04 29.87 1.62 29.87H0.91ZM2.4 32.01C2.4 31.7 2.17 31.49 1.8 31.49H0.92V32.56H1.78C2.14 32.56 2.4 32.32 2.4 32.01Z" fill="#918787" />
                <path id="Vector_2" d="M3.85 33.15V30.63H4.44V31.09C4.44 31.09 4.62 30.58 5.11 30.58C5.28 30.58 5.4 30.61 5.4 30.61V31.26C5.4 31.26 5.25 31.23 5.13 31.23C4.69 31.24 4.46 31.6 4.46 32V33.15H3.85Z" fill="#918787" />
                <path id="Vector_3" d="M7 30.58C7.75 30.58 8.23 31.06 8.23 31.84C8.23 31.97 8.22 32.06 8.22 32.06H6.4C6.42 32.42 6.73 32.66 7.23 32.66C7.74 32.66 8.09 32.42 8.09 32.42V32.99C8.09 32.99 7.76 33.19 7.2 33.2C6.33 33.21 5.78 32.67 5.78 31.89C5.78 31.09 6.36 30.58 7 30.58ZM7.61 31.64C7.6 31.35 7.41 31.09 7.01 31.09C6.65 31.09 6.43 31.38 6.41 31.64H7.61Z" fill="#918787" />
                <path id="Vector_4" d="M10.79 32.78C10.76 32.83 10.54 33.2 9.96 33.2C9.26 33.2 8.7 32.66 8.7 31.88C8.7 31.09 9.27 30.58 9.94 30.58C10.49 30.58 10.75 30.93 10.77 30.99V30.63H11.37V33.15H10.79V32.78ZM10.04 32.67C10.46 32.67 10.77 32.32 10.77 31.9C10.77 31.47 10.47 31.12 10.05 31.12C9.64 31.12 9.33 31.45 9.33 31.89C9.33 32.31 9.62 32.67 10.04 32.67Z" fill="#918787" />
                <path id="Vector_5" d="M12.39 30.4V30.05H12.96V30.63H13.64V31.13H12.96V32.31C12.96 32.56 13.1 32.65 13.32 32.65C13.46 32.65 13.64 32.58 13.64 32.58V33.13C13.64 33.13 13.39 33.21 13.13 33.2C12.56 33.19 12.35 32.91 12.35 32.34V31.13H11.87V30.63H12.19C12.36 30.63 12.39 30.59 12.39 30.4Z" fill="#918787" />
                <path id="Vector_6" d="M16.57 31.54V33.15H15.92V31.72C15.92 31.37 15.73 31.16 15.41 31.16C15.1 31.16 14.86 31.45 14.84 31.77V33.16H14.22V29.3H14.84V31.02C14.91 30.91 15.15 30.6 15.63 30.6C16.15 30.58 16.57 30.94 16.57 31.54Z" fill="#918787" />
                <path id="Vector_7" d="M17.23 29.63C17.23 29.39 17.4 29.24 17.62 29.24C17.85 29.24 18.01 29.39 18.01 29.63C18.01 29.86 17.84 30.02 17.61 30.02C17.39 30.02 17.23 29.86 17.23 29.63ZM17.31 33.15V30.63H17.93V33.15H17.31Z" fill="#918787" />
                <path id="Vector_8" d="M21.05 31.54V33.15H20.4V31.72C20.4 31.37 20.21 31.16 19.89 31.16C19.56 31.16 19.32 31.47 19.32 31.81V33.16H18.7V30.64H19.3V31.05C19.3 31.05 19.53 30.59 20.11 30.59C20.63 30.58 21.05 30.94 21.05 31.54Z" fill="#918787" />
                <path id="Vector_9" d="M24.29 33.15C24.28 33.71 23.77 34.32 22.89 34.32C22.31 34.32 21.89 34.01 21.89 34.01V33.38C21.89 33.38 22.31 33.78 22.88 33.78C23.41 33.77 23.7 33.38 23.7 33.1V32.79C23.65 32.87 23.42 33.2 22.88 33.2C22.18 33.2 21.62 32.66 21.62 31.88C21.62 31.09 22.19 30.58 22.86 30.58C23.41 30.58 23.67 30.93 23.69 30.99V30.63H24.29V33.15ZM23.69 31.9C23.69 31.47 23.39 31.12 22.97 31.12C22.56 31.12 22.25 31.45 22.25 31.89C22.25 32.3 22.54 32.67 22.97 32.67C23.38 32.67 23.69 32.32 23.69 31.9Z" fill="#918787" />
                <path id="Vector_10" d="M25.09 31.89V31.38H27.57V31.89H25.09Z" fill="#918787" />
                <path id="Vector_11" d="M29.71 29.82C29.27 29.82 29 30.04 29 30.3C29 30.63 29.33 30.73 29.73 30.86C30.3 31.06 30.99 31.26 30.99 32.05C30.99 32.72 30.4 33.19 29.59 33.19C28.83 33.19 28.4 32.85 28.4 32.85V32.12C28.4 32.12 28.85 32.58 29.58 32.58C29.93 32.58 30.33 32.42 30.33 32.07C30.33 31.7 29.92 31.55 29.43 31.4C28.91 31.24 28.34 31.02 28.34 30.28C28.34 29.62 28.94 29.2 29.69 29.2C30.42 29.2 30.79 29.52 30.79 29.52V30.2C30.8 30.22 30.32 29.82 29.71 29.82Z" fill="#918787" />
                <path id="Vector_12" d="M34.39 31.87C34.39 32.65 33.83 33.19 33.13 33.19C32.63 33.19 32.4 32.92 32.32 32.81V34.38H31.72V30.62H32.32V30.98C32.34 30.92 32.6 30.57 33.15 30.57C33.82 30.58 34.39 31.09 34.39 31.87ZM33.77 31.89C33.77 31.45 33.46 31.12 33.05 31.12C32.63 31.12 32.33 31.47 32.33 31.9C32.33 32.32 32.63 32.67 33.06 32.67C33.48 32.67 33.77 32.31 33.77 31.89Z" fill="#918787" />
                <path id="Vector_13" d="M36.08 30.58C36.83 30.58 37.31 31.06 37.31 31.84C37.31 31.97 37.3 32.06 37.3 32.06H35.48C35.5 32.42 35.81 32.66 36.31 32.66C36.82 32.66 37.17 32.42 37.17 32.42V32.99C37.17 32.99 36.84 33.19 36.28 33.2C35.41 33.21 34.87 32.67 34.87 31.89C34.86 31.09 35.44 30.58 36.08 30.58ZM36.69 31.64C36.68 31.35 36.49 31.09 36.09 31.09C35.74 31.09 35.51 31.38 35.49 31.64H36.69Z" fill="#918787" />
                <path id="Vector_14" d="M39.87 32.78C39.84 32.83 39.62 33.2 39.04 33.2C38.34 33.2 37.78 32.66 37.78 31.88C37.78 31.09 38.35 30.58 39.02 30.58C39.57 30.58 39.83 30.93 39.85 30.99V30.63H40.45V33.15H39.87V32.78ZM39.12 32.67C39.54 32.67 39.85 32.32 39.85 31.9C39.85 31.47 39.55 31.12 39.13 31.12C38.72 31.12 38.41 31.45 38.41 31.89C38.4 32.31 38.69 32.67 39.12 32.67Z" fill="#918787" />
                <path id="Vector_15" d="M42.83 33.15L41.79 31.98V33.15H41.17V29.29H41.79V31.71L42.77 30.64H43.59L42.45 31.86L43.63 33.16H42.83V33.15Z" fill="#918787" />
                <path id="Vector_16" d="M44.07 29.63C44.07 29.39 44.24 29.24 44.46 29.24C44.69 29.24 44.85 29.39 44.85 29.63C44.85 29.86 44.68 30.02 44.45 30.02C44.23 30.02 44.07 29.86 44.07 29.63ZM44.15 33.15V30.63H44.77V33.15H44.15Z" fill="#918787" />
                <path id="Vector_17" d="M47.89 31.54V33.15H47.24V31.72C47.24 31.37 47.05 31.16 46.73 31.16C46.4 31.16 46.16 31.47 46.16 31.81V33.16H45.54V30.64H46.14V31.05C46.14 31.05 46.37 30.59 46.95 30.59C47.47 30.58 47.89 30.94 47.89 31.54Z" fill="#918787" />
                <path id="Vector_18" d="M51.13 33.15C51.12 33.71 50.61 34.32 49.73 34.32C49.15 34.32 48.73 34.01 48.73 34.01V33.38C48.73 33.38 49.16 33.78 49.72 33.78C50.25 33.77 50.54 33.38 50.54 33.1V32.79C50.49 32.87 50.26 33.2 49.72 33.2C49.02 33.2 48.46 32.66 48.46 31.88C48.46 31.09 49.03 30.58 49.7 30.58C50.25 30.58 50.51 30.93 50.53 30.99V30.63H51.13V33.15ZM50.53 31.9C50.53 31.47 50.23 31.12 49.81 31.12C49.4 31.12 49.09 31.45 49.09 31.89C49.09 32.3 49.38 32.67 49.81 32.67C50.22 32.67 50.53 32.32 50.53 31.9Z" fill="#918787" />
                <path id="Vector_19" d="M51.93 31.89V31.38H54.41V31.89H51.93Z" fill="#918787" />
                <path id="Vector_20" d="M57.88 32.55V33.15H55.42V29.29H56.07V32.55H57.88Z" fill="#918787" />
                <path id="Vector_21" d="M58.45 29.63C58.45 29.39 58.62 29.24 58.84 29.24C59.07 29.24 59.23 29.39 59.23 29.63C59.23 29.86 59.06 30.02 58.83 30.02C58.61 30.02 58.45 29.86 58.45 29.63ZM58.53 33.15V30.63H59.15V33.15H58.53Z" fill="#918787" />
                <path id="Vector_22" d="M60.36 30.63L61.03 32.51L61.71 30.63H62.41L61.36 33.15H60.7L59.69 30.63H60.36Z" fill="#918787" />
                <path id="Vector_23" d="M62.85 29.63C62.85 29.39 63.02 29.24 63.24 29.24C63.47 29.24 63.63 29.39 63.63 29.63C63.63 29.86 63.46 30.02 63.23 30.02C63.01 30.02 62.85 29.86 62.85 29.63ZM62.93 33.15V30.63H63.55V33.15H62.93Z" fill="#918787" />
                <path id="Vector_24" d="M66.67 31.54V33.15H66.02V31.72C66.02 31.37 65.83 31.16 65.51 31.16C65.18 31.16 64.94 31.47 64.94 31.81V33.16H64.32V30.64H64.92V31.05C64.92 31.05 65.15 30.59 65.73 30.59C66.25 30.58 66.67 30.94 66.67 31.54Z" fill="#918787" />
                <path id="Vector_25" d="M69.92 33.15C69.91 33.71 69.4 34.32 68.52 34.32C67.94 34.32 67.52 34.01 67.52 34.01V33.38C67.52 33.38 67.94 33.78 68.51 33.78C69.04 33.77 69.33 33.38 69.33 33.1V32.79C69.28 32.87 69.05 33.2 68.51 33.2C67.81 33.2 67.25 32.66 67.25 31.88C67.25 31.09 67.82 30.58 68.49 30.58C69.04 30.58 69.3 30.93 69.32 30.99V30.63H69.92V33.15ZM69.31 31.9C69.31 31.47 69.01 31.12 68.59 31.12C68.18 31.12 67.87 31.45 67.87 31.89C67.87 32.3 68.16 32.67 68.59 32.67C69.01 32.67 69.31 32.32 69.31 31.9Z" fill="#918787" />
                <path id="Vector_26" d="M2.41 38.9C2.32 39.1 2.02 39.52 1.36 39.52C0.679999 39.52 0.119999 38.95 0.119999 38.19C0.119999 37.43 0.679999 36.89 1.39 36.89C1.94 36.89 2.31 37.27 2.41 37.49V36.95H2.67V39.47H2.41V38.9ZM1.37 39.28C1.97 39.28 2.4 38.82 2.4 38.24C2.4 37.63 2 37.17 1.4 37.17C0.86 37.17 0.379999 37.57 0.379999 38.21C0.389999 38.75 0.779999 39.28 1.37 39.28Z" fill="#918787" />
                <path id="Vector_27" d="M3.75 36.82V36.38H4.01V36.96H4.79V37.2H4.01V38.78C4.01 39.14 4.12 39.28 4.42 39.28C4.58 39.28 4.75 39.22 4.75 39.22V39.48C4.75 39.48 4.55 39.53 4.36 39.53C3.86 39.52 3.72 39.26 3.72 38.79V37.2H3.18L3.19 36.96H3.62C3.72 36.96 3.75 36.93 3.75 36.82Z" fill="#918787" />
                <path id="Vector_28" d="M5.11 38.22C5.11 37.48 5.71 36.91 6.43 36.91C7.15 36.91 7.75 37.48 7.75 38.22C7.75 38.96 7.15 39.53 6.43 39.53C5.71 39.53 5.11 38.96 5.11 38.22ZM7.48 38.22C7.48 37.62 7 37.19 6.44 37.19C5.86 37.19 5.39 37.63 5.39 38.22C5.39 38.81 5.86 39.26 6.43 39.26C7 39.26 7.48 38.8 7.48 38.22Z" fill="#918787" />
                <path id="Vector_29" d="M9.13 39.28C9.39 39.28 9.77 39.15 9.77 38.83C9.77 38.48 9.45 38.42 9.06 38.27C8.62 38.1 8.28 38.02 8.28 37.58C8.28 37.14 8.66 36.91 9.19 36.91C9.74 36.91 9.97 37.11 9.97 37.11V37.41C9.97 37.41 9.74 37.16 9.2 37.16C8.75 37.16 8.56 37.34 8.56 37.58C8.56 37.84 8.78 37.89 9.17 38.04C9.61 38.22 10.05 38.31 10.05 38.84C10.05 39.32 9.6 39.54 9.12 39.54C8.57 39.54 8.28 39.3 8.28 39.3V38.98C8.29 38.97 8.59 39.28 9.13 39.28Z" fill="#918787" />
                <path id="Vector_30" d="M10.78 36.96H11.05V37.51C11.05 37.51 11.28 36.91 11.87 36.91C12.59 36.91 12.68 37.5 12.68 37.5C12.68 37.5 12.93 36.91 13.57 36.91C14.11 36.91 14.49 37.2 14.49 37.86V39.49H14.22V37.91C14.22 37.4 13.93 37.18 13.55 37.18C13.08 37.19 12.79 37.63 12.79 37.99V39.49H12.52V37.91C12.52 37.4 12.22 37.18 11.84 37.18C11.35 37.19 11.07 37.71 11.07 38.06V39.49H10.78V36.96Z" fill="#918787" />
                <path id="Vector_31" d="M16.33 36.9C17 36.9 17.51 37.37 17.51 38.12C17.51 38.22 17.49 38.31 17.49 38.31H15.4C15.42 38.9 15.96 39.26 16.47 39.26C17.01 39.26 17.36 39.02 17.36 39.02V39.32C17.36 39.32 17.06 39.52 16.47 39.53C15.75 39.54 15.12 39.01 15.12 38.21C15.13 37.42 15.67 36.9 16.33 36.9ZM17.24 38.09C17.23 37.53 16.85 37.16 16.34 37.16C15.85 37.16 15.45 37.56 15.43 38.09H17.24Z" fill="#918787" />
                <path id="Vector_32" d="M20.6 39.47H20.34V38.9C20.25 39.1 19.95 39.52 19.29 39.52C18.61 39.52 18.05 38.95 18.05 38.19C18.05 37.43 18.61 36.89 19.33 36.89C19.86 36.89 20.22 37.24 20.34 37.46V35.6H20.61V39.47H20.6ZM20.33 38.24C20.33 37.63 19.93 37.17 19.33 37.17C18.79 37.17 18.31 37.57 18.31 38.21C18.31 38.76 18.7 39.28 19.3 39.28C19.9 39.28 20.33 38.82 20.33 38.24Z" fill="#918787" />
                <path id="Vector_33" d="M21.39 35.79C21.39 35.66 21.49 35.56 21.62 35.56C21.75 35.56 21.85 35.66 21.85 35.79C21.85 35.93 21.75 36.03 21.62 36.03C21.49 36.03 21.39 35.92 21.39 35.79ZM21.48 39.47V36.95H21.76V39.47H21.48Z" fill="#918787" />
                <path id="Vector_34" d="M23.89 37.18C23.24 37.18 22.76 37.6 22.76 38.22C22.76 38.85 23.24 39.25 23.85 39.27C24.32 39.28 24.66 39.04 24.66 39.04V39.33C24.66 39.33 24.37 39.53 23.85 39.53C23.09 39.53 22.47 39.03 22.47 38.23C22.47 37.43 23.05 36.91 23.87 36.91C24.34 36.91 24.65 37.12 24.65 37.12V37.42C24.65 37.41 24.35 37.18 23.89 37.18Z" fill="#918787" />
                <path id="Vector_35" d="M27.39 38.9C27.3 39.1 27 39.52 26.34 39.52C25.66 39.52 25.1 38.95 25.1 38.19C25.1 37.43 25.66 36.89 26.38 36.89C26.93 36.89 27.3 37.27 27.4 37.49V36.95H27.66V39.47H27.4V38.9H27.39ZM26.36 39.28C26.96 39.28 27.39 38.82 27.39 38.24C27.39 37.63 26.99 37.17 26.39 37.17C25.85 37.17 25.37 37.57 25.37 38.21C25.37 38.75 25.77 39.28 26.36 39.28Z" fill="#918787" />
                <path id="Vector_36" d="M28.46 39.47V35.61H28.73V39.47H28.46Z" fill="#918787" />
                <path id="Vector_37" d="M29.66 39.3C29.66 39.17 29.77 39.06 29.9 39.06C30.03 39.06 30.13 39.17 30.13 39.3C30.13 39.43 30.03 39.54 29.89 39.54C29.76 39.53 29.66 39.43 29.66 39.3Z" fill="#918787" />
                <path id="Vector_38" d="M32.24 37.18C31.59 37.18 31.11 37.6 31.11 38.22C31.11 38.85 31.59 39.25 32.2 39.27C32.67 39.28 33.01 39.04 33.01 39.04V39.33C33.01 39.33 32.72 39.53 32.2 39.53C31.44 39.53 30.82 39.03 30.82 38.23C30.82 37.43 31.4 36.91 32.22 36.91C32.69 36.91 33 37.12 33 37.12V37.42C33 37.41 32.71 37.18 32.24 37.18Z" fill="#918787" />
                <path id="Vector_39" d="M33.44 38.22C33.44 37.48 34.04 36.91 34.76 36.91C35.48 36.91 36.08 37.48 36.08 38.22C36.08 38.96 35.48 39.53 34.76 39.53C34.04 39.53 33.44 38.96 33.44 38.22ZM35.81 38.22C35.81 37.62 35.33 37.19 34.77 37.19C34.19 37.19 33.72 37.63 33.72 38.22C33.72 38.81 34.19 39.26 34.76 39.26C35.33 39.26 35.81 38.8 35.81 38.22Z" fill="#918787" />
                <path id="Vector_40" d="M36.78 36.96H37.05V37.51C37.05 37.51 37.28 36.91 37.87 36.91C38.59 36.91 38.68 37.5 38.68 37.5C38.68 37.5 38.93 36.91 39.57 36.91C40.11 36.91 40.49 37.2 40.49 37.86V39.49H40.22V37.91C40.22 37.4 39.93 37.18 39.55 37.18C39.08 37.19 38.79 37.63 38.79 37.99V39.49H38.52V37.91C38.52 37.4 38.22 37.18 37.84 37.18C37.35 37.19 37.07 37.71 37.07 38.06V39.49H36.78V36.96Z" fill="#918787" />
                <path id="Vector_41" d="M25.33 5.5V0.330002H30.22V5.5H34.78V9.61H30.22V16.92C30.22 18.47 31.28 18.99 32.63 18.99C33.57 18.99 34.85 18.46 34.85 18.46V22.98C34.85 22.98 32.93 23.55 31.01 23.51C26.86 23.43 25.21 21.48 25.21 17.22V9.61H21.93V5.5H25.33Z" fill="#F0414B" />
                <path id="Vector_42" d="M36.55 14.36C36.55 8.82 40.77 5.2 46.08 5.2C51.39 5.2 55.65 8.82 55.65 14.36C55.65 19.9 51.39 23.52 46.08 23.52C40.77 23.51 36.55 19.89 36.55 14.36ZM50.61 14.32C50.61 11.57 48.61 9.87 46.16 9.87C43.67 9.87 41.6 11.57 41.6 14.32C41.6 17 43.6 18.99 46.08 18.99C48.54 18.99 50.61 16.99 50.61 14.32Z" fill="#F0414B" />
                <path id="Vector_43" d="M63.6 19.48C64.81 19.48 65.6 18.91 65.6 18.05C65.6 16.99 64.92 16.73 62.66 15.98C59.57 14.96 57.54 14.28 57.54 10.52C57.54 7.54 60.03 5.13 64.32 5.13C68.35 5.13 69.9 6.3 69.9 6.3V10.82C69.9 10.82 67.71 9.24 64.81 9.24C63.38 9.24 62.62 9.77 62.62 10.41C62.62 11.16 63.11 11.69 65.3 12.33C68.54 13.31 70.88 14.55 70.88 17.98C70.88 20.69 68.73 23.56 63.49 23.56C59.99 23.56 57.61 22.2 57.61 22.2V17.15C57.61 17.14 60.06 19.48 63.6 19.48Z" fill="#F0414B" />
                <path id="Vector_44" d="M5 23.13L6.13 17.82H15.74L16.87 23.13H21.86L18.46 6.94C17.41 1.92 14.38 0 10.93 0C7.56 0 4.24 1.9 3.4 6.94L0 23.13H5ZM8.21 7.34C8.64 5.52 9.73 4.88 10.93 4.88C12.06 4.88 13.18 5.37 13.65 7.34L14.76 13.22H7.11L8.21 7.34Z" fill="#F0414B" />
            </g>
        </SvgIcon>
    );
};