export const getYoutubeVideoId = (url: string) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/; // eslint-disable-line
    const match = url.match(regExp);

    if (match && match[2].length === 11) {
        return match[2];
    } else {
        return;
    }
};

export const getVimeoVideoId = (url: string) => {
    const regEx = /(https?:\/\/)?(www\.)?(player\.)?vimeo\.com\/?(showcase\/)*([0-9))([a-z]*\/)*([0-9]{6,11})[?]?.*?(\/)?([a-zA-Z0-9]*)?/;
    
    const match = url.match(regEx);
    
    if (match && match.length == 9 && !match[8]) {
        return match[6];
    } else if (match && match[8]) {
        return `${match[6]}?h=${match[8]}`;
    } else {
        return;
    }
};
