export const AtosLogoRelative = () => {
    return (
        <svg viewBox='0 0 283.46 144.83' width='50%' height='auto'>
            <path class="cls-1" fill="#918787" d="M18.69,128.89c0,2.49-2,4.15-4.61,4.15H8V118.36h5.52c2.78,0,4.66,1.33,4.66,3.84a3.44,3.44,0,0,1-2.29,3.27A3.67,3.67,0,0,1,18.69,128.89Zm-8.27-8.31v4.11h2.72a2.29,2.29,0,0,0,2.5-2.14c0-1.23-.94-2-2.56-2Zm5.65,8.14a2,2,0,0,0-2.29-2H10.42v4H13.7A2.16,2.16,0,0,0,16.07,128.72Z" />
            <path class="cls-1" fill="#918787" d="M21.59,133v-9.57h2.25v1.74a3,3,0,0,1,2.56-1.95,4.09,4.09,0,0,1,1.08.13v2.45a6.82,6.82,0,0,0-1-.1,2.66,2.66,0,0,0-2.54,2.94V133Z" />
            <path class="cls-1" fill="#918787" d="M33.57,123.26c2.86,0,4.68,1.82,4.68,4.79a8.19,8.19,0,0,1,0,.84H31.3c.08,1.37,1.25,2.29,3.15,2.29a6.21,6.21,0,0,0,3.27-.92v2.16a7,7,0,0,1-3.39.8c-3.32,0-5.38-2-5.38-5A4.72,4.72,0,0,1,33.57,123.26Zm2.33,4a2.12,2.12,0,0,0-2.27-2.1,2.28,2.28,0,0,0-2.27,2.1Z" />
            <path class="cls-1" fill="#918787" d="M48,131.63a3.49,3.49,0,0,1-3.15,1.59,4.79,4.79,0,0,1-4.78-5,4.7,4.7,0,0,1,4.7-4.93,3.53,3.53,0,0,1,3.17,1.58v-1.37h2.27V133H48Zm-2.84-.41a3,3,0,0,0,0-5.89,2.78,2.78,0,0,0-2.74,2.92A2.82,2.82,0,0,0,45.12,131.22Z" />
            <path class="cls-1" fill="#918787" d="M54.05,122.59v-1.33h2.16v2.21h2.58v1.9H56.21v4.48c0,1,.54,1.31,1.38,1.31a3.93,3.93,0,0,0,1.2-.27V133a7.1,7.1,0,0,1-1.92.27c-2.17,0-3-1.1-3-3.27v-4.6H52.06v-1.9h1.23C54,123.47,54.05,123.3,54.05,122.59Z" />
            <path class="cls-1" fill="#918787" d="M69.93,126.9V133H67.48V127.6a1.89,1.89,0,0,0-1.93-2.15,2.32,2.32,0,0,0-2.16,2.31V133H61V118.36h2.35v6.52a3.56,3.56,0,0,1,6.54,2Z" />
            <path class="cls-1" fill="#918787" d="M72.45,119.67a1.41,1.41,0,0,1,1.49-1.5,1.39,1.39,0,0,1,1.47,1.5,1.43,1.43,0,0,1-1.53,1.47A1.4,1.4,0,0,1,72.45,119.67Zm.3,13.37v-9.59h2.36V133Z" />
            <path class="cls-1" fill="#918787" d="M87,126.9V133H84.5V127.6c0-1.33-.71-2.15-1.92-2.15a2.35,2.35,0,0,0-2.17,2.47V133H78.06v-9.57h2.27V125A3.55,3.55,0,0,1,87,126.9Z" />
            <path class="cls-1" fill="#918787" d="M99.28,133c0,2.14-2,4.45-5.32,4.45a7.15,7.15,0,0,1-3.8-1.16v-2.42a6,6,0,0,0,3.76,1.52,3,3,0,0,0,3.13-2.6v-1.16a3.52,3.52,0,0,1-3.13,1.55,4.79,4.79,0,0,1-4.78-5,4.7,4.7,0,0,1,4.7-4.93A3.53,3.53,0,0,1,97,124.84v-1.37h2.27ZM97,128.29a2.75,2.75,0,1,0-5.48,0,2.75,2.75,0,1,0,5.48,0Z" />
            <path class="cls-1" fill="#918787" d="M102.32,128.25v-1.92h9.42v1.92Z" />
            <path class="cls-1" fill="#918787" d="M119.85,120.38c-1.68,0-2.68.84-2.68,1.84,0,1.27,1.25,1.62,2.76,2.15,2.17.75,4.81,1.51,4.81,4.54,0,2.55-2.25,4.31-5.34,4.31a7.72,7.72,0,0,1-4.52-1.29v-2.76a6.71,6.71,0,0,0,4.5,1.76c1.33,0,2.86-.61,2.86-1.92s-1.55-2-3.41-2.54-4.15-1.43-4.15-4.27c0-2.51,2.29-4.09,5.13-4.09a6.77,6.77,0,0,1,4.19,1.21v2.59A7.18,7.18,0,0,0,119.85,120.38Z" />
            <path class="cls-1" fill="#918787" d="M137.65,128.19a4.78,4.78,0,0,1-4.78,5,3.6,3.6,0,0,1-3.07-1.45v6h-2.29V123.47h2.27v1.37a3.57,3.57,0,0,1,3.17-1.58A4.7,4.7,0,0,1,137.65,128.19Zm-2.37.06a2.75,2.75,0,1,0-2.71,3A2.81,2.81,0,0,0,135.28,128.25Z" />
            <path class="cls-1" fill="#918787" d="M144.07,123.26c2.86,0,4.68,1.82,4.68,4.79a8.19,8.19,0,0,1,0,.84H141.8c.08,1.37,1.25,2.29,3.15,2.29a6.21,6.21,0,0,0,3.27-.92v2.16a7,7,0,0,1-3.39.8c-3.32,0-5.38-2-5.38-5A4.72,4.72,0,0,1,144.07,123.26Zm2.33,4a2.12,2.12,0,0,0-2.27-2.1,2.28,2.28,0,0,0-2.27,2.1Z" />
            <path class="cls-1" fill="#918787" d="M158.46,131.63a3.49,3.49,0,0,1-3.15,1.59,4.79,4.79,0,0,1-4.79-5,4.7,4.7,0,0,1,4.71-4.93,3.57,3.57,0,0,1,3.17,1.58v-1.37h2.26V133h-2.2Zm-2.84-.41a3,3,0,0,0,0-5.89,2.77,2.77,0,0,0-2.74,2.92A2.81,2.81,0,0,0,155.62,131.22Z" />
            <path class="cls-1" fill="#918787" d="M169.72,133l-3.95-4.46V133h-2.35V118.36h2.35v9.18l3.74-4.07h3.11l-4.31,4.64,4.47,4.93Z" />
            <path class="cls-1" fill="#918787" d="M174.44,119.67a1.41,1.41,0,0,1,1.49-1.5,1.39,1.39,0,0,1,1.47,1.5,1.43,1.43,0,0,1-1.53,1.47A1.4,1.4,0,0,1,174.44,119.67Zm.31,13.37v-9.59h2.35V133Z" />
            <path class="cls-1" fill="#918787" d="M189,126.9V133h-2.46V127.6c0-1.33-.71-2.15-1.92-2.15a2.35,2.35,0,0,0-2.17,2.47V133h-2.35v-9.57h2.27V125A3.56,3.56,0,0,1,189,126.9Z" />
            <path class="cls-1" fill="#918787" d="M201.27,133c0,2.14-2,4.45-5.31,4.45a7.16,7.16,0,0,1-3.81-1.16v-2.42a6,6,0,0,0,3.77,1.52,3,3,0,0,0,3.12-2.6v-1.16a3.51,3.51,0,0,1-3.12,1.55,4.79,4.79,0,0,1-4.79-5,4.7,4.7,0,0,1,4.7-4.93,3.53,3.53,0,0,1,3.17,1.58v-1.37h2.27ZM199,128.29a2.75,2.75,0,1,0-5.48,0,2.75,2.75,0,1,0,5.48,0Z" />
            <path class="cls-1" fill="#918787" d="M204.31,128.25v-1.92h9.43v1.92Z" />
            <path class="cls-1" fill="#918787" d="M226.91,130.75V133h-9.35V118.36H220v12.39Z" />
            <path class="cls-1" fill="#918787" d="M229.09,119.67a1.41,1.41,0,0,1,1.49-1.5,1.39,1.39,0,0,1,1.47,1.5,1.43,1.43,0,0,1-1.53,1.47A1.4,1.4,0,0,1,229.09,119.67Zm.3,13.37v-9.59h2.35V133Z" />
            <path class="cls-1" fill="#918787" d="M236.33,123.47l2.54,7.13,2.57-7.13h2.66l-4,9.57H237.6l-3.85-9.57Z" />
            <path class="cls-1" fill="#918787" d="M245.81,119.67a1.41,1.41,0,0,1,1.49-1.5,1.4,1.4,0,0,1,1.48,1.5,1.44,1.44,0,0,1-1.54,1.47A1.4,1.4,0,0,1,245.81,119.67Zm.31,13.37v-9.59h2.35V133Z" />
            <path class="cls-1" fill="#918787" d="M260.32,126.9V133h-2.46V127.6c0-1.33-.71-2.15-1.92-2.15a2.35,2.35,0,0,0-2.17,2.47V133h-2.35v-9.57h2.27V125a3.56,3.56,0,0,1,6.63,1.88Z" />
            <path class="cls-1" fill="#918787" d="M272.64,133c0,2.14-2,4.45-5.31,4.45a7.11,7.11,0,0,1-3.8-1.16v-2.42a6,6,0,0,0,3.76,1.52,3,3,0,0,0,3.12-2.6v-1.16a3.51,3.51,0,0,1-3.12,1.55,4.79,4.79,0,0,1-4.79-5,4.7,4.7,0,0,1,4.7-4.93,3.53,3.53,0,0,1,3.17,1.58v-1.37h2.27Zm-2.29-4.75a2.74,2.74,0,1,0-2.76,2.93A2.79,2.79,0,0,0,270.35,128.29Z" />
            <path class="cls-2" fill="#f0414b" d="M103.23,28V8.32h18.6V28h17.32V43.57H121.83V71.35c0,5.88,4,7.88,9.16,7.88,3.58,0,8.45-2,8.45-2V94.41a55.28,55.28,0,0,1-14.61,2c-15.75-.28-22-7.73-22-23.91V43.57H90.32V28Z" />
            <path class="cls-2" fill="#f0414b" d="M145.86,61.62c0-21,16-34.8,36.23-34.8s36.37,13.75,36.37,34.8-16.18,34.79-36.37,34.79S145.86,82.67,145.86,61.62Zm53.42-.15c0-10.45-7.59-16.89-16.9-16.89S165.05,51,165.05,61.47c0,10.17,7.59,17.76,17,17.76A17.37,17.37,0,0,0,199.28,61.47Z" />
            <path class="cls-2" fill="#f0414b" d="M248.64,81.09c4.58,0,7.59-2.15,7.59-5.44,0-4-2.58-5-11.17-7.88-11.74-3.86-19.48-6.44-19.48-20.76,0-11.31,9.45-20.48,25.78-20.48,15.32,0,21.19,4.44,21.19,4.44V48.16a36.15,36.15,0,0,0-19.33-6c-5.44,0-8.31,2-8.31,4.44,0,2.86,1.87,4.87,10.17,7.3,12.32,3.73,21.2,8.45,21.2,21.48,0,10.31-8.17,21.2-28.07,21.2a48.73,48.73,0,0,1-22.34-5.16V72.21S235.18,81.09,248.64,81.09Z" />
            <path class="cls-2" fill="#f0414b" d="M26,95l4.3-20.18H66.77L71.06,95H90L77.13,33.45c-4-19.09-15.5-26.36-28.62-26.36-12.79,0-25.43,7.2-28.62,26.36L7,95ZM38.18,35C39.81,28,44,25.61,48.51,25.61c4.29,0,8.55,1.87,10.33,9.36l4.21,22.35H34Z" />
        </svg>
    );
};