import { User } from '../../../types/shared';
import { MenuHeader } from '../../menu/menu-mobile/menu-header/menu-header';
import { StyledMenuMobile, StyledMenuMobileTop } from '../../menu/menu-mobile/menu-mobile.styles';
import { Show, useContext } from 'solid-js';
import { StyledLoginMenuSignedInAs, StyledLoginMenuSignedInAsBold, StyledLoginMenuSignedInAsContentMobile } from '../login-menu.styles';
import { StyledVerticalSpace } from '../../../ui-components/utility-style-components/spacing';
import { Button } from '../../../ui-components/button/button';
import { LoginForm } from '../../login-form/login-form';
import { AppContext } from '../../../app-context-provider/app-context-provider';
import { UserOrderHistory } from '../user-order-history';

type LoginMenuMobileProps = {
    user: User | null;
    handleCloseMenuClick: () => void;
    handleSignOutClick: () => void;
};

export const LoginMenuMobile = (props: LoginMenuMobileProps) => {
    const { localize, siteInfo } = useContext(AppContext);

    return (
        <StyledMenuMobile>
            <StyledMenuMobileTop>
                <MenuHeader handleCloseMenuClick={props.handleCloseMenuClick} />
            </StyledMenuMobileTop>
            <Show when={props.user} fallback={<LoginForm />}>
                <StyledLoginMenuSignedInAsContentMobile>
                    <StyledLoginMenuSignedInAs>
                        <StyledLoginMenuSignedInAsBold>
                            {localize('signed-in-as', 'Signed in as')}
                        </StyledLoginMenuSignedInAsBold>
                        <div>{props.user?.email}</div>
                    </StyledLoginMenuSignedInAs>
                    <StyledVerticalSpace size={2} />
                    <Button label="Sign out" onClick={() => props.handleSignOutClick()} />
                </StyledLoginMenuSignedInAsContentMobile>

                <Show when={siteInfo.siteType === 'atos-care'}>
                    <UserOrderHistory />
                </Show>
            </Show>
        </StyledMenuMobile>
    );
};
