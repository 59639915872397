import { For } from 'solid-js';
import { FirstLevelMenuItemLink } from './first-level-menu-item-link/first-level-menu-item-link';
import { FirstLevelMenu } from './first-level-menu/first-level-menu';
import { StyledPrimaryMenu, StyledPrimaryMenuList } from './primary-menu.styles';
import menuStore from '../../../../stores/menu-store';
import type { MenuItem, PrimaryMenuProps } from '../../types/primary-menu-types';

export const PrimaryMenu = (props: PrimaryMenuProps) => {
    const { close } = menuStore;

    return (
        <StyledPrimaryMenu>
            <StyledPrimaryMenuList>
                <For each={props.menu}>
                    {(menuItem: MenuItem) => {
                        if (!menuItem.children) {
                            return (
                                <li>
                                    <FirstLevelMenuItemLink link={menuItem} onLinkClick={close} />
                                </li>
                            );
                        } else {
                            return (
                                <li>
                                    <FirstLevelMenu parent={menuItem} onLinkClick={close} />
                                </li>
                            );
                        }
                    }}
                </For>
            </StyledPrimaryMenuList>
        </StyledPrimaryMenu>
    );
};
