/* eslint-disable indent */
import { styled } from 'solid-styled-components';
import { theme, FlexAlignment } from '../../style/theme';
import { containerQueries } from '../../tools/check-container-support';
import { StyleProps, StyleFlexContainerProps } from './column.d';
import * as Responsive from './column.responsive.style';
import * as Helpers from './helpers';

const {grid} = theme;

export const StyledColumn = styled.div<StyleProps>`
    ${p => {
        const gridBaseProps = {
            bgValue: p.backgroundValue,
            extendBg: p.extendBgColor,
            extendBgColorSide: p.extendBgColorSide,
            withSidebarMenu: p.withSidebarMenu,
        };

        const mobileCssProps = {
            bgImage: p.backgroundImageSrcData?.original,
            bgValue: p.backgroundValue,
        };

        const extendBgColorProps = {
            extendBg: p.extendBgColor,
            bgValue: p.backgroundValue,
            withSidebarMenu: p.withSidebarMenu,
            extendBgColorSide: p.extendBgColorSide,
            combineWithRightColumn: p.combineWithRightColumn,
            bgImage: p.backgroundImageSrcData?.original,
        };

        let borderTopLeftRadius = theme.borderRadius.large;
        let borderTopRightRadius = theme.borderRadius.large;
        let borderBottomRightRadius = theme.borderRadius.large;
        let borderBottomLeftRadius = theme.borderRadius.large;

        let borderTopLeftRadiusMobile = theme.borderRadius.small;
        let borderTopRightRadiusMobile = theme.borderRadius.small;
        let borderBottomRightRadiusMobile = theme.borderRadius.small;
        let borderBottomLeftRadiusMobile = theme.borderRadius.small;

        if (p.combineWithRightColumn) {
            borderTopRightRadius = '0px';
            borderBottomRightRadius = '0px';

            borderTopRightRadiusMobile = '0px';
            borderBottomRightRadiusMobile = '0px';
        }

        if (p.extendBgColor) {
            if (p.extendBgColorSide === 'right') {
                borderTopRightRadius = '0px';
                borderBottomRightRadius = '0px';

                borderTopRightRadiusMobile = '0px';
                borderBottomRightRadiusMobile = '0px';
            } else {
                borderTopLeftRadius = '0px';
                borderBottomLeftRadius = '0px';

                borderTopLeftRadiusMobile = '0px';
                borderBottomLeftRadiusMobile = '0px';
            }
        }

        return `
            ${containerQueries('tabletMin')} {
                column-gap: ${grid.gapSizeTablet}rem;
            }
            *:has(> .atos-column) {
                container-type: inline-size;
            }
            ${p.roundCorners ? `
                &.round-corners {
                    border-top-left-radius: ${borderTopLeftRadius};
                    border-top-right-radius: ${borderTopRightRadius};
                    border-bottom-right-radius: ${borderBottomRightRadius};
                    border-bottom-left-radius: ${borderBottomLeftRadius};
                    ${theme.mediaQueries.mobileMax} {
                        border-top-left-radius: ${borderTopLeftRadiusMobile};
                        border-top-right-radius: ${borderTopRightRadiusMobile};
                        border-bottom-right-radius: ${borderBottomRightRadiusMobile};
                        border-bottom-left-radius: ${borderBottomLeftRadiusMobile};
                    }
                }
            ` : ''}

            ${p.combineWithRightColumn ? `
                &.combine-with-right {
                    margin-right: ${grid.gapSizeMobile * -1}rem;
                    
                    ${containerQueries('mobileMin')} {
                        &.with-image {
                            & + .atos-column {
                                column-gap: ${grid.gapSizeTablet}rem !important;
                                padding-left: 0 !important;
                            }
                        }
                    }
                    ${!p.redDividerEnabled && !p.backgroundImageSrcData?.original || p.backgroundImageSrcData?.original == '' ? `
                        & + :not(.with-image) {
                            ${containerQueries('mobileMin')} {
                                column-gap: 0 !important;
                            }
                            ${containerQueries('tabletMax')} {
                                column-gap: ${grid.gapSizeTablet}rem !important;
                            }
                            ${containerQueries('mobileMax')} {
                                column-gap: ${grid.gapSizeMobile}rem !important;
                            }
    
                            &.no-extend {
                                padding-right: ${grid.gapSizeTablet}rem;
                                ${containerQueries('tabletMax')} {
                                    padding-right: ${grid.gapSizeTablet}rem;
                                }
                                padding-left: 0px;
    
                                ${containerQueries('tabletMax')} {
                                    padding-right: 0px;
                                    padding-left: 0px;
                                }
                            }
    
                            &:last-of-type {
                                ${containerQueries('tabletMax')} {
                                    padding-left: 0px;
                                }
                            }
                        }
                    ` : `
                        & + .atos-column:not(.with-bg-color) {
                            ${containerQueries('tabletMin')} {
                                padding-left: ${grid.gapSizeTablet}rem;
                            }
                        }
                    `}
                    
                    ${containerQueries('tabletMax')} {
                        margin-right: unset;
                        &:not(&:has(+ .with-image)) {
                            margin-bottom: ${grid.gapSizeMobile * -1}rem;
                        }
                        border-bottom-left-radius: 0px;
                        ${p.roundCorners ? `
                            border-top-right-radius: ${theme.borderRadius.large};
                            ${theme.mediaQueries.mobileMax} {
                                border-top-right-radius: ${theme.borderRadius.small};
                            }
                        ` : ''};
                    }

                    &.with-bg-color:has(+ .atos-column + .atos-column) {
                        ${containerQueries('tabletMin')} {
                            column-gap: 0;
                            padding-left: ${theme.grid.gapSizeTablet}rem;
                        }
                    }

                    &.with-bg-color:not(.with-divider) {

                        &:not(:first-child):has(+ .atos-column) {
                            ${containerQueries('tabletMin')} {
                                padding-left: ${theme.grid.gapSize}rem;
                                padding-right: ${theme.grid.gapSize}rem;
                            }
                        }
                        &:first-child:has(+ .atos-column):not(:has(+ .atos-column + .atos-column)) {
                            ${containerQueries('tabletMin')} {
                                column-gap: 0;
                                padding-right: ${theme.grid.gapSizeMobile}rem;
                                padding-left: ${theme.grid.gapSizeTablet}rem;
                            }
                        }
                    }
    
                    &:not(.with-divider) + .atos-column:not(:nth-child(3)) {
                        ${containerQueries('tabletMin')} {
                            padding-left: ${theme.grid.gapSizeMobile}rem;
                        }
                    }
                    & + .atos-column {
                        border-bottom-left-radius: 0px;
                        border-top-left-radius: 0px;
                        margin-left: ${grid.gapSizeMobile * -1}rem;
                        ${containerQueries('tabletMin')} {
                            column-gap: ${theme.grid.gapSizeTablet}rem;
                        }
    
                        ${containerQueries('tabletMax')} {
                            &.round-corners:not(.combine-with-right) {
                                border-bottom-left-radius: ${theme.borderRadius.large};
                                ${theme.mediaQueries.mobileMax} {
                                    border-bottom-left-radius: ${theme.borderRadius.small};
                                }
                            }
                        }
                        ${containerQueries('tabletMax')} {
                            margin-left: unset;
                            margin-top: ${theme.grid.gapSize * -1}rem;
                            padding-bottom: ${theme.grid.gapSize * 2}rem;
                            border-top-right-radius: 0px;
                            ${containerQueries('mobileMax')} {
                                margin-top: ${theme.grid.gapSizeTabletSmall * -1}rem;
                                padding-bottom: ${theme.grid.gapSizeTablet}rem;
                            }
                            &:not(.with-image) {
                                margin-right: unset;
                            }
                            
                            &.round-corners:last-of-type {
                                border-bottom-left-radius: ${theme.borderRadius.large};
                                ${theme.mediaQueries.mobileMax} {
                                    border-bottom-left-radius: ${theme.borderRadius.small};
                                }
                            }
                        }
                    }
                }

            ` : ''}

            background-color: ${theme.palette[p.backgroundValue]};
            ${p.backgroundValue && p.backgroundValue !== 'transparent' ? `
                padding-top: ${grid.standardUnit * 2.5}rem;
                padding-bottom: ${grid.standardUnit * 2.5}rem;
            ` : ''}

            ${p.lockedContent ? `
                padding-top: 2rem;
                padding-bottom: 2rem;
            ` : ''}
            
            ${Helpers.gridBase(gridBaseProps)}
            ${Helpers.gridItems()}
            ${Helpers.bgImage(p.backgroundImageSrcData, p.focusPosition, p.overlayType)}
            ${Helpers.extendBgColor(extendBgColorProps)}
            ${Helpers.redDividerStyleDesktop(p.redDividerEnabled, p.backgroundImageSrcData?.original)}
            ${Responsive.desktopLargeCss(p.backgroundValue)}
            ${Responsive.desktopCss()}
            ${Responsive.tabletCss(p.backgroundImageSrcData?.original, p.backgroundValue)}
            ${Responsive.mobileCss(mobileCssProps)}
        `;
    }}
`;

export const FlexContainer = styled.div<StyleFlexContainerProps>`
    ${p => {
        return `
            container-type: inline-size;
            height: auto;
            display: flex;
            flex-direction: column;
            ${p.horizontalAlignment === 'center' ? `
                align-items: center;
            ` : '' }
            ${p.horizontalAlignment === 'right' ? 'margin-left: auto;' : '' }
            ${p.verticalAlignment ? `justify-content: ${FlexAlignment[p.verticalAlignment]} !important;` : '' }
            ${p.customCss ? p.customCss : ''}
        `;
    }}
`;

export const StyledImg = styled.img`
    opacity: 0;
    position: absolute;
    z-index: -1;
`;
