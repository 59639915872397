import { Show, useContext } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';
import menuStore from '../../stores/menu-store';
import theme from '../../style/theme';
import { LockOpenIcon } from '../icons-library/lock-open';
import { StyledSignedInAs, StyledSignedInAsBold, StyledSignedInStatus } from '../login-menu/login-menu.styles';
import clsx from 'clsx';

type LoginControlsProps = {
    showLoginRegisterLabel?: boolean;
    usePadding?: boolean;
};

export const LoginControls = (props: LoginControlsProps) => {
    const AppState = useContext(AppContext);
    const { openLoginMenu, close } = menuStore;
    const { userState, localize, viewport } = AppState;
    const isMobile = () => viewport.width <= theme.breakpoints.MOBILE;

    const handleClickOpenLoginMenu = () => {
        close();
        openLoginMenu();
    };

    return (
        <>
            <Show
                when={userState.user && !isMobile()}
                fallback={
                    <button
                        class={clsx({ 'py-[0.5rem] px-[1.43rem]': props.usePadding }, 'flex')}
                        tabIndex={0}
                        onClick={handleClickOpenLoginMenu}
                    >
                        <LockOpenIcon
                            altText={localize('header_login_icon', 'Link to Login / Register')}
                            {...(viewport.width < theme.breakpoints.MOBILE
                                ? {
                                    height: 2.5,
                                    width: 2.5,
                                    opacity: 0.7,
                                }
                                : {})}
                        />
                        <Show when={props.showLoginRegisterLabel}>
                            <span onClick={handleClickOpenLoginMenu}>
                                {localize('login-register', 'Login / Register')}
                            </span>
                        </Show>
                    </button>
                }
            >
                <StyledSignedInStatus usePadding={props?.usePadding ? props.usePadding : false}>
                    <StyledSignedInAs onClick={handleClickOpenLoginMenu}>
                        <StyledSignedInAsBold>{localize('signed-in-as', 'Signed in as')}</StyledSignedInAsBold>
                        <div>{userState.user?.email}</div>
                    </StyledSignedInAs>
                </StyledSignedInStatus>
            </Show>
        </>
    );
};
