/* eslint-disable indent */
import { useSearchParams } from '@solidjs/router';
import { gql } from 'graphql-request';
import { useLocation } from '@solidjs/router';

import algoliasearch from 'algoliasearch';
import { createSignal, For, onCleanup, onMount, Show, useContext } from 'solid-js';
import { Portal } from 'solid-js/web';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { Grid } from '../../grid-system/grid/grid';
import { Section } from '../../grid-system/section/section';
import theme from '../../style/theme';
import { ErrorCatcher } from '../../tools/error-catcher';
import { navigateToAnchor } from '../../tools/scroll-to-element';
import { Button } from '../../ui-components/button/button';
import { Heading } from '../../ui-components/heading/heading';
import { Text } from '../../ui-components/text/text';
import { MagnifyingGlassIcon } from '../icons-library/magnifying-glass';
import { Menu } from '../menu/menu';
import { SearchResults } from './search-results';
import {
    StyledButton,
    StyledCategories,
    StyledCategoriesWrapper,
    StyledDropdownItem,
    StyledHeadingsSection,
    StyledResultsDropdown,
    StyledSearchForm,
    StyledSearchFormWrapper,
    StyledSearchInput,
    StyledSearchWrapper,
} from './search.style';
import removeAmpersand from '../../tools/remove-ampersand';
import { SeoSettings } from '../seo-settings/seo-settings';

export const GET_PAGE_QUERY = gql`
    query GetPage($key: String!) {
        page: resource(key: $key) {
            content
            key
        }
    }
`;

export const Search = () => {
    const AppState = useContext(AppContext);
    const { localize, createCachedResource, algoliaSettings, siteInfo, searchPage } = useContext(AppContext);
    const location = useLocation();

    const [pageContent] = createCachedResource(GET_PAGE_QUERY, () => ({ key: location.pathname }), true);

    const { searchAppId, searchApiClientKey } = algoliaSettings;
    if (!searchAppId || !searchApiClientKey) {
        return <div>No searchAppId / searchApiClientKey was found..</div>;
    }

    const { siteId, target } = siteInfo;
    if (!siteId || !target) {
        return <div>No siteId / target was found..</div>;
    }

    const [searchParams, setSearchParams] = useSearchParams();

    const client = algoliasearch(searchAppId, searchApiClientKey); // search only api key

    const isMobile = () => AppState.viewport.width <= theme.breakpoints.MOBILE;

    const [searchText, setSearchText] = createSignal('');

    const [tags, setTags] = createSignal([]) as any;

    const [searchResult, setSearchResult] = createSignal([]);
    const [isDropdownVisible, setIsDropdownVisible] = createSignal(false);

    const [hasClickedSubmitButton, setHasClickedSubmitButton] = createSignal(false);

    const [pagesAndProducts, setPagesAndProducts] = createSignal([]);
    const [articlesAndBlogPosts, setArticlesAndBlogPosts] = createSignal([]);
    const [videos, setVideos] = createSignal([]);
    const [eventsAndWebinars, setEventsAndWebinars] = createSignal([]);

    const searchClient = client.initIndex(`${siteId}-${target}`);

    const fetchTags = async () => {
        try {
            const { facetHits }: any = await searchClient.searchForFacetValues('tags', '*', {});
            const faceHitsWithIsActive = facetHits.map((facetHit: any) => {
                return {
                    ...facetHit,
                    isActive: false,
                };
            });
            setTags(faceHitsWithIsActive);
        } catch (error) {
            console.error(error);
        }
    };

    const searchDropdownItems = async () => {
        if (searchText().length < 2) {
            setSearchResult([]);
            return;
        }

        try {
            const { hits }: any = await searchClient.search(searchText(), { hitsPerPage: 10 });
            setSearchResult(hits);
        } catch (error) {
            console.error('error searching', error);
        }
    };

    const searchAndSortByTags = async () => {
        const initialSearchQueries = [
            {
                indexName: `${siteId}-${target}`,
                query: searchText(),
                params: {
                    filters: '(type:page OR type:product)',
                },
            },
            {
                indexName: `${siteId}-${target}`,
                query: searchText(),
                params: {
                    filters: 'type:blog-and-news',
                },
            },
            {
                indexName: `${siteId}-${target}`,
                query: searchText(),
                params: {
                    filters: 'type:video-item',
                },
            },
            {
                indexName: `${siteId}-${target}`,
                query: searchText(),
                params: {
                    filters: 'type:event',
                },
            },
        ];

        try {
            const { results }: any = await client.multipleQueries(initialSearchQueries);
            const [pagesAndProducts, articlesAndBlogPosts, videos, eventsAndWebinars] = results;

            setPagesAndProducts(pagesAndProducts.hits);
            setArticlesAndBlogPosts(articlesAndBlogPosts.hits);
            setVideos(videos.hits);
            setEventsAndWebinars(eventsAndWebinars.hits);
        } catch (error) {
            console.error('error fetching algoliasearch', error);
        }
    };

    onMount(async () => {
        fetchTags();
        if (searchParams.s) {
            setSearchText(searchParams.s);
        }
        searchAndSortByTags();

        const handleClick = (event: any) => {
            const isClickInsideForm = formRef.contains(event.target);
            const isClickInsideDropdown = dropdownRef.contains(event.target);

            if (isClickInsideForm) {
                searchDropdownItems();
            }

            if (!isClickInsideDropdown && !isClickInsideForm) {
                setIsDropdownVisible(false);
            }
        };
        document.addEventListener('click', handleClick);
        onCleanup(() => document.removeEventListener('click', handleClick));
    });

    const handleOnSearchInputChange = (event: any) => {
        setSearchText(event.target.value);
        searchDropdownItems();

        if (searchText()) {
            setIsDropdownVisible(true);
        } else {
            setIsDropdownVisible(false);
        }
        searchAndSortByTags();
    };

    const formSubmit = (event: any) => {
        event.preventDefault();

        searchAndSortByTags();

        if (!searchText() || !isDropdownVisible()) {
            return;
        }
        searchPage.url && setSearchParams({ s: searchText() });
        setIsDropdownVisible(false);
        setHasClickedSubmitButton(!hasClickedSubmitButton());
        navigateToAnchor({ id: 'searchResultsHeading', smallScreen: isMobile() });
    };

    const clickOnCategoryItem = async (clickedTag: any) => {
        // only one tag can be isActive at a time!
        setTags(
            tags().map((category: any) =>
                category.value === clickedTag.value ? { ...category, isActive: !category.isActive } : { ...category, isActive: false }
            )
        );

        setSearchText('');

        if (!clickedTag.isActive) {
            try {
                const queries = [
                    {
                        indexName: `${siteId}-${target}`,
                        query: searchText(),
                        params: {
                            filters: `(type:page OR type:product) AND tags:${clickedTag.value}`,
                        },
                    },
                    {
                        indexName: `${siteId}-${target}`,
                        query: searchText(),
                        params: {
                            filters: `type:blog-and-news AND tags:${clickedTag.value}`,
                        },
                    },
                    {
                        indexName: `${siteId}-${target}`,
                        query: searchText(),
                        params: {
                            filters: `type:video-item AND tags:${clickedTag.value}`,
                        },
                    },
                    {
                        indexName: `${siteId}-${target}`,
                        query: searchText(),
                        params: {
                            filters: `type:event AND tags:${clickedTag.value}`,
                        },
                    },
                ];

                const { results }: any = await client.multipleQueries(queries);

                const [pagesAndProducts, articlesAndBlogPosts, videos, eventsAndWebinars] = results;

                setPagesAndProducts(pagesAndProducts.hits);
                setArticlesAndBlogPosts(articlesAndBlogPosts.hits);
                setVideos(videos.hits);
                setEventsAndWebinars(eventsAndWebinars.hits);
            } catch (error) {
                console.error('error fetching data', error);
            }
        }
        setHasClickedSubmitButton(!hasClickedSubmitButton());
        navigateToAnchor({ id: 'searchResultsHeading', smallScreen: isMobile() });
    };

    const handleOnDropdownItemClick = async (clickedDropdownItemTitle: string) => {
        setSearchText(clickedDropdownItemTitle);
        searchPage.url && setSearchParams({ s: searchText() });

        searchAndSortByTags();

        setIsDropdownVisible(false);
        setHasClickedSubmitButton(!hasClickedSubmitButton());
        navigateToAnchor({ id: 'searchResultsHeading', smallScreen: isMobile() });
    };

    let formRef: any;
    let dropdownRef: any;

    return (
        <ErrorCatcher componentName="Search">
            <Show when={pageContent()?.page?.content?.seo}>
                <SeoSettings
                    seo={pageContent().page?.content?.seo}
                    title={pageContent().page?.content?.post_title}
                />
            </Show>
            <StyledSearchWrapper id="search">
                <Section templateShorthand={[12]} widthType={'bgFull'}>
                    <Grid templateShorthand={[12]}>
                        <StyledHeadingsSection>
                            <Heading tag="h1" variant="hero">
                                {localize('atos-search-tool', 'Atos search tool')}
                            </Heading>

                            <Text color={'darkGray'} fontSize="large" displayRedVerticalLine={true}>
                                {localize(
                                    'use-atos-search-tool-for-what-you-are-looking-for',
                                    'Use Atos search tool to explore different topics and find relevant resources'
                                )}
                            </Text>
                        </StyledHeadingsSection>

                        <StyledSearchFormWrapper>
                            <StyledSearchForm onSubmit={formSubmit} novalidate role="search" ref={formRef}>
                                <StyledSearchInput
                                    type="search"
                                    placeholder={localize('write-a-keyword', 'Write a keyword for what you are looking for…')}
                                    onInput={handleOnSearchInputChange}
                                    value={searchText()}
                                    onClick={() => setIsDropdownVisible(true)}
                                />
                                <StyledButton type="submit">
                                    <MagnifyingGlassIcon
                                        {...(AppState.viewport.width < theme.breakpoints.MOBILE
                                            ? {
                                                  height: 2,
                                                  width: 2,
                                                  opacity: 0.7,
                                              }
                                            : {})}
                                    />
                                </StyledButton>
                            </StyledSearchForm>

                            <StyledResultsDropdown isDropdownVisible={isDropdownVisible()} ref={dropdownRef}>
                                <For
                                    each={searchResult()}
                                    fallback={<StyledDropdownItem noResults={true}>{localize('no-results', 'No results...')}</StyledDropdownItem>}
                                >
                                    {(item: any) => (
                                        <StyledDropdownItem onClick={() => handleOnDropdownItemClick(item?.postTitle)}>
                                            {removeAmpersand(item?.postTitle)}{' '}
                                        </StyledDropdownItem>
                                    )}
                                </For>
                            </StyledResultsDropdown>
                        </StyledSearchFormWrapper>
                        <StyledCategoriesWrapper>
                            <Text color={'darkGray'} fontSize="large" displayRedVerticalLine={false}>
                                {localize('or-choose-a-topic', 'Or choose a topic:')}
                            </Text>
                            <StyledCategories>
                                <For each={tags().slice(0, 5)}>
                                    {(tag: any) => (
                                        <Button
                                            label={tag.value}
                                            variant="secondary"
                                            onClick={() => clickOnCategoryItem(tag)}
                                            customCss={'margin: 0.5rem;'}
                                            isActive={tag.isActive}
                                        />
                                    )}
                                </For>
                            </StyledCategories>
                        </StyledCategoriesWrapper>
                    </Grid>
                </Section>
            </StyledSearchWrapper>
            <SearchResults
                articlesAndBlogPosts={articlesAndBlogPosts()}
                pagesAndProducts={pagesAndProducts()}
                videos={videos()}
                eventsAndWebinars={eventsAndWebinars()}
                hasClickedSubmitButton={hasClickedSubmitButton()}
            />
            {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
            <Portal mount={document.getElementById('root')!}>
                <Menu menuLocation="primary_menu" shortcutsLocation="shortcuts" />
            </Portal>
        </ErrorCatcher>
    );
};