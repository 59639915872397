import { useContext, createSignal, JSX, Show, createEffect } from 'solid-js';
import { Grid } from '../../grid-system/grid/grid';
import { StyledButtonWrapper, StyledContentHubContainer, StyledLinksContainer, StyledLinksInner, StyledRightPanel } from './content-hub.styles';
import { fetchBlogsAndNews } from '../blogs-news/fetch-blogs-and-news';
import { createGroupedBlogAndNewsCard } from '../blogs-news/blogs-news-component';
import { BlogNewsData } from '../blog-news/blog-news';
import { EventData } from '../event/event-types';
import { PostCardPlaceholder } from './post-card-placeholder';
import { ContentHubCardType, ContentHubProps, TagType, UniqueTypeAndTagIdType } from './content-hub-types';
import { Heading } from '../../ui-components/heading/heading';
import { Text } from '../../ui-components/text/text';
import { Button } from '../../ui-components/button/button';
import { ErrorCatcher } from '../../tools/error-catcher';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { fetchEvents } from '../events/helpers/fetch-events';
import { createGroupedEventCard } from '../events/helpers/events-card-helper';

export const ContentHub = (props: ContentHubProps) => {
    const { localize} = useContext(AppContext);
    const [card1, setCard1] = createSignal(<PostCardPlaceholder />);
    const [card2, setCard2] = createSignal(<PostCardPlaceholder />);
    const [card3, setCard3] = createSignal(<PostCardPlaceholder />);
    const [err, setErr] = createSignal(false);

    createEffect(() => {
        try {            
            if (props.mixedContent) {
                createMixedCards();
            } else {
                createCardsForCommonType();
            }
        } catch (error) {
            setErr(true);
        }
    });

    function withLinks() {
        return props.link1?.url || props.link2?.url;
    };

    const createCardsForCommonType = () => {
        try {
            const cards = fetchAndCreateCards(props.contentType);
            if (!cards || cards?.length === 0) {
                return;
            }
            setCards(cards);
        } catch (error) {
            setErr(true);
        }
    };

    const noContentFound = () => {
        setCard1((
            <Text fontSize='normal'>
                {localize('no-content-found', 'No content found')}
            </Text>));
        setCard2(null);
        setCard3(null);
    };

    function setCards(cards: JSX.Element[]) {
        setCard1(null);
        setCard2(null);
        setCard3(null);

        const placementSetter = [
            (card: JSX.Element) => setCard1(card),
            (card: JSX.Element) => setCard2(card),
            (card: JSX.Element) => setCard3(card),
        ];

        placementSetter.forEach((setter, index) => {            
            for (let i = index; i < cards.length; i++) {
                if (cards[i]) {
                    setter(cards[i]);
                    break;
                }
            }
        });
    };

    const audienceFilter = (post: BlogNewsData | EventData) => {
        if (!props.audience || props.audience === 'all') {
            return true;
        }

        if (props.audience === 'hcp') {
            return post?.isHealthcareProfessional;
        } else {
            return !post?.isHealthcareProfessional;
        }
    };

    const fetchAndCreateCards = (selectedType: ContentHubCardType, firstCard?: boolean) => {
        
        try {
            const posttype = {
                'blog-and-news': {
                    fetch: (tag: TagType) => fetchBlogsAndNews({ limit: 3, tag }),
                    createCards: (post: BlogNewsData, largeCard: boolean) => createGroupedBlogAndNewsCard({ post, largeCard})
                },
                'event': {
                    fetch: (tag: TagType) => fetchEvents({ limit: 3, tag, fromDate: new Date().toDateString() }),
                    createCards: (event: EventData, largeCard: boolean) => createGroupedEventCard({ event, largeCard})
                }
            };
            
            const typeSlug = selectedType?.posttype?.value;
            if (!typeSlug) {
                return [];
            }
            const posts = posttype[typeSlug].fetch(selectedType?.tag);
            if (!posts()) {
                return [];
            }
    
            const cards = posts()
                .filter(audienceFilter)
                .map((post, index) => {
                    let largeCard = false;
                    if (!props.mixedContent && index === 0) {
                        largeCard = true;
                    }
                    if (firstCard) {
                        largeCard = true;
                    }
        
                    const created = posttype[typeSlug].createCards(post as any, largeCard);
                    return created;
                });

            if (cards.length === 0) {
                noContentFound();
            }
    
            return cards;
        } catch (err) {
            console.error('Error fetching and creating cards: ', err);
            setErr(true);
            return [];
        }
    };

    function createMixedCards() {
        const fetchedAndCreatedCards: Partial<UniqueTypeAndTagIdType> = {};

        props.cards.forEach((card, index) => {
            const tagSlug = card?.tag?.slug ?? '';
            const uniqueTypeAndTagId = `${card?.posttype?.value}-${tagSlug}`;
            const posts = fetchedAndCreatedCards[uniqueTypeAndTagId];
            const alreadyFetched = posts && posts?.length > 0 ? true : false;
            
            if (!alreadyFetched) {
                const firstCard = index === 0;
                const fetched = fetchAndCreateCards(card, firstCard);
                fetchedAndCreatedCards[uniqueTypeAndTagId] = [...fetched];
            }
        });

        const readyCards: Element[] = [];
        props.cards.forEach(card => {
            const tagSlug = card?.tag?.slug ?? '';
            const uniqueTypeAndTagId = `${card?.posttype?.value}-${tagSlug}`;
            
            const posts = fetchedAndCreatedCards[uniqueTypeAndTagId];
            if (!posts || posts.length === 0) {
                return;
            }

            const firstItem = posts.shift();
            readyCards.push(firstItem);
        });

        return setCards(readyCards);
    };

    return (
        <ErrorCatcher componentName='Content hub'>
            <StyledContentHubContainer withLinks={withLinks()}>
                <Show when={props.eyebrowHeading}>
                    <Heading tag="h3" variant="small">
                        {props.eyebrowHeading}
                    </Heading>
                </Show>
                <Show when={props.heading}>
                    <Heading tag="h2" variant="xlarge">
                        {props.heading}
                    </Heading>
                </Show>
                <Show
                    when={!err()}
                    fallback={
                        <Text fontSize='normal'>{localize('error-has-occurred', 'An error has occurred, please try again later')}</Text>
                    }
                >
                    <Grid
                        templateShorthand={[7, 5]}
                        responsive={{
                            largeDesktop: [6, 6],
                            smallDesktop: [6, 6],
                            tablet: [12]
                        }}
                    >
                        {card1()}
                        <Grid
                            templateShorthand={[5]} 
                            responsive={{
                                largeDesktop: [6],
                                smallDesktop: [6],
                                tablet: [12]
                            }}
                        >
                            <StyledRightPanel>
                                <div>
                                    {card2()}
                                </div>
                                <div>
                                    {card3()}
                                </div>
                                <StyledLinksContainer>
                                    <StyledLinksInner>
                                        <Show when={props.link1?.url}>
                                            <Button variant="tertiary" label={props.link1?.title} arrow={true} isLink={true} url={props.link1?.url || '#'}/>
                                        </Show>
                                        <Show when={props.link2?.url}>
                                            <StyledButtonWrapper>
                                                <Button variant="tertiary" label={props.link2?.title} arrow={true} isLink={true} url={props.link2?.url || '#'}/>
                                            </StyledButtonWrapper>
                                        </Show>
                                    </StyledLinksInner>
                                </StyledLinksContainer>
                            </StyledRightPanel>
                        </Grid>
                    </Grid>
                </Show>
            </StyledContentHubContainer>
        </ErrorCatcher>
    );
};


ContentHub.parseProps = (atts: any) => {
    const type = atts.contentType?.posttype?.value;
    const mixedContent = type === 'mix' ? true : false;
    const cards = mixedContent ? [ atts.card1, atts.card2, atts.card3 ] : [];
    
    return {
        eyebrowHeading: atts.eyebrowHeading,
        heading: atts.heading,
        mixedContent,
        contentType: atts.contentType,
        cards,
        link1: atts.link1,
        link2: atts.link2,
        audience: atts.audience,
    };
};
