import { For, createEffect, createSignal, useContext } from 'solid-js';
import { ErrorCatcher } from '../../tools/error-catcher';
import { Button } from '../../ui-components/button/button';
import { ButtonProps } from '../../ui-components/button/button-types';
import { DocumentHolder, GetDocumentHolderResponse } from '../files/document-holder';
import { AppContext } from '../../app-context-provider/app-context-provider';


export const File = (props: FileProps) => {
    const {localize} = useContext(AppContext);
    const [documentHolders, setDocumentHolders] = createSignal<DocumentHolder[]>([]);

    createEffect(async () => {
        const idsString = props.documentHoldersIds.join(',') || '';
        const url = `/files/document-holders?documentHolderIds=${idsString}`;
        
        const response = await fetch(url, {
            method: 'GET',
        });
        
        const data: GetDocumentHolderResponse = await response.json();
        
        if (data?.success) {            
            setDocumentHolders(data.documentHolders);
        }
    });

    const getLabel = () => {        
        if (props.button.label?.toLowerCase() === 'download') {
            return localize('download', 'Download');
        }

        return props.button.label;
    };

    return (
        <ErrorCatcher componentName='File'>
            <For each={documentHolders()}>{(documentHolder) => (
                <Button {...props.button} label={getLabel()} url={`/downloads/${documentHolder.id}/${documentHolder.slug}`} isLink/>
            )}</For>
        </ErrorCatcher>
    );
};

export type FileProps = {
    button: ButtonProps;
    documentHoldersIds: number[];
}

File.parseProps = (atts: any) => {
    return {
        documentHoldersIds: atts.documentHolders || [],
        button: atts.innerBlocks[0],
    };
};
