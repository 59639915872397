import { Show, useContext } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { getBackgroundImageSrcData, ImageSrcData } from '../image-component/image-component';
import theme, { breakpoints } from '../../style/theme';
import { Button } from '../../ui-components/button/button';
import { Heading } from '../../ui-components/heading/heading';
import { Text } from '../../ui-components/text/text';
import {
    StyledContentWrapper,
    StyledHeroWrapper,
    StyledMobileImageContainer,
    StyledScrollDownContainer,
    StyledInnerWrapper,
    StyledLinkWrapper,
    StyledEmptyColumn,
    StyledHeadings,
    StyledSwirlMobile,
    StyledIconWrapper,
    StyledSwirlDesktop,
    StyledHeroAndSwirlContainer,
    StyledScrollDownButton,
} from './hero-campaign.styles';
import { HeroComponentProps } from './hero-campaign-types';
import { Grid } from '../../grid-system/grid/grid';
import { AngleDownIcon } from '../icons-library/angle-down';
import { focusPosition } from '../../tools/get-image-focus';
import { ErrorCatcher } from '../../tools/error-catcher';
import { SwirlMobile } from '../icons-library/swirl-mobile';
import { SwirlDesktop } from '../icons-library/swirl-desktop';

/**
 * This is a simple copy of the Hero component with one change: the CTA button is no longer hard-coded
 * to the text "Learn more" and the action being scrolling down the page, but instead customizable in the block.
 */

export const HeroCampaign = (props: HeroComponentProps) => {
    const { imagesServiceUrl, supportedImageFormats, localize, viewport, siteInfo } = useContext(AppContext);

    const isMobile = () => viewport.width <= breakpoints.MOBILE;

    const imageWidth = () => {
        // Because this is a background image which sizes itself to the container, we need to set the width larger than the container, so it has room to grow/shrink.
        if (viewport.width <= breakpoints.TABLET_SMALL) {
            return 750; // Mobile
        }

        if (viewport.width <= breakpoints.DESKTOP && viewport.width > breakpoints.TABLET_SMALL) {
            return 1500; // Tablet
        }

        return 1920; // Desktop
    };

    const imageHeight = () => {
        // Because this is a background image which sizes itself to the container, we need to set the width larger than the container, so it has room to grow/shrink.
        if (viewport.width <= breakpoints.TABLET_SMALL) {
            return 325; // Mobile
        }

        if (viewport.width <= breakpoints.DESKTOP && viewport.width > breakpoints.TABLET_SMALL) {
            return 750; // Tablet
        }

        return 960; // Desktop
    };

    const getBackgroundImage = () => {
        if (props.src) {
            const backgroundImage: ImageSrcData = getBackgroundImageSrcData({
                imagesServiceUrl,
                supportedImageFormats,
                src: props.src,
                focus: props.focus,
                sign: props.sign,
                width: imageWidth() || 750, // Assume mobile from the start
                height: imageHeight() || 325,
            });
            return backgroundImage;
        }
    };

    const customCssRow = `
        height: 100%;
        ${theme.mediaQueries.mobileMax} {
            height: auto;
        }
    `;

    const handleScrollDown = (e: any) => {
        e.preventDefault();
        if (!window) {
            return;
        }
        
        window.scrollTo({
            top: window.innerHeight - (isMobile() ? 20 : 100),
            behavior: 'smooth'
        });
    };

    return (
        <ErrorCatcher componentName="HeroCampaign">
            <StyledHeroAndSwirlContainer class='hero-campaign'>
                <StyledHeroWrapper backgroundImageSrcData={getBackgroundImage()} focusPosition={focusPosition(props.focus)} overlayType={props.overlayType}>
                    <Grid
                        templateShorthand={[12]}
                        isLayoutGrid={true}
                        customCss={`
                            height: 100%; ${isMobile() ? 'grid-template-rows: max-content;' : ''}
                        `}
                    >
                        <Grid templateShorthand={[1, 11]} responsive={{ tablet: [1, 11], mobile: [12] }} customCss={customCssRow}>
                            <StyledEmptyColumn />
                            <StyledInnerWrapper>
                                <StyledContentWrapper isAtosCare={siteInfo?.siteType === 'atos-care'}>
                                    <StyledHeadings>
                                        <Heading tag="h1" variant="heroEyebrow">
                                            {props.eyebrowHeading}
                                        </Heading>
                                        <Heading tag="h2" variant="hero" noBlockSpacing={true}>
                                            {props.largerHeading}
                                        </Heading>
                                    </StyledHeadings>
                                    <Text color={'darkGray'} fontSize={isMobile() ? 'normal' : 'large'} displayRedVerticalLine={true}>
                                        {props.description}
                                    </Text>
                                    <Show when={props?.customLink?.url}>
                                        <StyledLinkWrapper>
                                            <Button
                                                label={props.customLink.label ? props.customLink.label : ''}
                                                url={props.customLink.url}
                                                variant="primary"
                                                isLink={true}
                                            />
                                        </StyledLinkWrapper>
                                    </Show>
                                </StyledContentWrapper>
                            </StyledInnerWrapper>
                        </Grid>
                        <StyledMobileImageContainer backgroundImageSrcData={getBackgroundImage()} focusPosition={focusPosition(props.focus)}>
                            <div id="learnMoreMobile" onClick={handleScrollDown}>
                                <Button label={localize('learn-more', 'Learn more')} variant="tertiary" darkMode={true} />
                                <StyledIconWrapper>
                                    <AngleDownIcon fill="white" />
                                </StyledIconWrapper>
                            </div>
                        </StyledMobileImageContainer>
                    </Grid>
                    <Show when={siteInfo?.siteType !== 'atos-care' && isMobile()}>
                        <StyledSwirlMobile>
                            <SwirlMobile />
                        </StyledSwirlMobile>
                    </Show>
                    <StyledScrollDownContainer>
                        <StyledScrollDownButton aria-label={localize('learn-more', 'Learn more')} onClick={handleScrollDown}>
                            <AngleDownIcon />
                        </StyledScrollDownButton>
                    </StyledScrollDownContainer>
                </StyledHeroWrapper>
                <Show when={siteInfo?.siteType !== 'atos-care' && !isMobile()}>
                    <StyledSwirlDesktop>
                        <SwirlDesktop />
                    </StyledSwirlDesktop>
                </Show>
            </StyledHeroAndSwirlContainer>
        </ErrorCatcher>
    );
};

HeroCampaign.parseProps = (atts: any) => {
    // This is the raw image object coming from gutenberg. We dont want all details from it.
    const { image, customLink } = atts;
    const commonAtts = {
        altText: atts.altText,
        imageText: atts.imageText,
        eyebrowHeading: atts.eyebrowHeading,
        largerHeading: atts.largerHeading,
        description: atts.description,
        labels: atts.labels,
        customLink: customLink ? customLink[0] : null,
    };

    if (image) {
        return {
            src: image.url,
            width: image.width,
            focus: image.focus,
            sign: image.sign,
            overlayType: atts.overlayType,
            ...commonAtts,
        };
    } else return commonAtts;
};
