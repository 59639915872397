/* eslint-disable indent */
import { useNavigate, useSearchParams } from '@solidjs/router';
import { gql } from 'graphql-request';
import { For, Show, createEffect, createMemo, createSignal, on, onMount, useContext } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { Grid } from '../../grid-system/grid/grid';
import { Section } from '../../grid-system/section/section';
import theme, { palette } from '../../style/theme';
import { MyContentItem, getMyContent } from './my-content-helpers';
import { ExpandableContainer } from '../../ui-components/expandable-container/expandable-container';
import { Heading } from '../../ui-components/heading/heading';
import { Text } from '../../ui-components/text/text';
import { BlogNewsData } from '../blog-news/blog-news';
import { createGroupedBlogAndNewsCard } from '../blogs-news/blogs-news-component';
import { EventData } from '../event/event-types';
import { AngleDownIcon } from '../icons-library/angle-down';
import { PagePostCard } from '../page/page-post-card';
import { ProductData } from '../product/product-types';
import { createGroupedProductCard } from '../products/products';
import { StyledShowMoreButton } from '../search/search-result.styles';
import { ShowMoreButtonProps } from '../search/search-results';
import { createGroupedVideoCard } from '../videos/videos-component';
import { VideoData } from '../videos/videos-types';
import {
    StyledCategoriesContainer,
    StyledCategoriesContainerMobile,
    StyledCategoriesHeadingDesktop,
    StyledCategoriesList,
    StyledCategoryItem,
    StyledShowMoreButtonContainer,
} from './my-content.styles';
import { createGroupedEventCard } from '../events/helpers/events-card-helper';
import { Button } from '../../ui-components/button/button';
import { StyledFlexRow } from '../../ui-components/utility-style-components/flex';
import { StyledHorizontalSpace, StyledVerticalSpace } from '../../ui-components/utility-style-components/spacing';

const GET_CONTENT_QUERY = gql`
    query GetContent($key: String!) {
        resource(key: $key) {
            content
            key
        }
    }
`;

export const MyContent = () => {
    const AppState = useContext(AppContext);
    const { graphQlClient, viewport, localize, pdfBuilderPage } = useContext(AppContext);
    const isMobile = () => viewport.width <= theme.breakpoints.MOBILE;

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const POSTS_PER_PAGE_MOBILE = 3;
    const [currentPage, setCurrentPage] = createSignal({
        events: 1,
        news: 1,
        products: 1,
        videos: 1,
        blogs: 1,
        pages: 1,
    });

    const [content, setContent] = createSignal([] as MyContentItem[]);
    const [fetchedData, setFetchedData] = createSignal([] as any);
    const [loading, setLoading] = createSignal(true);
    const [selectedCategory, setSelectedCategory] = createSignal<any>({
        slug: searchParams.category || 'all',
        name: '',
    });

    const fetchCookiesAndSetContent = () => {
        setLoading(true);
        const myContent = getMyContent();
        myContent && setContent(myContent);
    };

    const fetchData = async () => {
        if (content().length === 0) {
            setLoading(false);
            return setFetchedData([]);
        }

        const promises = content().map(async (element) => {
            try {
                const response = await graphQlClient(GET_CONTENT_QUERY, { key: element.permalink }, true);
                if (!response || !response.resource) {
                    return null;
                }
                return response;
            } catch (e) {
                console.log(e);
                return null;
            }
        });

        const results = await Promise.all(promises);
        setFetchedData(results.filter(Boolean));
        setLoading(false);
    };

    onMount(() => {
        fetchCookiesAndSetContent();
        fetchData();
    });

    const refetchDataOnRemoval = () => {
        fetchCookiesAndSetContent();
        fetchData();
    };

    createEffect(
        on(
            () => content(),
            () => fetchData(),
            {
                defer: true,
            }
        )
    );

    const fetchedContent = createMemo((): any => fetchedData().map((element: any) => element.resource.content));

    const events = createMemo((): any => fetchedContent().filter((item: any) => item.type === 'event'));
    const news = createMemo((): any => fetchedContent().filter((item: any) => item.type === 'blog-and-news' && item.tags.includes('news')));
    const products = createMemo((): any => fetchedContent().filter((item: any) => item.type === 'product'));
    const videos = createMemo((): any => fetchedContent().filter((item: any) => item.type === 'video-item'));
    const blogs = createMemo((): any => fetchedContent().filter((item: any) => item.type === 'blog-and-news' && !item.tags.includes('news')));
    const pages = createMemo((): any => fetchedContent().filter((item: any) => item.type === 'page'));

    const categories = [
        { name: 'Events', fn: events, slug: 'events' },
        { name: 'News', fn: news, slug: 'news' },
        { name: 'Products', fn: products, slug: 'products' },
        { name: 'Videos', fn: videos, slug: 'videos' },
        { name: 'Blogs', fn: blogs, slug: 'blogs' },
        { name: 'Pages', fn: pages, slug: 'pages' },
    ];

    const nonEmptyCategories = () => categories.filter(({ fn }) => fn().find(() => true));

    const categoryName = () => {
        const category = categories.find((category) => {
            return category.slug === selectedCategory().slug;
        });

        if (!category) {
            return;
        }

        return category.name;
    };

    let expandableContainer: HTMLDivElement | undefined;
    const handleCategoryClick = (category: any) => {
        const categoryToUse = category || { slug: 'all', name: localize('all', 'All') };
        setSelectedCategory(categoryToUse);
        expandableContainer?.click();
        navigate(`${location.pathname}?category=${categoryToUse.slug}`, { scroll: false });
    };

    const renderCategoriesList = () => {
        return (
            <StyledCategoriesList>
                <StyledCategoryItem underlined={selectedCategory().slug === 'all'} onClick={() => handleCategoryClick(null)}>
                    <Heading tag="h3" variant="tinyRed" noBlockSpacing={true}>
                        {localize('all', 'All')}
                    </Heading>
                </StyledCategoryItem>

                <For each={nonEmptyCategories()}>
                    {(topCategory: any) => (
                        <StyledCategoryItem underlined={selectedCategory()?.slug === topCategory.slug} onClick={() => handleCategoryClick(topCategory)}>
                            <Heading tag="h3" variant="tinyRed" noBlockSpacing={true}>
                                {topCategory.name}
                            </Heading>
                        </StyledCategoryItem>
                    )}
                </For>
            </StyledCategoriesList>
        );
    };

    const ShowMoreButton = (props: ShowMoreButtonProps) => {
        return (
            <Show
                when={
                    Math.min(props.currentPage * props.resultsPerPage, props.totalResults) !== props.totalResults &&
                    AppState.viewport.width <= theme.breakpoints.MOBILE
                }
            >
                <StyledShowMoreButtonContainer>
                    <StyledShowMoreButton type="button" onClick={props.onClick}>
                        <div>{localize('show-more', 'show more')}</div>
                        <AngleDownIcon fill={theme.palette.red} {...{ height: 2.14, width: 2.86 }} />
                    </StyledShowMoreButton>
                </StyledShowMoreButtonContainer>
            </Show>
        );
    };

    const CategoriesMobile = () => (
        <StyledCategoriesContainerMobile>
            <ExpandableContainer
                ref={expandableContainer}
                initialOpen={false}
                chevronColor={palette.red}
                isDropdown={true}
                withBorder={true}
                headingProps={{
                    noBlockSpacing: true,
                    tag: 'h3',
                    variant: 'medium',
                    children: categoryName() || localize('categories', 'Categories'),
                }}
            >
                {renderCategoriesList()}
            </ExpandableContainer>
        </StyledCategoriesContainerMobile>
    );

    return (
        <>
            <Section widthType="bgFull" backgroundType="color" backgroundValue="white" customCss="z-index: 1;">
                <Show when={!isMobile()} fallback={<CategoriesMobile />}>
                    <StyledCategoriesContainer>
                        <StyledCategoriesHeadingDesktop>
                            <Heading tag="h3" variant="medium" noBlockSpacing={true}>
                                {localize('filters', 'Filters')}
                            </Heading>
                        </StyledCategoriesHeadingDesktop>
                        {renderCategoriesList()}
                    </StyledCategoriesContainer>
                </Show>
            </Section>

            <Show when={pdfBuilderPage}>
                <Section widthType="bgFull" backgroundType="color" backgroundValue="darkGray" customCss="z-index: 0;">
                    <StyledFlexRow justifyContent='center'>
                        <Text
                            fontSize='large'
                            color={'white'}
                            noBlockSpacing={true}
                        >
                            {localize(
                                'pdf-builder-create-pdf-cta-heading', 
                                'Create printable and downloadable PDFs from content on the site'
                            )}
                        </Text>
                        <StyledHorizontalSpace size={3} />
                        <Button
                            label={localize('pdf-builder-create-pdf-cta-button', 'Create PDF')}
                            isLink={true} 
                            url={pdfBuilderPage}
                            variant='secondary'
                            darkMode={true}
                        />
                    </StyledFlexRow>
                </Section>
                <StyledVerticalSpace size={2} />
            </Show>

            <Show when={loading()}>
                <Section templateShorthand={[12]} widthType="bgFull" isLayoutSection={true}>
                    <Heading tag='h4'>{localize('loading', 'Loading...')}</Heading>
                </Section>
            </Show>

            <Show when={!loading() && !nonEmptyCategories().length}>
                <Section templateShorthand={[12]} widthType="bgFull" isLayoutSection={true}>
                    <Text fontSize='normal'>{localize('no-results', 'No results found')}</Text>
                </Section>
            </Show>

            <Show when={(selectedCategory().slug === 'all' || selectedCategory().slug === 'events') && events().length}>
                <Section templateShorthand={[12]} widthType="bgFull" isLayoutSection={true}>
                    <div>
                        <Heading tag="h3" variant="large">
                            {localize('events', 'Events')}
                        </Heading>

                        <Grid templateShorthand={[4, 4, 4]} responsive={{ mobile: [12], tablet: [6, 6] }} inheritParentGrid={true}>
                            <For each={isMobile() ? events().slice(0, currentPage().events * POSTS_PER_PAGE_MOBILE) : events()} fallback={null}>
                                {(event: EventData) => (
                                    <div onClick={ () => refetchDataOnRemoval()}>
                                        {createGroupedEventCard({ event })}
                                    </div>
                                )}
                            </For>
                        </Grid>

                        <ShowMoreButton
                            currentPage={currentPage().events}
                            resultsPerPage={POSTS_PER_PAGE_MOBILE}
                            totalResults={events().length}
                            onClick={() => setCurrentPage((prevPage) => ({ ...prevPage, events: prevPage.events + 1 }))}
                        />
                    </div>
                </Section>
            </Show>

            <Show when={(selectedCategory().slug === 'all' || selectedCategory().slug === 'news') && news().length}>
                <Section templateShorthand={[12]} widthType="bgFull" isLayoutSection={true}>
                    <div>
                        <Heading tag="h3" variant="large">
                            {localize('news', 'News')}
                        </Heading>

                        <Grid templateShorthand={[4, 4, 4]} responsive={{ mobile: [12], tablet: [6, 6] }} inheritParentGrid={true}>
                            <For each={isMobile() ? news().slice(0, currentPage().news * POSTS_PER_PAGE_MOBILE) : news()} fallback={null}>
                                {(post: BlogNewsData) => (
                                    <div onClick={ () => refetchDataOnRemoval()}>
                                        {createGroupedBlogAndNewsCard({ post })}
                                    </div>
                                )}
                            </For>
                        </Grid>

                        <ShowMoreButton
                            currentPage={currentPage().news}
                            resultsPerPage={POSTS_PER_PAGE_MOBILE}
                            totalResults={news().length}
                            onClick={() => setCurrentPage((prevPage) => ({ ...prevPage, news: prevPage.news + 1 }))}
                        />
                    </div>
                </Section>
            </Show>

            <Show when={(selectedCategory().slug === 'all' || selectedCategory().slug === 'products') && products().length}>
                <Section templateShorthand={[12]} widthType="bgFull" isLayoutSection={true}>
                    <div>
                        <Heading tag="h3" variant="large">
                            {localize('products', 'Products')}
                        </Heading>
                        <Grid templateShorthand={[3, 3, 3, 3]} responsive={{ mobile: [12], tablet: [4, 4, 4] }} inheritParentGrid={true}>
                            <For each={isMobile() ? products().slice(0, currentPage().products * POSTS_PER_PAGE_MOBILE) : products()} fallback={null}>
                                {(product: ProductData) => (
                                    <div onClick={ () => refetchDataOnRemoval()}>
                                        {createGroupedProductCard(product)}
                                    </div>
                                )}
                            </For>
                        </Grid>

                        <ShowMoreButton
                            currentPage={currentPage().products}
                            resultsPerPage={POSTS_PER_PAGE_MOBILE}
                            totalResults={products().length}
                            onClick={() => setCurrentPage((prevPage) => ({ ...prevPage, products: prevPage.products + 1 }))}
                        />
                    </div>
                </Section>
            </Show>

            <Show when={(selectedCategory().slug === 'all' || selectedCategory().slug === 'videos') && videos().length}>
                <Section templateShorthand={[12]} widthType="bgFull" isLayoutSection={true}>
                    <div>
                        <Heading tag="h3" variant="large">
                            {localize('videos', 'Videos')}
                        </Heading>
                        <Grid templateShorthand={[4, 4, 4]} responsive={{ mobile: [12], tablet: [6, 6] }} inheritParentGrid={true}>
                            <For each={isMobile() ? videos().slice(0, currentPage().videos * POSTS_PER_PAGE_MOBILE) : videos()} fallback={null}>
                                {(video: VideoData) => (
                                    <div onClick={ () => refetchDataOnRemoval()}>
                                        {createGroupedVideoCard(video, true)}
                                    </div>
                                )}
                            </For>
                        </Grid>

                        <ShowMoreButton
                            currentPage={currentPage().videos}
                            resultsPerPage={POSTS_PER_PAGE_MOBILE}
                            totalResults={videos().length}
                            onClick={() => setCurrentPage((prevPage) => ({ ...prevPage, videos: prevPage.videos + 1 }))}
                        />
                    </div>
                </Section>
            </Show>

            <Show when={(selectedCategory().slug === 'all' || selectedCategory().slug === 'blogs') && blogs().length}>
                <Section templateShorthand={[12]} widthType="bgFull" isLayoutSection={true}>
                    <div>
                        <Heading tag="h3" variant="large">
                            {localize('blogs', 'Blogs')}
                        </Heading>
                        <Grid templateShorthand={[4, 4, 4]} responsive={{ mobile: [12], tablet: [6, 6] }} inheritParentGrid={true}>
                            <For each={isMobile() ? blogs().slice(0, currentPage().blogs * POSTS_PER_PAGE_MOBILE) : blogs()} fallback={null}>
                                {(post: BlogNewsData) => (
                                    <div onClick={ () => refetchDataOnRemoval()}>
                                        {createGroupedBlogAndNewsCard({ post })}
                                    </div>
                                )}
                            </For>
                        </Grid>

                        <ShowMoreButton
                            currentPage={currentPage().blogs}
                            resultsPerPage={POSTS_PER_PAGE_MOBILE}
                            totalResults={blogs().length}
                            onClick={() => setCurrentPage((prevPage) => ({ ...prevPage, blogs: prevPage.blogs + 1 }))}
                        />
                    </div>
                </Section>
            </Show>

            <Show when={(selectedCategory().slug === 'all' || selectedCategory().slug === 'pages') && pages().length}>
                <Section templateShorthand={[12]} widthType="bgFull" isLayoutSection={true}>
                    <div>
                        <Heading tag="h3" variant="large">
                            {localize('pages', 'Pages')}
                        </Heading>
                        {/* TODO: maybe in future we would want to use createGroupedPageCard method but as for now i dont see reason for that */}
                        <Grid templateShorthand={[4, 4, 4]} responsive={{ mobile: [12], tablet: [6, 6] }} inheritParentGrid={true}>
                            <For each={isMobile() ? pages().slice(0, currentPage().pages * POSTS_PER_PAGE_MOBILE) : pages()} fallback={null}>
                                {(post: any) => (
                                    <div onClick={ () => refetchDataOnRemoval()}>
                                        <PagePostCard data={post} />
                                    </div>
                                )}
                            </For>
                        </Grid>

                        <ShowMoreButton
                            currentPage={currentPage().pages}
                            resultsPerPage={POSTS_PER_PAGE_MOBILE}
                            totalResults={pages().length}
                            onClick={() => setCurrentPage((prevPage) => ({ ...prevPage, pages: prevPage.pages + 1 }))}
                        />
                    </div>
                </Section>
            </Show>
        </>
    );
};
