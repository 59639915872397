import { styled } from 'solid-styled-components';
import theme from '../../style/theme';

export const StyledOrderTable = styled.div`
    display: grid;
    grid-template-columns: 1fr auto auto;
    align-items: flex-start;
    gap: 1rem;
    column-gap: 3rem;
    ${theme.mediaQueries.tabletMaxOriginal} {
        display: block;
        grid-template-columns: 1fr;
    }
    ${theme.mediaQueries.mobileMax} {
        column-gap: 2rem;
    }
`;

export const StyledOrderTableMobile = styled.div`
    display: grid;
    grid-template-columns: auto;
    align-items: flex-start;
    column-gap: 3rem;
    width: fit-content;
    align-self: end;
    ${theme.mediaQueries.tabletMax} {
        margin-top: 1rem;
    }
    ${theme.mediaQueries.mobileMax} {
        column-gap: 1.5rem;
    }
`;

export const StyledBottom = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 2rem;
    ${theme.mediaQueries.tabletMax} {
        flex-direction: column-reverse;
        justify-content: center;
    }
`;

export const StyledOrderLine = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1rem;
`;

export const StyledRemoveButtonMobile = styled.div`
    align-self: flex-end;
    margin-block: 1rem;
`;

export const StyledImageHolder = styled.div`
    flex-shrink: 0;
    align-self: flex-start;
    width: 100px;
    padding-right: 1rem;
    ${theme.mediaQueries.tabletMax} {
        margin-bottom: 1.5rem;
        padding-right: 0;
    }
    ${theme.mediaQueries.tabletMax} {
        width: 60px;
    }
`;

export const StyledHeader = styled.div`
    margin-top: 4rem;
`;

export const StyledBasketContainer = styled.div``;

export const StyledAmountValue = styled.div<{ color?: string }>`
    background-color: ${p => p.color || theme.palette.beige};
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 5rem;
    ${theme.mediaQueries.tabletMax} {
        min-width: 3.5rem;
    }
    ${theme.mediaQueries.mobileMax} {
        font-size: 1.3rem;
    }
`;

export const StyledAmountControls = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;    
    font-size: 1.5rem;
    height: 3.6rem;
    margin-bottom: 1rem;
    ${theme.mediaQueries.mobileMax} {
        height: 2.8rem;
    }
`;

export const StyledAmountButtons = styled.div<{ left?: boolean }>`
    background-color: ${theme.palette.red};
    color: ${theme.palette.white};
    height: 100%;
    font-size: 2.5rem;
    cursor: pointer;
    width: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${ p => p.left
        ? '0.5rem 0 0 0.5rem'
        : '0 0.5rem 0.5rem 0' };
    transition: background-color 0.2s ease-in-out;
    &:hover {
        background-color: ${theme.palette.pink};
    }
    ${theme.mediaQueries.tabletMax} {
        width: 3rem;
        font-size: 2rem;
    }
`;

export const StyledTableItem = styled.div<{ align: 'flex-end' | 'center' }>`
    white-space: nowrap;
    justify-self: ${(props) => props.align};
`;

export const StyledBasketErrorMessage = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 3rem;
`;

export const StyledPlaceOrderContainer = styled.div`
    position: relative; 
`;

export const StyledPlaceOrderSpinner = styled.div`
    position: absolute;
    right: 16px;
    top: 30px;
    width: auto;
    opacity: 0;
    animation: fadeIn 0.4s ease-in-out forwards;

    @keyframes fadeIn {
        to {
            opacity: 1;
        }
    }
`;
export const StyledPlaceOrderSpinnerOLD = styled.div`
    position: absolute;
    right: -33px;
    top: 25px;
    width: auto;
`;

export const StyledAddToBasketButton = styled.button`
    display: flex;
    border: none;
    background: transparent;
    cursor: pointer;
    margin: 0;
    align-items: center;
    border: 1px solid ${theme.palette.red};
    border-radius: 24px;
    padding: 0.5rem;
    padding-inline: 1rem;
    transition: all 0.3s;

    & svg {
        fill: ${theme.palette.red};
    }

    &:hover {
        background: ${theme.palette.red};
        & svg {
            fill: ${theme.palette.white};
        }
    }
`;
