import { createSignal, For, Show } from 'solid-js';
import { Heading } from '../../../ui-components/heading/heading';
import { Radio } from '../../../ui-components/inputs/radio/radio';
import { FilterIcon } from '../../icons-library/filter';
import { FilterList } from '../../../ui-components/filter-list/filter-list';
import { NestedCategory, ProductsProps } from '../products-types';
// import { 
//     props.store.store,
//     props.changeAreaOfCare,
//     props.store.clearFilters,
//     props.store.changeSelectedProductCategory,
//     props.store.changeSelectedType,
// } from '../../instructions-for-use/instructions-for-use-products-store';

import { MobileCategoryTag } from './mobile-category-tag';
import { MobileFilterModal } from './mobile-filters-modal';
import { StyledCategoryTagsContainer, StyledFilterIconAndTextWrapper, StyledFilterIconWrapper, StyledMobileFilterButton, StyledMobileFilterButtonContainer } from './mobile-sidebar.style';

export const MobileProductsSidebar = (props: ProductsProps) => {
    const [mobileFilterViewOpen, setMobileFilterViewOpen] = createSignal<boolean>(false);

    let filterListRef: HTMLDivElement | undefined;
    const changeArea = (area: NestedCategory) => {
        props.store.changeAreaOfCare(area);
        filterListRef?.click();
    };

    const openMobileFilterView = () => {
        setMobileFilterViewOpen(true);
        const body = document.getElementsByTagName('body')[0];
        body.style.overflow = 'hidden';
    };

    const closeMobileFilterView = () => {
        setMobileFilterViewOpen(false);
        const body = document.getElementsByTagName('body')[0];
        body.style.overflow = 'auto';
    };  

    return (
        <>
            <FilterList
                isMobile={true}
                isDropdown={true}
                initialOpen={false}
                listHeading={props.store.store.areaOfCare?.name || props.labels?.topCategoryHeading}
                ref={filterListRef}
            >
                <For each={Object.keys(props.store.store.availableAreasOfCare)}>{(area) => (
                    <li>
                        <Radio
                            value={props.store.store.availableAreasOfCare[area]?.data?.slug}
                            whenClicked={() => changeArea(props.store.store.availableAreasOfCare[area])}
                            name='areaOfCare'
                            readableName={props.store.store.availableAreasOfCare[area]?.data?.name}
                            isChecked={props.store.store.areaOfCare?.slug === props.store.store.availableAreasOfCare[area]?.data?.slug}
                        />
                    </li>
                )}</For>
            </FilterList>
            <Show when={props.store.store.availableProductCategories.length > 0}>
                <StyledMobileFilterButtonContainer onClick={openMobileFilterView}>
                    <StyledMobileFilterButton>
                        <StyledFilterIconAndTextWrapper>
                            <StyledFilterIconWrapper>
                                <FilterIcon />
                            </StyledFilterIconWrapper>
                            <Heading tag='h5' variant='medium' noBlockSpacing={true}>
                                {props.labels?.filtersHeading}
                            </Heading>
                        </StyledFilterIconAndTextWrapper>
                    </StyledMobileFilterButton>
                </StyledMobileFilterButtonContainer>
            </Show>

            <Show when={props.store.store.areaOfCare}>
                <StyledCategoryTagsContainer>
                    <MobileCategoryTag
                        category={props.store.store.areaOfCare}
                        onClick={(e) => props.store.clearFilters(e)}
                    />
                    <For each={props.store.store.selectedProductCategories}>{(category: NestedCategory) => (
                        <MobileCategoryTag 
                            category={category.data} 
                            onClick={() => props.store.changeSelectedProductCategory(category)}
                        />
                    )}</For>
                    <For each={props.store.store.selectedTypes}>{(type: NestedCategory) => (
                        <MobileCategoryTag
                            category={type.data}
                            onClick={() => props.store.changeSelectedType(type)}
                        />
                    )}</For>
                </StyledCategoryTagsContainer>
            </Show>

            <Show when={mobileFilterViewOpen()}>
                <MobileFilterModal
                    closeModal={closeMobileFilterView}
                    applyFiltersText={props.labels?.applyFiltersText || ''}
                    productCategoriesHeading={props.labels?.firstNestedCategoryHeading  || ''}
                    typesHeading={props.labels?.secondNestedCategoryHeading  || ''}
                    store={props.store}
                />
            </Show>
        </>
    );
};
