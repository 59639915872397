import { styled } from 'solid-styled-components';
import theme, { fontFamilyAtosRegular } from '../../../../style/theme';

export const StyledPrimaryMenu = styled.nav`
`;

export const StyledPrimaryMenuTitle = styled.h2`
    font-family: ${fontFamilyAtosRegular};
    color: ${theme.palette.darkGray};
    font-size: 2.86rem;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 6rem;

    strong {
        font-weight: 600;
    }
`;

export const StyledPrimaryMenuList = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0 2rem 0 0;
`;
