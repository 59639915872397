import { styled } from 'solid-styled-components';
import theme from '../../../style/theme';
import { cardPadding } from '../pdf-factory/components/product/product-components.style';


export const StyledAdmin = styled.div`
    @media print {
        display: none;
    }
`;

export const StyledCursor = styled.div`
    border-top: 1px solid #000;
`;

export const StyledPdfDashboard = styled.div`
    @media print {
        background-color: #fff;
    }
`;

export const TextWrapper = styled.div`
    padding-inline: ${cardPadding}px;
    padding-right: ${cardPadding * 3}px;
    background-color: ${theme.palette.beige};
    padding-bottom: 1.2rem;
`;
