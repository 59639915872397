import { For, Show, createEffect, createSignal, useContext } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { ErrorCatcher } from '../../tools/error-catcher';
import { Grid } from '../../grid-system/grid/grid';
import { Section } from '../../grid-system/section/section';
import theme from '../../style/theme';
import { FilterList } from '../../ui-components/filter-list/filter-list';
import { Heading } from '../../ui-components/heading/heading';
import { Checkbox } from '../../ui-components/inputs/checkbox/checkbox';
import { Radio } from '../../ui-components/inputs/radio/radio';
import { gridSettings, StyledSidebarContainer, StyledSidebarContainerLine, StyledSidebar, StyledSidebarInner } from '../../ui-components/layouts/sidebar.style';
import { StyledDocumentsList, StyledDocumentRow, StyledDocument, StyledSearchInput } from '../instructions-for-use/instructions-for-use.styles';
import { StyledFilterHeadingContainer, StyledProductsContainer } from '../products/products.style';
import { LoadingSpinner } from '../loading-spinner/loading-spinner';
import { DocumentHolder, Type, Category, GetDocumentHolderResponse } from './document-holder';



const getTypesFromDocumentHolders = (documentHolders: DocumentHolder[]) => {
    const types = documentHolders.map((documentHolder) => {
        return documentHolder.type;
    });
    // Remove duplicates before returning.
    return types.filter((value, index, self) =>
        index === self.findIndex((t) => (
            t.id === value.id && t.name === value.name
        ))
    );
};


export const Files = (props: FilesProps) => {
    const {viewport, localize} = useContext(AppContext);
    const [loading, setLoading] = createSignal<boolean>(false);
    const [isTabletOrSmaller, setIsTabletOrSmaller] = createSignal<boolean>(false);
    const [isMobile, setIsMobile] = createSignal<boolean>(false);
    const [searchQuery, setSearchQuery] = createSignal('');

    const [documentHolders, setDocumentHolders] = createSignal<DocumentHolder[]>([]);
    const [types, setTypes] = createSignal<Type[]>([]);

    const [parentCategories, setParentCategories] = createSignal<Category[]>([]);
    const [subCategories, setSubCategories] = createSignal<Category[]>([]);


    // SELECTED States
    const [selectedType, setSelectedType] = createSignal<number>();
    const [selectedParentCategories, setSelectedParentCategories] = createSignal<number[]>([]);
    const [selectedSubCategories, setSelectedSubCategories] = createSignal<number[]>([]);

    createEffect(() => setIsTabletOrSmaller(viewport.width <= theme.breakpoints.TABLET));
    createEffect(() => setIsMobile(viewport.width <= theme.breakpoints.MOBILE));

    createEffect(async () => {
        setLoading(true);
        try {
            const typeIds = props.types ? [...props.types].join(',') : '';
            const url = `/files/document-holders/types?typeIds=${typeIds}`;
        
            const response = await fetch(url, {
                method: 'GET',
            });
        
            const data: GetDocumentHolderResponse = await response.json();
            
            setLoading(false);
            if (data?.success) {
                const types = getTypesFromDocumentHolders(data.documentHolders);
                setDocumentHolders(data.documentHolders);
                setTypes(types);
                if (types?.length > 0) {
                    // Set first type as clicked for now.
                    handleTypeClick(types[0]);
                }
            } else {
                setDocumentHolders([]);
            }
        } catch (e) {
            setLoading(false);
            setDocumentHolders([]);
        }
    });

    const handleTypeClick = (type: Type) => {
        setSelectedType(type.id);
        // Reset rest of the state
        setSubCategories([]);
        setSelectedParentCategories([]);
        setSelectedSubCategories([]);

        // Extract top parent categories from filtered documents
        const categories = filteredDocuments().flatMap(doc => doc.categories?.filter(cat => !cat.parentId));
        const uniqueTopParentCategories = [...new Map(categories.map(item => [item['id'], item])).values()];

        // Set the parent categories for the documents with the clicked type.
        setParentCategories(uniqueTopParentCategories);
        // Filter the document list

    };

    const handleCategoryClick = (category: Category) => {
        setSelectedParentCategories(prevSelected => {
            if (prevSelected.includes(category.id)) {
                return prevSelected.filter(id => id !== category.id);
            } else {
                return [...prevSelected, category.id];
            }
        });

        // Filter child categories based on selected parent categories
        const filteredChildCategories = documentHolders().flatMap(doc => doc.categories?.filter((cat) => {
            if (doc.type?.id !== selectedType()) {
                return false;
            }
            return selectedParentCategories().some((selectedCategoryId) => {
                if (cat.parentId === selectedCategoryId) {
                    return true;
                }
                return false;
            });
        }));

        const uniqueChildCategories = [...new Map(filteredChildCategories.map(item => [item['id'], item])).values()];
        setSubCategories(uniqueChildCategories);
    };

    const handleSubCategoryClick = (category: Category) => {
        setSelectedSubCategories(prevSelected => {
            if (prevSelected.includes(category.id)) {
                return prevSelected.filter(id => id !== category.id);
            } else {
                return [...prevSelected, category.id];
            }
        });

    };

    const filteredDocuments = () => {
        const documents = documentHolders().filter((documentHolder) => {

            // First, if there is a search query active, make sure it's matching.
            if (searchQuery().length) {
                const nameMatch = documentHolder.name.toLocaleLowerCase().includes(searchQuery());
                const categoryMatch = documentHolder.categories.some( (cat) => cat.name.toLocaleLowerCase().includes(searchQuery()) );
                if (!nameMatch && !categoryMatch) {
                    return false;
                }
            }

            // If they dont have a filter menu we show every document.
            if (!props.showFilterMenu) {
                return true;
            }


            // Then, make sure it has the same type.
            if (documentHolder.type?.id !== selectedType()) {
                return false;
            }

            // Then, if no top category has been selected, show it.
            if (selectedParentCategories().length === 0) {
                return true;
            }

            // If categories has been selected, make sure it has at least one matching category with the selected ones.
            const hasAnySelectedCategory = documentHolder.categories.some((category) => selectedParentCategories().includes(category.id));
            if (!hasAnySelectedCategory) {
                return false;
            }

            // Then, IF any subCategories has been selected, make sure it has at least one of those.
            if (selectedSubCategories().length) {
                const hasAnySelectedChildCategory = documentHolder.categories.some((category) => selectedSubCategories().includes(category.id));
                if (!hasAnySelectedChildCategory) {
                    return false;
                }
            }

            return true;
        });
        return documents;
    };

    const handleSearchInput = (e: any) => {
        setSearchQuery(e.target.value.toLowerCase());
    };

    return (
        <ErrorCatcher componentName='Files'>
            <Section 
                templateShorthand={[12]}
                widthType={'bgFull'}
                heightType={'fill'}
                backgroundType={'color'}
                backgroundValue={'white'}
                removeSpacingBlock={true}
                customCss={'padding-top: 2rem; padding-bottom: 2rem;'}
            >
                <Grid {...gridSettings.container}>
                    <Show when={!loading()} fallback={<LoadingSpinner />}>
                        <Show when={!isTabletOrSmaller() && props.showFilterMenu}>
                            <StyledSidebarContainer>
                                <StyledSidebarContainerLine>
                                    <StyledSidebar>
                                        <StyledSidebarInner>
                                            {/*
                                            <Show when={isTabletOrSmaller()}>
                                                // This is broken, but whenever we're implementing it we could probably try to fix it so we can reuse it.
                                                // Instead of building something completely new.
                                                // We dont use the same store though, so would need some changes even if we fix it.
                                                <Show when={isMobile()}>
                                                    <MobileProductsSidebar
                                                        store={{
                                                            store: IfuProductsStore,
                                                            changeAreaOfCare,
                                                            clearFilters,
                                                            changeSelectedProductCategory,
                                                            changeSelectedType,
                                                        }}
                                                    />
                                                </Show>
                                            </Show>
                                            */}

                                            <StyledFilterHeadingContainer>
                                                <Heading tag='h2' variant='medium' noBlockSpacing={true}>{localize('filters', 'Filters')}</Heading>
                                            </StyledFilterHeadingContainer>

                                            <FilterList listHeading={localize('type', 'Type')}>
                                                <For each={types()}>{(type: Type) => (
                                                    <li>
                                                        <Radio
                                                            value={type.uuid}
                                                            whenClicked={() => handleTypeClick(type)}
                                                            name='type'
                                                            readableName={localize(type.name, type.name)}
                                                            isChecked={selectedType() === type.id}
                                                        />
                                                    </li>
                                                )}</For>
                                            </FilterList>

                                            <Show when={parentCategories().length}>
                                                <FilterList listHeading={localize('category', 'Category')}>
                                                    <For each={parentCategories()}>{(category) => (
                                                        <li>
                                                            <Checkbox
                                                                value={category?.uuid}
                                                                whenClicked={() => handleCategoryClick(category)}
                                                                name={localize(category?.name, category.name)}
                                                                isChecked={selectedParentCategories().includes(category?.id)}
                                                            />
                                                        </li>
                                                    )}</For>
                                                </FilterList>
                                            </Show>

                                            <Show when={subCategories().length}>
                                                <FilterList listHeading={localize('sub-category', 'Sub Category')}>
                                                    <For each={subCategories()}>{(category) => (
                                                        <li>
                                                            <Checkbox
                                                                value={category.uuid}
                                                                whenClicked={() => handleSubCategoryClick(category)}
                                                                name={localize(category.name, category.name)}
                                                                isChecked={selectedSubCategories().includes(category.id)}
                                                            />
                                                        </li>
                                                    )}</For>
                                                </FilterList>
                                            </Show>

                                        </StyledSidebarInner>
                                    </StyledSidebar>
                                </StyledSidebarContainerLine>
                            </StyledSidebarContainer>
                        </Show>

                        <StyledProductsContainer>
                            <div>
                                <Heading tag='h2' variant='xxlarge'>
                                    { props.headline }
                                </Heading>
                                <StyledSearchInput
                                    oninput={(e: any) => handleSearchInput(e)}
                                    placeholder={localize('search', 'Search...')}
                                />
                            </div>
                            <StyledDocumentsList>
                                <For each={filteredDocuments()}>
                                    {(documentHolder: DocumentHolder) => (
                                        <StyledDocumentRow>
                                            <StyledDocument href={`/downloads/${documentHolder.id}/${documentHolder.slug}`} target="_blank">
                                                {documentHolder.name}
                                            </StyledDocument>
                                        </StyledDocumentRow>
                                    )}
                                </For>
                            </StyledDocumentsList>
                        </StyledProductsContainer>
                    </Show>
                </Grid>
            </Section>
        </ErrorCatcher>
    );
};

export type FilesProps = {
    types: string[];
    headline: string;
    showFilterMenu: string;
}
