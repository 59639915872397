/* eslint-disable indent */

import { theme, ColorName } from '../../style/theme';
import { containerQueries } from '../../tools/check-container-support';

const {grid} = theme;

export const desktopLargeCss = (bgValue: ColorName) => {    
    if (bgValue === 'transparent') return '';

    return `
        ${containerQueries('desktopLargeMax')} {
            column-gap: ${grid.gapSizeTablet}rem;
        }
    `;
};

export const desktopCss = () => {
    return `
        ${containerQueries('desktopMax')} {
            .atos-column-flex > .atos-column {
                column-gap: 0;
            }
            column-gap: ${grid.gapSizeTablet}rem;
        }
    `;
};

export const tabletCss = (bgImage: string, backgroundValue: string) => {
    const gap = grid.standardUnit;

    return `
        ${containerQueries('tabletMax')} {
            ${bgImage ? 'min-height: 50vw;' : ''}
            ${backgroundValue && backgroundValue !== 'transparent' ? `
                padding-top: ${gap * 2}rem;
                padding-bottom: ${gap * 1.5}rem;
            ` : ''}

            &.combine-with-right:not(.with-divider):not(.with-image) {
                padding-bottom: ${theme.grid.gapSizeMobile}rem;
                &:has(+ .with-image) {
                    padding-bottom: ${gap * 2}rem;
                }
                & + .atos-column {
                    padding-top: ${theme.grid.gapSizeMobile}rem;
                }
            }
    
        }
    `;
};

type MobileCssProps = {
    bgImage: string;
    bgValue?: string;
}
export const mobileCss = (p: MobileCssProps) => {

    return `
        ${containerQueries('mobileMax')} {
            column-gap: ${grid.gapSizeMobile}rem;
            ${p.bgValue && p.bgValue !== 'transparent' ? `
                padding-top: ${grid.gapSizeTablet}rem;
                padding-bottom: ${grid.gapSize}rem;
            ` : ''}

            ${p.bgImage ? `
                min-height: 20rem;
            ` : ''}

            grid-template-columns:
                0px
                auto
                0px;
            
            & > * {
                grid-column-start: 2;
                grid-column-end: span 1;
                grid-row-end: span 1;
                grid-row-start: auto;
            }
        }
    `;
};
