import { useLocation } from '@solidjs/router';
import { createSignal, Match, onMount, Show, Switch, useContext } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';
import theme from '../../style/theme';
import { ErrorCatcher } from '../../tools/error-catcher';
import { LockedContent } from '../../ui-components/layouts/locked-content';
import { Text } from '../../ui-components/text/text';
import { StyledFlexRow } from '../../ui-components/utility-style-components/flex';
import { StyledRelativeContainer } from '../../ui-components/utility-style-components/positioning';
import { DocumentTypeFlag } from '../document-type-flag/document-type-flag';
import { ArrowRightIcon } from '../icons-library/arrow-right';
import { getBackgroundImageSrcData, getImageServiceSrcPath, ImageSrcData } from '../image-component/image-component';
import { MyContentHandler } from '../my-content-handler/my-content-handler';
import type { PostCardProps } from './post-card-types';
import {
    StyledBottomRow,
    StyledCardContent,
    StyledCardTitle,
    StyledDate,
    StyledImage,
    StyledImagePlaceholder,
    StyledLargeCardBottomRow,
    StyledLargeCardColumns,
    StyledLargeCardColumnsWrapper,
    StyledLargeCardTitle,
    StyledOverlayBottomRow,
    StyledOverlayContent,
    StyledOverlayContentInner,
    StyledPostCard,
    StyledPostCardWrapper,
    StyledReadMoreText,
    StyledPostCardTop,
    StyledPostCardBottom,
    StyledCardOverlayDate,
} from './post-card.styles';
import { formatDateDigits, formattedDayMonth } from '../../tools/date-format';
import { Heading } from '../../ui-components/heading/heading';
import { AtosLogoRelative } from '../icons-library/atos-logo-relative';
import { removeTrailingSlash } from '../../tools/remove-trailing-slash';
import removeAmpersand from '../../tools/remove-ampersand';
import IntersectionObserver from '../intersection-observer/intersection-observer';
import { DOMElement } from 'solid-js/jsx-runtime';

export const PostCard = (props: PostCardProps) => {
    const { imagesServiceUrl, supportedImageFormats, viewport, localize, userState, pageRegistration, siteInfo } = useContext(AppContext);
    const [backgroundImageSrcData, setBackgroundImageSrcData] = createSignal<ImageSrcData>();
    const [lowResBackgroundImagePath, setLowResBackgroundImagePath] = createSignal<string>();

    const imageWidth = () => {
        // Because this is a background image which sizes itself to the container, we need to set the width larger than the container, so it has room to grow/shrink.
        if (viewport.width <= theme.breakpoints.TABLET_SMALL) {
            return 500; // Mobile
        }

        if (viewport.width <= theme.breakpoints.DESKTOP && viewport.width > theme.breakpoints.TABLET_SMALL) {
            return 960; // Tablet
        }

        return 600; // Desktop
    };

    onMount(() => {
        if (!props?.data?.listCardContent?.image?.url) return;

        const lowRes = getImageServiceSrcPath({
            imagesServiceUrl,
            supportedImageFormats,
            src: props?.data?.listCardContent?.image?.url || '',
            width: 1,
            height: 1,
        });

        setLowResBackgroundImagePath(lowRes);
    });

    const cardDate = () => {
        const dateToUse = props.data.type === 'event' ? props.data.eventDate : props.data.date;
        if (dateToUse) {
            return {
                formattedDate: formatDateDigits(dateToUse, siteInfo.key),
                formattedDayMonth: formattedDayMonth(dateToUse, siteInfo.key),
            };
        }
        return null;
    };

    const eventLocation = () => {
        if (!props.data?.eventLocation) {
            return '';
        }
        return ' | ' + props.data?.eventLocation;
    };

    if (!props.data.url) {
        return null;
    }

    const isHiddenForPublic = () => {
        return props.data.isHealthcareProfessional 
            && !props.data.isVisibleForPublic 
            && !userState.user 
            && pageRegistration.url !== '';
    };

    const getCustomDocumentTypeFlagText = () => {
        if (props.data.isFullyBooked) {
            return localize('event-fully-booked', 'Fully Booked');
        } else if (props.data.type === 'blog-and-news' && props?.data?.categories && props.data?.categories?.length > 0) {
            return props.data.categories[0].name;
        } else if (props.data.type === 'blog-and-news') {
            return localize('blog', 'Blog');
        } else if (props.data.type === 'event' && props.data.eventExpireDate) {
            return localize('on-demand', 'On demand');
        }

        return undefined;
    };

    const showImage = (visible: boolean | DOMElement) => {
        if (!visible || !props?.data?.listCardContent?.image?.url) {
            return;
        }

        const backgroundImage: ImageSrcData = getBackgroundImageSrcData({
            imagesServiceUrl,
            supportedImageFormats,
            src: props.data.listCardContent.image.url,
            width: imageWidth() || 500, // Assume mobile from the start
            height: 'AUTO',
        });

        setBackgroundImageSrcData(backgroundImage);
    };

    return (
        <ErrorCatcher componentName="Post card">
            <IntersectionObserver onVisible={showImage} threshold={0.1}>
                <div style={{ height: '100%' }}>
                    <StyledPostCardWrapper large={props.largeCard} type={props.data.type}>
                        <StyledPostCard
                            href={isHiddenForPublic() ? useLocation().pathname : removeTrailingSlash(props.data.url)}
                            orientation={props.orientation}
                            inGrouping={props.inGrouping}
                            disableClickEvents={isHiddenForPublic()}
                            stale={props.data.stale}
                        >
                            <Show when={!props.hideTypeFlag}>
                                <DocumentTypeFlag
                                    size="small"
                                    type={props.data.type}
                                    date={cardDate()?.formattedDayMonth}
                                    customText={getCustomDocumentTypeFlagText()}
                                />
                            </Show>

                            <StyledPostCardTop large={props.largeCard}>
                                <Show
                                    when={props.data.listCardContent?.image?.url?.length}
                                    fallback={
                                        <StyledImagePlaceholder title={props?.data?.listCardContent?.image?.name} large={props.largeCard}>
                                            <AtosLogoRelative />
                                        </StyledImagePlaceholder>
                                    }
                                >
                                    <StyledImage
                                        src={lowResBackgroundImagePath()}
                                        alt={props?.data?.listCardContent?.image?.alt || props?.data?.listCardContent?.image?.name || ''}
                                        title={props?.data?.listCardContent?.image?.name || ''}
                                        large={props.largeCard}
                                        animate={!!backgroundImageSrcData()}
                                        lowRes={true}
                                    />
                                    <StyledImage
                                        src={backgroundImageSrcData()?.original || lowResBackgroundImagePath()}
                                        alt={props?.data?.listCardContent?.image?.alt || props?.data?.listCardContent?.image?.name || ''}
                                        title={props?.data?.listCardContent?.image?.name || ''}
                                        large={props.largeCard}
                                    />
                                </Show>
                            </StyledPostCardTop>

                            <StyledPostCardBottom>
                                <Switch>
                                    <Match when={props.largeCard}>
                                        <StyledCardContent>
                                            <StyledLargeCardColumnsWrapper>
                                                <StyledLargeCardColumns>
                                                    <div>
                                                        <Show when={props.data.type === 'event' && props.data.eventDate}>
                                                            <StyledDate>{`${cardDate()?.formattedDate} ${eventLocation()}`}</StyledDate>
                                                        </Show>
                                                        <StyledLargeCardTitle>{removeAmpersand(props.data.listCardContent?.title)}</StyledLargeCardTitle>
                                                    </div>
                                                    <div>
                                                        <Text displayRedVerticalLine={false} fontSize="normal" splitParagraph={true} color={'darkGray'}>
                                                            {props.data.listCardContent?.cardText}
                                                        </Text>
                                                    </div>
                                                </StyledLargeCardColumns>
                                                <StyledLargeCardBottomRow>
                                                    <MyContentHandler
                                                        action="add"
                                                        title={props?.data?.listCardContent?.title}
                                                        permalink={props?.data?.permalink}
                                                        type={props?.data?.type}
                                                        onlyIcon={true}
                                                    />
                                                    <StyledFlexRow justifyContent="end" alignItems="center">
                                                        <StyledRelativeContainer top={7}>
                                                            <ArrowRightIcon fill={theme.palette.red} />
                                                        </StyledRelativeContainer>
                                                    </StyledFlexRow>
                                                </StyledLargeCardBottomRow>
                                            </StyledLargeCardColumnsWrapper>
                                        </StyledCardContent>
                                    </Match>
                                    <Match when={!props.largeCard}>
                                        <StyledCardContent>
                                            <Show when={props.data.type === 'event' && props.data.eventDate}>
                                                <StyledDate>{`${cardDate()?.formattedDate} ${eventLocation()}`}</StyledDate>
                                            </Show>
                                            <StyledBottomRow>
                                                <StyledCardTitle type={props.data.type}>
                                                    <Heading tag={'h4'} variant="productNames">
                                                        {props.data.listCardContent?.title}
                                                    </Heading>
                                                </StyledCardTitle>
                                                <StyledRelativeContainer top={3}>
                                                    <ArrowRightIcon fill={theme.palette.red} />
                                                </StyledRelativeContainer>
                                            </StyledBottomRow>
                                        </StyledCardContent>
                                        <StyledOverlayContent>
                                            <StyledOverlayContentInner>
                                                <Show when={props.data.type === 'event' && props.data.eventDate}>
                                                    <StyledCardOverlayDate>{`${cardDate()?.formattedDate} ${eventLocation()}`}</StyledCardOverlayDate>
                                                </Show>
                                                <StyledCardTitle type={props.data.type}>
                                                    <Heading tag={'h4'} variant="productNames">
                                                        {props.data.listCardContent?.title}
                                                    </Heading>
                                                </StyledCardTitle>
                                                <Text displayRedVerticalLine={false} fontSize="normal" splitParagraph={false} lineLimit={4} color={'darkGray'}>
                                                    {props.data.listCardContent?.cardText}
                                                </Text>
                                            </StyledOverlayContentInner>
                                            <StyledOverlayBottomRow>
                                                <MyContentHandler
                                                    action="add"
                                                    title={props?.data?.listCardContent?.title}
                                                    permalink={props?.data?.permalink}
                                                    type={props?.data?.type}
                                                    onlyIcon={true}
                                                />
                                                <StyledFlexRow justifyContent="end" alignItems="center">
                                                    <StyledReadMoreText>{localize('read-more', 'Read more')}</StyledReadMoreText>
                                                    <StyledRelativeContainer top={3}>
                                                        <ArrowRightIcon fill={theme.palette.red} />
                                                    </StyledRelativeContainer>
                                                </StyledFlexRow>
                                            </StyledOverlayBottomRow>
                                        </StyledOverlayContent>
                                    </Match>
                                </Switch>
                            </StyledPostCardBottom>
                            <Show when={isHiddenForPublic()}>
                                <LockedContent />
                            </Show>
                        </StyledPostCard>
                    </StyledPostCardWrapper>
                    <MyContentHandler action="remove" permalink={props?.data?.permalink} />
                </div>
            </IntersectionObserver>
        </ErrorCatcher>
    );
};
