import { Link } from '@solidjs/router';
import clsx from 'clsx';
import { For, Show, createSignal, onCleanup, onMount } from 'solid-js';
import { ChevronUpBoldIcon } from '../../icons-library/chevron-up-bold';
import { PrimaryMenuData } from '../../menu/types/primary-menu-types';
import { StyledButton, StyledDropDown, StyledDropDownItem, StyledLinkContent, StyledLinkSpan } from './header-atos-care.style';
import urlMatches from '../../../tools/url-match';
import removeAmpersand from '../../../tools/remove-ampersand';

type HeaderAtosCareDropdownMenuProps = {
    primaryMenuData: PrimaryMenuData;
};

export const HeaderAtosCareDropdownMenu = (props: HeaderAtosCareDropdownMenuProps) => {
    const [activeSubmenu, setActiveSubmenu] = createSignal(0);

    const buttonClasses = 'flex items-center px-6 py-4 leading-[1.93rem]';
    const transitionClasses = 'transition-color ease-in-out duration-300';

    const handleOnClick = (e: Event, ID: number) => {
        e.stopImmediatePropagation();
        setActiveSubmenu(activeSubmenu() === ID ? 0 : ID);
    };

    let dropdownMenuRef: HTMLDivElement | undefined;
    const handleOutsideClick = () => {
        const handleClickOutside = (event: Event) => {
            if (!dropdownMenuRef?.contains(event.target as Node) && activeSubmenu() !== 0) {
                setActiveSubmenu(0);
            }
        };

        window.addEventListener('click', handleClickOutside);

        onCleanup(() => {
            window.removeEventListener('click', handleClickOutside);
        });
    };

    onMount(() => {
        handleOutsideClick();
    });

    const closeSubmenues = () => setActiveSubmenu(0);

    return (
        <nav class="flex flex-wrap min-h-headerDesktop">
            <For each={props.primaryMenuData?.menu}>
                {(item, index) => (
                    <Show
                        fallback={
                            <Link class={clsx(buttonClasses, transitionClasses)} href={item.url}>
                                <StyledLinkSpan active={urlMatches(item.url)}>{removeAmpersand(item.title)}</StyledLinkSpan>
                            </Link>
                        }
                        when={item.children}
                    >
                        <div class="relative">
                            <StyledButton
                                aria-label={(activeSubmenu() === item.ID ? 'Hide' : 'Show') + ' submenu for ' + item.title}
                                class={clsx(buttonClasses, transitionClasses)}
                                data-index={index()}
                                onClick={(e) => handleOnClick(e, item.ID)}
                                active={item.children.some((child: any) => urlMatches(child.url))}
                            >
                                {removeAmpersand(item.title)}
                                <span
                                    class={clsx('ml-[0.625rem]', 'transform', 'translate-y-[2px] transform transition-transform duration-300 origin-center', {
                                        'rotate-180': activeSubmenu() === item.ID,
                                    })}
                                >
                                    <ChevronUpBoldIcon />
                                </span>
                            </StyledButton>

                            <Show when={item.ID === activeSubmenu()}>
                                <div class="sub-menu absolute animate-fade-in-move-up" ref={dropdownMenuRef}>
                                    <StyledDropDown class="shadow-subtle text-left translate-x-5 text-[1.125rem] min-w-[25rem] rounded-[1.07rem] bg-white px-3 text-black">
                                        <For each={item?.children}>
                                            {(subItem: any) => (
                                                <li>
                                                    <StyledDropDownItem 
                                                        active={false} 
                                                        class={clsx('block py-6 px-3', transitionClasses)}
                                                        href={subItem.url}
                                                        onClick={closeSubmenues}
                                                    >
                                                        <StyledLinkContent active={urlMatches(subItem.url)}>{removeAmpersand(subItem.title)}</StyledLinkContent>
                                                    </StyledDropDownItem>
                                                </li>
                                            )}
                                        </For>
                                    </StyledDropDown>
                                </div>
                            </Show>
                        </div>
                    </Show>
                )}
            </For>
        </nav>
    );
};
