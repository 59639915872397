/* eslint-disable indent */
import theme, { fontFamilyAtosBold, fontFamilyAtosLight, fontFamilyAtosRegular } from '../../style/theme';

export const common = `
    margin-top: 0 !important;
    margin-bottom: 0.67em;
    word-break: break-word;
    hyphens: auto;
    &:last-child {
        margin-bottom: 0;
    }
`;

type CalcFontSizeProps = {
    minMaxFont: number[],
    minMaxLine: number[],
}
export const calcFontSize = (fs: CalcFontSizeProps) => {
    const minFont = fs.minMaxFont[0];
    const maxFont = fs.minMaxFont[1];

    const minLine = fs.minMaxLine[0];
    const maxLine = fs.minMaxLine[1];
    const vwUnit = 1; // This is the html font-size which we've set in global.styles

    const fontSizeClamp = Math.round((minFont / vwUnit + Number.EPSILON) * 100) / 100;
    const lineHeightClamp = Math.round((minLine / vwUnit + Number.EPSILON) * 100) / 100;

    return `
        font-size: clamp(${minFont}rem, ${minFont}rem + ${fontSizeClamp}vw, ${maxFont}rem);
        line-height: clamp(${minLine}rem, ${minLine}rem + ${lineHeightClamp}vw, ${maxLine}rem);
    `;
};

/* Sizes */
const xxlarge = `
    ${calcFontSize({
        minMaxFont: [2.7, 3.7],
        minMaxLine: [3, 4.5]
    })}
    letter-spacing: -0.09rem;
    font-family: ${fontFamilyAtosLight};
    font-weight: normal;
    color: ${theme.colors.text.heading};
    ${theme.mediaQueries.desktopMax} {
        letter-spacing: -1px;
    }
    ${theme.mediaQueries.mobileMax} {
        letter-spacing: -0.06rem;
        font-size: 2.7rem;
        line-height: 3rem;
    }
`;

const xlarge = `
    ${calcFontSize({
        minMaxFont: [2.8, 3.3],
        minMaxLine: [3, 3.8]
    })}
    letter-spacing: -0.08rem;
    font-family: ${fontFamilyAtosLight};
    font-weight: normal;
    color: ${theme.colors.text.heading};
    ${theme.mediaQueries.desktopMax} {
        letter-spacing: -0.06rem;
    }
    ${theme.mediaQueries.mobileMax} {
        letter-spacing: -0.05rem;
        font-size: 2.8rem;
        line-height: 4.5rem;
    }
`;

const large = `
    font-size: 2.5rem;
    line-height: 3.5rem;
    letter-spacing: -0.06rem;
    font-family: ${fontFamilyAtosLight};
    font-weight: normal;
    color: ${theme.colors.text.heading};
    ${theme.mediaQueries.desktopMax} {
        letter-spacing: -0.05rem;
    }
    ${theme.mediaQueries.mobileMax} {
        letter-spacing: -0.005rem;
        font-size: 2.3rem;
    }
`;

const medium = `
    font-size: 2.1rem;
    letter-spacing: -0.05rem;
    font-family: ${fontFamilyAtosLight};
    font-weight: normal;
    color: ${theme.colors.text.heading};
    line-height: 3rem;
    ${theme.mediaQueries.desktopMax} {
        letter-spacing: -0.05rem;
    }
    ${theme.mediaQueries.mobileMax} {
        letter-spacing: -0.04rem;
        font-size: 1.9rem;
    }
`;

const small = `
    ${calcFontSize({
        minMaxFont: [1.3, 1.3],
        minMaxLine: [3, 3.8]
    })}
    letter-spacing: 0;
    font-family: ${fontFamilyAtosBold};
    font-weight: 800;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 0;
    color: ${theme.colors.text.heading};
    ${theme.mediaQueries.desktopMax} {
        letter-spacing: -0.08px;
    }
    ${theme.mediaQueries.mobileMax} {
        letter-spacing: -0.0114rem;
        font-size: 1.3rem;
        line-height: 3rem;
    }
`;

/** Styles */
const hero = `
    ${calcFontSize({
        minMaxFont: [3.9, 6.4],
        minMaxLine: [5.2, 7.1]
    })}
    letter-spacing: -0.15rem;
    font-family: ${fontFamilyAtosLight};
    font-weight: normal;
    margin-bottom: 0.5em;
    color: ${theme.colors.text.heading};
    ${theme.mediaQueries.desktopMax} {
        letter-spacing: -0.11rem;
    }
    ${theme.mediaQueries.mobileMax} {
        letter-spacing: -0.15rem;
        font-size: 3.9rem;
        line-height: 5.2rem;
    }
`;

const heroEyebrow = `
    ${calcFontSize({
        minMaxFont: [1.35, 1.6],
        minMaxLine: [3.1, 3.1]
    })}
    letter-spacing: -0.05rem;
    font-family: ${fontFamilyAtosBold};
    font-weight: 800;
    text-transform: uppercase;
    color: ${theme.colors.text.hero};
    margin-top: 0;
    margin-bottom: 0;
    ${theme.mediaQueries.desktopMax} {
        letter-spacing: -0.014rem;
    }
    ${theme.mediaQueries.mobileMax} {
        letter-spacing: -0.0114rem;
        font-size: 1.35rem;
        line-height: 3.1rem;
    }
`;

const bigGray = `
    ${calcFontSize({
        minMaxFont: [1.8, 2.3],
        minMaxLine: [2.3, 2.9]
    })}
    letter-spacing: 0;
    font-family: ${fontFamilyAtosBold};
    font-weight: 800;
    color: ${theme.colors.text.heading};
    ${theme.mediaQueries.mobileMax} {
        font-size: 1.8rem;
        line-height: 2.3rem;
    }
`;

const smallGray = `
    ${calcFontSize({
        minMaxFont: [1.6, 2],
        minMaxLine: [2.3, 3.2]
    })}
    letter-spacing: 0;
    font-family: ${fontFamilyAtosBold};
    font-weight: 800;
    color: ${theme.colors.text.heading};
    ${theme.mediaQueries.mobileMax} {
        font-size: 1.6rem;
        line-height: 2.3rem;
    }
`;

const tinyGray = `
    ${calcFontSize({
        minMaxFont: [1.4, 1.6],
        minMaxLine: [2.1, 2.3]
    })}
    letter-spacing: 0;
    font-family: ${fontFamilyAtosBold};
    font-weight: 800;
    color: ${theme.colors.text.heading};
    ${theme.mediaQueries.mobileMax} {
        font-size: 1.4rem;
        line-height: 2.1rem;
    }
`;

const breadcrumb = `
    font-size: 1.8rem;
    line-height: 2.3rem;
    letter-spacing: 0;
    font-family: ${fontFamilyAtosLight};
    font-weight: normal;
    color: ${theme.palette.gray};
`;

const productNames = `
    ${calcFontSize({
        minMaxFont: [1.65, 1.65],
        minMaxLine: [2.1, 2.1]
    })}
    letter-spacing: 0;
    font-family: ${fontFamilyAtosBold};
    font-weight: 800;
    color: ${theme.colors.text.heading};
    ${theme.mediaQueries.mobileMax} {
        font-size: 1.65rem;
        line-height: 2.1rem;
    }
`;

const giantRed = `
    ${calcFontSize({
        minMaxFont: [2.3, 3],
        minMaxLine: [2.1, 2.9]
    })}
    letter-spacing: 0;
    font-family: ${fontFamilyAtosBold};
    font-weight: 800;
    color: ${theme.colors.text.red};
    ${theme.mediaQueries.mobileMax} {
        font-size: 2.3rem;
        line-height: 2.1rem;
    }
`;

const bigRed = `
    ${calcFontSize({
        minMaxFont: [2.3, 2.3],
        minMaxLine: [2.1, 2.9]
    })}
    letter-spacing: 0;
    font-family: ${fontFamilyAtosBold};
    font-weight: 800;
    color: ${theme.colors.text.red};
    ${theme.mediaQueries.mobileMax} {
        font-size: 2.3rem;
    }
`;

const smallRed = `
    ${calcFontSize({
        minMaxFont: [1.6, 2],
        minMaxLine: [2.3, 2.6]
    })}
    letter-spacing: 0;
    font-family: ${fontFamilyAtosBold};
    font-weight: 800;
    color: ${theme.colors.text.red};
    ${theme.mediaQueries.mobileMax} {
        font-size: 1.6rem;
        line-height: 2.3rem;
    }
`;

const tinyRed = `
    ${calcFontSize({
        minMaxFont: [1.4, 1.6],
        minMaxLine: [2.1, 2.3]
    })}
    letter-spacing: 0;
    font-family: ${fontFamilyAtosBold};
    font-weight: 800;
    color: ${theme.colors.text.red};
    ${theme.mediaQueries.mobileMax} {
        font-size: 1.4rem;
        line-height: 2.1rem;
    }
`;

/**
 * For PDF Builder
 */

const xxlargePdfShared = `
    letter-spacing: -0.09rem;
    font-size: 3rem;
    font-style: normal;
    font-weight: 300;
    line-height: 130%;
    color: #000;
`;

const xxlargePDF = `
    ${xxlargePdfShared}
    font-family: ${fontFamilyAtosRegular};
`;

const xxlargeBoldPDF = `
    ${xxlargePdfShared}
    font-family: ${fontFamilyAtosBold};
`;

const xlargePdfShared = `
    letter-spacing: -0.08rem;
    font-size: 2.7rem;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    color: #000;
`;

const xlargePDF = `
    ${xlargePdfShared}
    font-family: ${fontFamilyAtosRegular};
`;

const xlargeBoldPDF = `
    ${xlargePdfShared}
    font-family: ${fontFamilyAtosBold};
`;

const largePDF = `
    letter-spacing: -0.06rem;
    font-family: ${fontFamilyAtosRegular};
    font-size: 2.3rem;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    color: #000;
`;

const mediumPDFShared = `
    letter-spacing: -0.05rem;
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    color: #000;
`;

const mediumPDF = `
    ${mediumPDFShared}
    font-family: ${fontFamilyAtosRegular};
`;

const mediumBoldPDF = `
    ${mediumPDFShared}
    font-family: ${fontFamilyAtosBold};
`;

const smallPDF = `
    letter-spacing: 0;
    font-family: ${fontFamilyAtosRegular};
    margin-top: 0;
    font-size: 0.95rem;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-transform: uppercase;
    color: #000;
`;

const heroPDF = `
    font-size: 3.3rem;
    font-family: ${fontFamilyAtosRegular};
    line-height: normal;
    font-weight: 300;
    margin-bottom: 0.5em;
    color: #000;
`;

const heroEyebrowPDF = `
    font-size: 1.42rem;
    font-style: normal;
    font-weight: 700;
    line-height: 3.1rem;
    text-transform: uppercase;
    letter-spacing: -0.05rem;
    font-family: ${fontFamilyAtosRegular};
    margin-bottom: 0;
    color: #000;
`;

const bigGrayPDF = `
    font-size: 1.91rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0;
    font-family: ${fontFamilyAtosBold};
    color: #000;
`;

const smallGrayPDF = `
    font-size: 1.53rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0;
    font-family: ${fontFamilyAtosBold};
    color: #000;
`;

const tinyGrayPDF = `
    font-size: 1.33rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0;
    font-family: ${fontFamilyAtosBold};
    color: #000;
`;

const giantRedPDF = `
    font-size: 2.21rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0;
    font-family: ${fontFamilyAtosBold};
    color: ${theme.colors.text.red};
`;

const bigRedPDF = `
    font-size: 1.68rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0;
    font-family: ${fontFamilyAtosBold};
    color: ${theme.colors.text.red};
`;

const smallRedPDF = `
    font-size: 1.48rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0;
    font-family: ${fontFamilyAtosBold};
    color: ${theme.colors.text.red};
`;

const tinyRedPDF = `
    font-size: 1.18rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0;
    font-family: ${fontFamilyAtosBold};
    color: ${theme.colors.text.red};
`;

const productTitlePDF = `
    font-size: 3rem;
    line-height: 130%;
    letter-spacing: 0;
    font-family: ${fontFamilyAtosBold};
    font-weight: 800;
    color: #000;
`;


export const variants = {
    // Sizes:
    xxlarge,
    xlarge,
    large,
    medium,
    small,

    // Styles:
    hero,
    heroEyebrow,
    bigGray,
    smallGray,
    tinyGray,
    productNames,
    giantRed,
    bigRed,
    smallRed,
    tinyRed,

    breadcrumb,

    // PDF Builder
    xxlargePDF,
    xxlargeBoldPDF,
    xlargePDF,
    largePDF,
    xlargeBoldPDF,
    mediumPDF,
    mediumBoldPDF,
    smallPDF,

    heroPDF,
    heroEyebrowPDF,
    bigGrayPDF,
    smallGrayPDF,
    tinyGrayPDF,
    giantRedPDF,
    bigRedPDF,
    smallRedPDF,
    tinyRedPDF,
    productTitlePDF,
};
