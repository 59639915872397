import { createRoot, createSignal } from 'solid-js';

function createMenuStore() {
    const [menuOpen, setMenuOpen] = createSignal(false);
    const [loginMenuOpen, setLoginMenuOpen] = createSignal(false);

    const open = () => {
        setMenuOpen(true);
        const body = document.getElementsByTagName('body')[0];
        body.style.overflow = 'hidden';
    };
    const close = () => {
        setMenuOpen(false);
        const body = document.getElementsByTagName('body')[0];
        body.style.overflow = 'auto';
    };
    const openLoginMenu = () => {
        setLoginMenuOpen(true);
        const body = document.getElementsByTagName('body')[0];
        body.style.overflow = 'hidden';
    };
    const closeLoginMenu = () => {
        setLoginMenuOpen(false);
        const body = document.getElementsByTagName('body')[0];
        body.style.overflow = 'auto';
    };

    return {
        menuOpen,
        open,
        close,
        loginMenuOpen,
        openLoginMenu,
        closeLoginMenu,
    };
}

export default createRoot(createMenuStore);
