import { For, useContext } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';
import theme from '../../style/theme';
import { Slider } from '../../ui-components/slider/slider';
import { CustomCard } from './slider-card/slider-card';
import { Grid } from '../../grid-system/grid/grid';
import { StyledCardSliderContainer } from './card-slider.styles';
import { CardTypeFactory, PostTypeSlug, PostTypeType } from './card-type-factory';
import { ImageWordpress, LinkType } from '../../types/shared';


export type CustomCardType = {
    heading?: string;
    text?: string;
    image?: ImageWordpress;
    link?: LinkType;
    type: 'custom' | PostTypeSlug;
    selectedPostSlug: string;
}

export type CardSliderProps = {
    cards: CustomCardType[];
    cardsPerView: number;
    dynamicCards: boolean;
    posttype: PostTypeType;
    tag?: {
        slug: string;
        name: string;
        taxonomy: string;
        parent: number;
    };
}

export const CardSlider = (props: CardSliderProps) => {    
    const {viewport} = useContext(AppContext);

    const createCards = () => {
        return <CardTypeFactory dynamicCards={true} posttype={props.posttype} tag={props.tag} insideSlider={true} />;
    };

    const createSingleCard = (type: PostTypeSlug, postSlug: string) => {
        return (
            <CardTypeFactory
                dynamicCards={false}
                posttype={{
                    label: '',
                    value: type,
                }}
                postSlug={postSlug}
            />
        );
    };
    
    const createSliderCards = () => {
        let perView = props.cardsPerView;
        let gapSize = theme.grid.gapSize;

        switch (true) {
            case viewport.width <= theme.breakpoints.DESKTOP_LARGE && viewport.width > theme.breakpoints.TABLET_SMALL + 100:
                gapSize = theme.grid.gapSizeTablet / 2;
                perView = perView > 1 ? 2 : 1;
                break;
            case viewport.width <= theme.breakpoints.TABLET_SMALL + 100:
                perView = 1;
                break;
        }

        if (props.dynamicCards && props.posttype?.value) {
            const cards = createCards();
            return (
                <Slider slidesPerView={perView} spacing={gapSize} slideHeight={'auto'}>
                    {cards}
                </Slider>
            );
        }

        return (
            <Slider slidesPerView={perView} spacing={gapSize} slideHeight={'auto'}>
                <For each={props.cards as CustomCardType[]}>
                    {(card: CustomCardType) => {
                        if (card.type === 'custom') {
                            return <CustomCard card={card} />;
                        }

                        return createSingleCard(card.type, card.selectedPostSlug);
                    }}
                </For>
            </Slider>
        );

    };

    return (
        <StyledCardSliderContainer>
            <Grid templateShorthand={[12]}>
                <div>{createSliderCards()}</div>
            </Grid>
        </StyledCardSliderContainer>
    );
};


CardSlider.parseProps = (atts: any) => {
    return {
        dynamicCards: atts.dynamicCards,
        cards: atts.cards,
        cardsPerView: atts.cardsPerView,
        posttype: atts.posttype,
        tag: atts.tag,
    };
};
