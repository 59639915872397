export type ColorName =
    'transparent'
    | 'black'
    | 'darkestGray'
    | 'darkGray'
    | 'gray'
    | 'lightGray'
    | 'mediumPink'
    | 'lightPink'
    | 'white'
    | 'beige'
    | 'lightBeige'
    | 'red'
    | 'green'
    | 'pink'
    | 'shopGreen';

export type ColorObject = {
    name: ColorName;
    color?: string;
}

export type HorizontalAlignment = 'left' | 'center' | 'right';
export enum VerticalAlignment {
    top = 'top',
    center = 'center',
    bottom = 'bottom',
    equalHeightColumns = 'stretch',
    spread = 'spread',
}

export type Spacing = 'auto' | 'none' | 'smallest' | 'smaller' | 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge';
export type GridSpacing = 'none' | 'small' | 'medium' | 'large';

export enum FlexAlignment {
    top = 'flex-start',
    bottom = 'flex-end',
    center = 'center',
    middle = 'center',
    left = 'flex-start',
    right = 'flex-end',
    stretch = 'stretch',
    spread = 'space-between',
}

export const fontFamilyAtosLight = '"Atos Light", -apple-system, BlinkMacSystemFont, "SF Pro", "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"';
export const fontFamilyAtosRegular = '"Atos Regular", -apple-system, BlinkMacSystemFont, "SF Pro", "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"';
export const fontFamilyAtosBold = '"Atos Bold", -apple-system, BlinkMacSystemFont, "SF Pro", "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"';

export type QueryTypeSlug = 'mobileMax' | 'tabletMax' | 'tabletMaxOriginal' | 'desktopMax' | 'desktopSmallMax' | 'desktopSmallMin' | 'desktopLargeMax' | 'desktopLargeMin' | 'desktopMin' | 'tabletMin' | 'mobileMin' | 'tabletSmallMax' | 'tabletSmallMin' | 'paddingMax' | 'paddingMin';
type QueryTypes = {
    [key in QueryTypeSlug ]: string;
}

export type TextTransform = 'none' | 'uppercase' | 'lowercase' | 'capitalize';

const MAX_WIDTH = 1000;
const MAX_WIDTH_REM = MAX_WIDTH / 10;
const STANDARD_UNIT = 2.21; // rem
const COLUMNS = 12;

export const palette = {
    transparent: 'transparent' as ColorName,
    black: '#000000' as ColorName,

    darkestGray: '#403131' as ColorName,
    darkGray: '#726060' as ColorName,
    gray: '#918787' as ColorName,
    lightGray: '#BEB9B9' as ColorName,

    red: '#F0414B' as ColorName,
    pink: '#F3756B' as ColorName,
    mediumPink: '#F7A193' as ColorName,
    lightPink: '#FBC5B9' as ColorName,

    beige: '#EEEEEE' as ColorName,
    lightBeige: '#F4F4F4' as ColorName,
    white: '#FFFFFF' as ColorName,

    lightRed: '#F0414B' as ColorName,
    lightBlue: '#407EC9' as ColorName,
    darkGreen: '#074F59' as ColorName,
    darkBlue: '#1B365D' as ColorName,
    lightPurple: '#878CB4' as ColorName,
    darkColdGray: '#63666A' as ColorName,
    orange: '#F78E28' as ColorName,
    green: '#4B9560' as ColorName,
    shopGreen: '#DBE9D8' as ColorName,
    darkPurple: '#615E9B' as ColorName,
    lightColdGray: '#BEB9B9' as ColorName,
    brightGreen: '#76B640' as ColorName,
};

export const breakpoints = {
    MOBILE: 480,
    TABLET_SMALL: 600,
    TABLET: 600,
    ORIGINAL_TABLET: 920,
    DESKTOP_LARGE: 1440,
    DESKTOP: 1280,
    DESKTOP_SMALL: 1024,
};

export const theme = {
    palette: palette,
    breakpoints: breakpoints,
    colors: {
        text: {
            paragraph: palette.darkestGray,
            heading: palette.darkGray,
            hero: palette.gray,
            quote: palette.darkGray,
            h1: palette.darkGray,
            h2: palette.darkGray,
            h3: palette.darkGray,
            h4: palette.darkGray,
            h5: palette.darkGray,
            h6: palette.darkGray,
            red: palette.red,
            pink: palette.pink
        },
        neutral: {
            darkOne: palette.darkGray,
            darkTwo: palette.darkGray,
            darkThree: palette.gray,
            lightOne: palette.lightGray,
            lightTwo: palette.beige,
            lightThree: palette.lightBeige,
            lightFour: palette.white,
            background: palette.beige,
        },
        svg: {
            default: palette.darkGray,
        },
        footer: {
            background: palette.darkGray,
            textColor: palette.white,
        },
    },
    borderRadius: {
        xsmall: '0.36rem',
        small: '1.07rem',
        medium: '1.43rem',
        large: '2.21rem',
    },
    headerMenu: {

    },
    fontSize: {
        small: '1.625rem', // 13px
        medium: '1.875rem', // 15px
        normal: '2rem', // 17px
        large: '2.75rem', // 22px
    },
    header: {
        opaqueBackgroundColor: 'rgba(240, 239, 234, 0.5)',
        desktop: {
            height: {
                fixed: {
                    value: 10,
                    unit: 'rem',
                },
                floating: {
                    value: 6.43,
                    unit: 'rem',
                },
                fixedAtosCare: {
                    value: 12.64, // 139px
                    unit: 'rem',
                },
                floatingAtosCare: {
                    value: 5.91, // 65px
                    unit: 'rem',
                },
            },
        },
        mobile: {
            height: {
                value: 6.43,
                unit: 'rem',
            },
        },
    },
    sideBarNavigation: {
        mobile: {
            height: {
                value: 3.995,
                unit: 'rem',
            },
        },
    },
    redLineThickness: '2px',
    contentMaxWidthExtra: '1440px',
    contentMaxWidth: '1280px',
    grid: {
        columns: COLUMNS,
        standardUnit: STANDARD_UNIT, // rem
        gapSize: 3.14, // rem
        gapSizeTablet: 3.14 * 1.5,
        gapSizeTabletSmall: 3.14 * 0.75,
        gapSizeMobile: 3.14 / 2,
        colSize: () => {
            const totalGapWidth = (COLUMNS - 1) * STANDARD_UNIT;
            const widthSansGutters = MAX_WIDTH_REM - totalGapWidth;
            const remainingColWidth = widthSansGutters / COLUMNS;
            const roundedColWidth = Math.round(remainingColWidth * 100) / 100;            
            return roundedColWidth;
        },
    },
    maxWidth: MAX_WIDTH,
    formPadding: {
        desktop: '40px',
        mobile: '20px',
    },
    outerPadding: '3rem',
    gap: {
        col: '4rem',
        row: '3.75rem',
    },
    mobileGap: {
        col: '2rem',
        row: '2rem',
    },
    baseSize: 16,
    mediaQueries: {
        tabletSmallMin: `@media only screen and (min-width: ${breakpoints.TABLET_SMALL}px)`,
        tabletSmallMax: `@media only screen and (max-width: ${breakpoints.TABLET_SMALL}px)`,
        // Tablet and larger
        tabletMin: `@media only screen and (min-width: ${breakpoints.TABLET}px)`,
        // Tablet and smaller
        tabletMax: `@media only screen and (max-width: ${breakpoints.TABLET}px)`,
        // Original tabet size for reference and legacy
        tabletMaxOriginal: `@media only screen and (max-width: ${breakpoints.ORIGINAL_TABLET}px)`,
        // Only mobile
        mobileMin: `@media only screen and (min-width: ${breakpoints.MOBILE}px)`,
        mobileMax: `@media only screen and (max-width: ${breakpoints.MOBILE}px)`,
        // Desktop and larger
        desktopSmallMin: `@media only screen and (min-width: ${breakpoints.DESKTOP_SMALL}px)`,
        desktopSmallMax: `@media only screen and (max-width: ${breakpoints.DESKTOP_SMALL}px)`,
        desktopMin: `@media only screen and (min-width: ${breakpoints.DESKTOP}px)`,
        desktopMax: `@media only screen and (max-width: ${breakpoints.DESKTOP}px)`,
        desktopLargeMin: `@media only screen and (min-width: ${breakpoints.DESKTOP_LARGE}px)`,
        desktopLargeMax: `@media only screen and (max-width: ${breakpoints.DESKTOP_LARGE}px)`,
        paddingMax: `@media only screen and (max-width: ${breakpoints.DESKTOP}px)`,
        paddingMin: `@media only screen and (min-width: ${breakpoints.DESKTOP}px)`,
    } as QueryTypes,
    containerQueries: {
        tabletSmallMin: `@container (min-width: ${breakpoints.TABLET_SMALL}px)`,
        tabletSmallMax: `@container (max-width: ${breakpoints.TABLET_SMALL}px)`,
        // Tablet and larger
        tabletMin: `@container (min-width: ${breakpoints.TABLET}px)`,
        // Tablet size and smaller
        tabletMax: `@container (max-width: ${breakpoints.TABLET}px)`,
        // Original tabet size for reference and legacy
        tabletMaxOriginal: `@container (max-width: ${breakpoints.ORIGINAL_TABLET}px)`,
        // Only mobile
        mobileMin: `@container (min-width: ${breakpoints.MOBILE}px)`,
        // Only mobile
        mobileMax: `@container (max-width: ${breakpoints.MOBILE}px)`,
        // Desktop and larger
        desktopMin: `@container (min-width: ${breakpoints.DESKTOP}px)`,
        desktopMax: `@container (max-width: ${breakpoints.DESKTOP}px)`,
        desktopLargeMin: `@container (min-width: ${breakpoints.DESKTOP_LARGE}px)`,
        desktopLargeMax: `@container (max-width: ${breakpoints.DESKTOP_LARGE}px)`,
        paddingMax: `@container (max-width: ${breakpoints.DESKTOP}px)`,
        paddingMin: `@container (min-width: ${breakpoints.DESKTOP}px)`,
    } as QueryTypes,
    gridSpacing: { // The multiplier is pretty randomly selected, but it allows us to scale
        auto: 'initial',
        none: 0,
        smallest: STANDARD_UNIT * 0.25,
        smaller: STANDARD_UNIT * 0.5,
        small:  STANDARD_UNIT * 0.75,
        medium: STANDARD_UNIT,
        large: STANDARD_UNIT * 2,
        xlarge: STANDARD_UNIT * 3.5,
        xxlarge: STANDARD_UNIT * 5.5,
    },
    spacing: {
        xxxs: '0.5rem',
        xxs: '1rem',
        xs: '1.5rem',
        s: '2rem',
        ms: '2.5rem',
        m: '3rem',
        ml: '3.5rem',
        l: '4rem',
        xl: '4.5rem',
        xxl: '5rem',
        xxxl: '6rem',
    },
    scaling: {
        desktopLargeMax: 1,
        desktopMax: 0.5,
        tabletMax: 0.5,
        mobileMax: 0.35,
    },
    menuBar: {
        height: '80px',
    },
    lighten: (color: string, amount: number) => {
        let usePound = false;

        if (color[0] == '#') {
            color = color.slice(1);
            usePound = true;
        }

        const number = parseInt(color, 16);

        let red = (number >> 16) + amount;

        if (red > 255) {
            red = 255;
        } else if (red < 0) {
            red = 0;
        }

        let green = (number & 0x0000ff) + amount;

        if (green > 255) {
            green = 255;
        } else if (green < 0) {
            green = 0;
        }

        let blue = ((number >> 8) & 0x00ff) + amount;

        if (blue > 255) {
            blue = 255;
        } else if (blue < 0) {
            blue = 0;
        }

        return (usePound ? '#' : '') + (green | (blue << 8) | (red << 16)).toString(16);
    },
};

export default theme;
