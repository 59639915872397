import { Link } from '@solidjs/router';
import { createSignal, Match, onMount, Show, Switch, useContext } from 'solid-js';
import { AppContext } from '../../../app-context-provider/app-context-provider';
import { ErrorCatcher } from '../../../tools/error-catcher';
import { LockedContent } from '../../../ui-components/layouts/locked-content';
import { getBackgroundImageSrcData, ImageSrcData } from '../../image-component/image-component';
import { getVimeoVideoId, getYoutubeVideoId } from '../../video-embed/helpers';
import { VideoPostCardProps } from './video-post-card-types';
import { StyledCardContent, StyledCardTitle, StyledImage, StyledVideoPostCard, StyledProductVideo, StyledVideoCardWrapper } from './video-post-card.styles';
import { MyContentHandler } from '../../my-content-handler/my-content-handler';
import { removeTrailingSlash } from '../../../tools/remove-trailing-slash';
import removeAmpersand from '../../../tools/remove-ampersand';

export const VideoPostCard = (props: VideoPostCardProps) => {
    const { imagesServiceUrl, supportedImageFormats, userState, pageRegistration, videosEntityName } = useContext(AppContext);
    const [backgroundImageSrcData, setBackgroundImageSrcData] = createSignal<ImageSrcData>();

    onMount(() => {
        if (props?.image?.url) {
            const backgroundImage: ImageSrcData = getBackgroundImageSrcData({
                imagesServiceUrl,
                supportedImageFormats,
                src: props.image.url,
                width: 500,
                height: 'AUTO',
            });

            setBackgroundImageSrcData(backgroundImage);
        }
    });

    if (!props.videoUrl) {
        return null;
    }

    const isHiddenForPublic = () => {
        return props.isHealthcareProfessional 
            && !props.isVisibleForPublic 
            && !userState.user 
            && pageRegistration.url !== '';
    };

    const postTypeSlug = '/video-item/';
    const permalinkWithEntityName = videosEntityName && props.permalink && props.permalink?.replace(postTypeSlug, `/${videosEntityName}/`);

    const VideoCard = () => (
        <>
            <StyledProductVideo ableToPlay={props.ableToPlay}>
                <Switch>
                    <Match when={props?.videoUrl?.includes('youtu') && !props?.image?.url}>
                        <iframe
                            src={'https://www.youtube.com/embed/' + getYoutubeVideoId(props.videoUrl)}
                            title="YouTube video player"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                        ></iframe>
                    </Match>
                    <Match when={props?.videoUrl?.includes('vimeo') && !props?.image?.url}>
                        <iframe
                            src={'https://player.vimeo.com/video/' + getVimeoVideoId(props.videoUrl)}
                            allow="autoplay; fullscreen; picture-in-picture"
                            allowfullscreen
                        ></iframe>
                    </Match>
                    <Match when={props?.image?.url}>
                        <StyledImage 
                            class="styledImage" 
                            src={backgroundImageSrcData()?.original}
                            title={props?.image?.name || ''}
                            alt={props?.image?.alt || ''}
                        />
                    </Match>
                </Switch>
            </StyledProductVideo>
            <StyledCardContent>
                <StyledCardTitle insideSlider={props.insideSlider}>{removeAmpersand(props.title)}</StyledCardTitle>
                <Show when={!props.hideMyContentStar}>
                    <MyContentHandler
                        action='add'
                        title={props.title}
                        permalink={props.permalink}
                        type={'video'}
                        onlyIcon={true}
                    />
                </Show>
            </StyledCardContent>
        </>
    );


    return (
        <ErrorCatcher componentName="Video post card">
            <StyledVideoCardWrapper>
                <StyledVideoPostCard inGrouping={props.inGrouping}>
                    <Show when={props.ableToPlay} fallback={
                        <Link href={permalinkWithEntityName ? removeTrailingSlash(permalinkWithEntityName) : ''}><VideoCard /></Link>
                    }>
                        <VideoCard />
                    </Show>

                    <Show when={isHiddenForPublic()}>
                        <LockedContent />
                    </Show>
                </StyledVideoPostCard>
                <MyContentHandler action='remove' permalink={props.permalink} />
            </StyledVideoCardWrapper>
        </ErrorCatcher>
    );
};
