import { Accessor, For } from 'solid-js';
import { FirstLevelMenuItemLink } from './first-level-menu-item-link/first-level-menu-item-link';
import { FirstLevelMenu } from './first-level-menu/first-level-menu';
import { StyledPrimaryMenuContainer, StyledPrimaryMenuTitle, StyledPrimaryMenuList } from './primary-menu.styles';
import menuStore from '../../../../stores/menu-store';
import type { MenuItem, PrimaryMenuDesktopProps, PrimaryMenuProps } from '../../types/primary-menu-types';

export const PrimaryMenu = (props: PrimaryMenuProps & PrimaryMenuDesktopProps) => {
    const { close } = menuStore;
    const firstWordOfTitle = () => props.menuTitle ? props.menuTitle.split(' ')[0] : '';
    const restOfTitle = () => props.menuTitle ? props.menuTitle.split(' ').slice(1).join(' ') : '';

    return (
        <StyledPrimaryMenuContainer>
            <StyledPrimaryMenuTitle>
                <strong>{firstWordOfTitle()}</strong>
                &nbsp
                {restOfTitle()}
            </StyledPrimaryMenuTitle>
            <nav>
                <StyledPrimaryMenuList>
                    <For each={props.menu}>
                        {(menuItem: MenuItem, index) => {
                            if (!menuItem.children) {
                                return (
                                    <li>
                                        <FirstLevelMenuItemLink
                                            link={menuItem}
                                            onLinkClick={close}
                                            lastElement={(el: HTMLElement) => {
                                                if (index() === props.menu.length - 1) {
                                                    props.lastElement(el);
                                                }
                                            }}
                                        />
                                    </li>
                                );
                            } else {
                                return (
                                    <li>
                                        <FirstLevelMenu
                                            parent={menuItem}
                                            onLinkClick={close}
                                            lastElement={(el: HTMLElement, elementIsAMenuAndIsOpen: Accessor<boolean>) => {
                                                if (index() === props.menu.length - 1) {
                                                    props.lastElement(el, elementIsAMenuAndIsOpen);
                                                }
                                            }}
                                            lastChildOfLastElement={(el: HTMLElement) => {
                                                if (index() === props.menu.length - 1) {
                                                    props.lastChildOfLastElement(el);
                                                }
                                            }}
                                        />
                                    </li>
                                );
                            }
                        }}
                    </For>
                </StyledPrimaryMenuList>
            </nav>
        </StyledPrimaryMenuContainer>
    );
};
