import { Link } from '@solidjs/router';
import { styled } from 'solid-styled-components';
import theme, { fontFamilyAtosBold } from '../../../../../style/theme';

export const StyledFirstLevelMenuItemLink = styled(Link)`
    border-bottom: 1px solid ${theme.palette.red};
    font-size: 1.8rem;
    color: ${theme.palette.darkGray};
    font-family: ${fontFamilyAtosBold};
    font-weight: 800;
    text-decoration: none;
    cursor: pointer;
    padding-top: 2rem;
    padding-bottom: 1rem;
    display: flex;
    gap: 0.5rem;

    &:hover {
        color: ${theme.palette.red};

        svg {
            path {
                fill: ${theme.palette.red};
            }
        }
    }
`;
