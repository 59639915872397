import { styled } from 'solid-styled-components';
import theme, { fontFamilyAtosBold } from '../../../style/theme';

const {spacing, palette, mediaQueries} = theme;

export const StyledExpandableContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${palette.lightBeige};
    padding: ${spacing.m} ${spacing.xl};
    border-radius: 1.43rem;
    cursor: pointer;


    ${mediaQueries.mobileMax} {
        border-radius: 1.1rem;
        padding: ${spacing.xs};
    }
`;

export const StyledExpandableContainerRow = styled.div<StyledExpandableContainerRowProps>`
    display: flex;
    justify-content: space-between;
`;
type StyledExpandableContainerRowProps = {
    open: boolean;
}

export const StyledExpandableContainerHeading = styled.div`
    font-family: ${fontFamilyAtosBold};
    font-weight: 800;
    font-size: 1.43rem;
    color: ${palette.darkGray};
    text-transform: uppercase;

    ${mediaQueries.mobileMax} {
        font-size: 1.29rem;
    }
`;

export const StyledChildren = styled.div`
    background-color: ${palette.lightBeige};
`;

export const StyledChildrenContent = styled.div``;

export const StyledDividerLine = styled.div`
    border-top: 2px solid ${palette.darkGray};
    background-color: ${palette.lightBeige};
    margin: ${spacing.xs} -${spacing.s} ${spacing.ms} -${spacing.s};

    ${mediaQueries.mobileMax} {
        border-width: 1px;
        margin: ${spacing.xxs} 0 ${spacing.xxs} 0;
    }
`;
