import { Link } from '@solidjs/router';
import { styled } from 'solid-styled-components';
import { fontFamilyAtosBold, palette, theme } from '../../../style/theme';
import { PathMatch } from '@solidjs/router/dist/types';

type StyledHeaderProps = {
    floatHeader: boolean;
    isOriginalTablet: boolean;
};

const { header, grid } = theme;
const { desktop } = header;

const desktopHeight = {
    staticHeight: desktop.height.fixedAtosCare.value + desktop.height.fixedAtosCare.unit,
    floatingHeight: desktop.height.floatingAtosCare.value + desktop.height.floatingAtosCare.unit,
};

const textStyleCommon = `
    transition: ease-in-out 0.2s all;
    font-size: 1.29rem;
    letter-spacing: 0;
    line-height: 1.93rem;
    font-family: ${fontFamilyAtosBold};
    font-weight: 800;
    align-self: center;
    border: none;
`;

export const StyledHeader = styled.header<StyledHeaderProps>`
    container-type: inline-size;
    height: ${(p) => (p.floatHeader || p.isOriginalTablet ? desktopHeight.floatingHeight : desktopHeight.staticHeight)}; /* 65px and 139px accordingly */
    position: sticky;
    z-index: 3;
    top: 0;


    @media print {
        display: none;
    }
`;

export const StyledLinkSpan = styled.span<{ active: PathMatch | boolean | null }>`
    ${textStyleCommon};
    text-transform: uppercase;
    color: ${(p) => (p.active ? theme.palette.pink : theme.palette.darkGray)};
    &:hover {
        color: ${theme.palette.pink};
    }
`;

export const FrostedBackground = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    margin-inline: ${grid.standardUnit * 2 * -1}rem;
    right: 0;
    height: inherit;
    z-index: -1;
    background: transparent;
    border-radius: 0 0 ${grid.standardUnit}rem ${grid.standardUnit}rem;

    ${theme.mediaQueries.desktopMax} {
        margin-inline: ${grid.gapSizeTablet * -1}rem;
    }

    ${theme.mediaQueries.tabletMax} {
        background: ${theme.palette.beige};
        margin-inline: ${grid.gapSizeMobile * -1}rem;
        border-radius: 0 0 ${grid.gapSizeMobile}rem ${grid.gapSizeMobile}rem;
    }

    backdrop-filter: blur(0.71rem);
    -webkit-backdrop-filter: blur(0.71rem);
    background: ${theme.header.opaqueBackgroundColor} !important;
`;

export const StyledLinkContent = styled.span<{ active: PathMatch | boolean | null }>`
    ${(p) =>
        p.active
            ? `
        color: ${theme.palette.red} !important;
    `
            : ''}
`;

const iconPaddingDesktop = '1.5rem';
const iconPaddingTablet = '1.25rem';
const iconPaddingMobile = '0.9rem';

export const StyledCareNavigationShortcuts = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    ${theme.mediaQueries.tabletMaxOriginal} {
        margin-right: -${iconPaddingDesktop};
    }

    ${theme.mediaQueries.tabletMax} {
        margin-right: -${iconPaddingTablet};
    }

    ${theme.mediaQueries.mobileMax} {
        margin-right: -${iconPaddingMobile};
    }
`;


export const StyledCircle = styled.circle<{ animate: boolean }>`
    fill: ${theme.palette.red};
    cx: 25;
    cy: 14;

    ${p => p.animate ? `
        r: unset;
        animation: bounce 0.25s ease-in-out forwards;
    ` : `
        r: 8;
        animation: none;
    `}

    @keyframes bounce {
        0%, 100% {
            r: 8;
        }
        50% {
            r: 12;
        }
    }
`;

export const StyledBasketBadge = styled.svg`
    position: absolute;
    top: -6px;
    left: -2px;
    width: 50px;
    height: 40px;
`;

export const StyledShoppingBasketContainer = styled.div`
    display: flex;
    align-items: center;
    position: relative;
`;

export const StyledIcon = styled.div<{ active: PathMatch | boolean | null; disablePadding?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-inline: ${iconPaddingDesktop};
    padding-block: 1rem;
    cursor: pointer;
    transition: ease-in-out 0.2s all;
    position: relative;

    ${theme.mediaQueries.tabletMax} {
        padding-inline: ${iconPaddingTablet};
    }

    ${theme.mediaQueries.mobileMax} {
        padding-inline: ${iconPaddingMobile};
    }

    ${(p) =>
        p.active
            ? `
        svg {
            fill: ${palette.pink};
        }
    `
            : ''}

    &:hover {
        svg {
            fill: ${palette.pink};
        }
        h3,
        span {
            color: ${palette.pink};
        }
    }

    span {
        ${textStyleCommon}
        color: ${(p) => (p.active ? theme.palette.pink : theme.palette.darkGray)};
        padding-left: 8px;
    }
`;

export const StyledButton = styled.button<{ active: PathMatch | boolean | null }>`
    ${textStyleCommon}
    color: ${(p: any) => (p.active ? theme.palette.pink : theme.palette.darkGray)};
    text-transform: uppercase;
    &:hover {
        color: ${theme.palette.pink};
    }
`;

export const StyledDropDownItem = styled(Link)<{ active: PathMatch | boolean | null }>`
    ${textStyleCommon}
    color: ${(p: any) => (p.active ? theme.palette.pink : theme.palette.darkGray)};
    &:hover {
        color: ${theme.palette.pink};
    }
`;

export const StyledDropDown = styled.ul`
    & > li {
        padding-bottom: 0 !important;
    }
    & > li > a:first-letter {
        text-transform: uppercase;
    }

    & > li:not(:last-child) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.07);
    }
`;
