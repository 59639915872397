import { EventData } from '../../event/event-types';

export const isPastEvent = (event: EventData) => {
    const currentDateTime = new Date();
    const eventDateTime = new Date(`
        ${event?.eventDate} ${event?.eventTime}
    `);

    // Events older than 30 minutes should not be shown
    eventDateTime.setMinutes(eventDateTime.getMinutes() + 30);

    if (event?.eventExpireDate) {
        const eventExpireDate = new Date(`
            ${event?.eventExpireDate}
        `);

        return eventExpireDate <= currentDateTime;
    }

    return eventDateTime <= currentDateTime;
};