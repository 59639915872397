import { ErrorCatcher } from '../../../tools/error-catcher';
import { Match, Owner, Show, Switch, createEffect, createSignal, getOwner, runWithOwner, useContext } from 'solid-js';
import { EntitySlug, ValidDataTypes, pdfStore, setPdfStore, supportedTypes } from '../pdf-store';
import { Portal, isServer } from 'solid-js/web';
import { PdfFactory } from '../pdf-factory/pdf-factory';
import { PrintPdfIcon } from '../../icons-library/print-pdf-icon';
import { Text } from '../../../ui-components/text/text';
import { AppContext } from '../../../app-context-provider/app-context-provider';
import theme, { breakpoints } from '../../../style/theme';
import { StyledShareContainer } from '../../share-and-save/share-and-save.styles';
import { LoadingSpinner } from '../../loading-spinner/loading-spinner';
import { incrementUserPdfPrintCount } from '../pdf-dashboard/pdf-newsletter-signup-prompt/newsletter-prompt-checker';
import { useLocation } from '@solidjs/router';


export const PdfToolbar = (props: { type: EntitySlug; data: ValidDataTypes }) => {
    const { localize, viewport, siteInfo, ...rest } = useContext(AppContext);
    const [triggeredViaButton, setTriggeredViaButton] = createSignal(false);
    const location = useLocation();

    const owner = getOwner();

    if (!isServer) {
        /**
         * This allows the user to get the print-rendered content via File->Print or Ctrl/CMD + P.
         * The extra logic ensures that the print will not trigger endlessly if the user
         * prints via the dedicated "Print PDF" button.
         */
        window.onbeforeprint = function() {
            if (triggeredViaButton()) return;
            preparePrint(false);
        };

        window.onafterprint = function() {
            setTriggeredViaButton(false);
        };
    }

    const preparePrint = (viaButton: boolean) => {
        setTriggeredViaButton(viaButton);

        if (pdfStore.status === 'printing') {
            return;
        }

        setPdfStore('renderComplete', false);
        setPdfStore('status', 'printing');
        setPdfStore('title', `${props.data.post_title} - ${siteInfo.siteType === 'atos-care' ? 'Atos Care' : 'Atos Medical'}`);
        setPdfStore('postsData', [{ type: props.type, data: props.data, key: ''}]);

        incrementUserPdfPrintCount();
    };

    createEffect(() => {
        if (pdfStore.status === 'printing' && pdfStore.renderComplete) {
            setPdfStore('status', '');
            window.print();
        }
    });

    const isMobile = () => viewport.width <= breakpoints.MOBILE;

    const excludedEndpointsForToolbarList = () => {
        const endpoints = [
            rest.eventsListingPage?.url,
            rest.blogsNewsListingPage?.url,
            rest.videosListingPage?.url,
            rest.pdfBuilderPage,
            rest.privacyPolicyPage,
            rest.newsletterSignupPage,
            rest.pageRequestPasswordResetUrl,
            rest.pageResetPasswordUrl,
            rest.pageVerifyAccountUrl,
            rest.shopPage?.url,
            rest.myContentPage?.url,
            rest.page404?.url,
            rest.pageRegistration?.url,
            rest.searchPage?.url,
            rest.productContactFormUrl,
        ].filter(endpoint => endpoint !== undefined && endpoint !== '');
    
        return endpoints as string[];
    };

    const supported = () => {
        if (excludedEndpointsForToolbarList().includes(location.pathname)) {
            return false;
        }
        
        return supportedTypes.includes(props.type) ;
    };

    return (
        <ErrorCatcher componentName='Pdf Toolbar'>
            <Show when={supported() && !isMobile()}>

                <StyledShareContainer onClick={() => preparePrint(true)} id='print-pdf-button'>
                    <Text color={'darkGray'} fontSize="small" noBlockSpacing={true} >
                        {localize('print-pdf', 'Print PDF')}
                    </Text>

                    <Switch>
                        <Match when={pdfStore.status !== 'printing'}>
                            <PrintPdfIcon stroke={theme.palette['gray']} />
                        </Match>
                        
                        <Match when={pdfStore.status === 'printing'}>
                            <LoadingSpinner size={'2.2rem'} />
                        </Match>
                    </Switch>
                </StyledShareContainer>

                <Portal mount={document.getElementById('pdf-factory-root')!}>
                    {runWithOwner(owner as Owner, () => (
                        <PdfFactory />
                    ))}
                </Portal>

            </Show>
        </ErrorCatcher>
    );
};