import { styled } from 'solid-styled-components';
import { SvgIconStyledProps } from './svg-icon.d';

const sizeMap = {
    small: 1.43,
    medium: 1.79,
    default: 2.14,
    large: 2.86,
    xlarge: 4.3,
};

export const StyledSvg = styled.svg<SvgIconStyledProps>`
    fill: ${({ fill }) => fill || 'inherit'};
    ${({ stroke }) => stroke ? `stroke: ${stroke}` : ''};
    cursor: ${p => (p.onClick || p.inButton ? 'pointer' : 'inherit')};

    width: ${ p => p.size
        ? sizeMap[ p.size ]
        : typeof p.width === 'number' ? `${p.width}rem` : p.width };

    height: ${p => p.size
        ? sizeMap[ p.size ]
        : typeof p.height === 'number' ? `${p.height}rem` : p.height };

    min-width: ${p => p.size ? sizeMap[p.size] : p.width}rem;
    min-height: ${p => p.size ? sizeMap[p.size] : p.height}rem;
    transition: fill 0.2s, stroke 0.2s;
    
    ${p => p.fullHeight ? 'height: 100%;' : ''};
    
    ${p => p.inButton ? `
        position: absolute;
        ${p.placement}: ${p.placementValue || 0};
        top: 0;
        bottom: 0;
    ` : '' };
    
    ${p => p.shape === 'square' ? `
        padding: ${p.padding || eval(p.padding) === 0 ? p.padding : '1.7rem'};
    ` : ''};
    
    ${p => p.shape === 'circle' ? `
        padding: ${p.padding || '1.2rem'};
        border-radius: 50%;
    ` : ''};

    ${p => p.opacity ? `opacity: ${p.opacity};` : ''};

    ${p => p.disabled ? `
        opacity: 0.5;
        cursor: default;
    ` : ''};

    ${p => p.customCss ? p.customCss : ''};

    /* styles below are for the provox.tsx icon */
    .st0{
        enable-background: new;
    }
	.st1{
        fill: #918787;
    }
	.st2{
        fill: #F0414B;
    }
`;