import { Show, useContext } from 'solid-js';
import { AppContext } from '../../../../../app-context-provider/app-context-provider';
import { Heading } from '../../../../../ui-components/heading/heading';
import { List } from '../../../../../ui-components/list/list';
import { ProductData, Feature, Specification, DocumentType } from '../../../../product/product-types';
import { TextWrapper } from '../../../pdf-dashboard/pdf-dashboard.style';
import { CardTopPdf, CardBottomPdf, StyledKeyFeaturesHeadingPDF, StyledKeyFeaturesListPDF, StyledKeyFeaturesPDF, StyledPdfProductSupportingMaterialBody, StyledGrayEmptySpacePDF } from './product-components.style';
import { ProductIntroPDF } from './intro/intro';
import { Text } from '../../../../../ui-components/text/text';
import { StyledDividerLinePDF, StyledExpandableContainerRowPDF, StyledPdfProductSpecificationsBody, StyledProductSpecificationsTablePDF, StyledPdfSpecificationBottom, StyledPdfSpecificationTop, StyledTableColumnPDF, StyledTableHeaderPDF } from './product-components.style';
import { ComponentPiece } from '../../pdf-renderer/pdf-renderer';
import { DocumentPDF } from '../document/document';

const helpers = {
    splitDescriptionIntoParagraphs: (data: ProductData) => {
        const description = data?.longDescription;
    
        if (!description) return [];
        if (!description.includes('\r\n\r')) {
            return [description];
        }
    
        const paragraphs = description
            .split('\r\n\r')
            .filter((paragraph: string) => paragraph !== '\n');
    
        return paragraphs;
    },
};

const intro: ComponentPiece = {
    elements: (data) => {
        return (
            <ProductIntroPDF data={data} />
        );
    }
};

const descriptionHeading: ComponentPiece = {
    elements: (data) => {

        return (
            <TextWrapper>
                <Heading tag='h2' variant='bigRedPDF'>{data?.subTitle || ''}</Heading>
            </TextWrapper>
        );
    }
};

const description: ComponentPiece = {
    canBeBrokenUp: true,
    topOpeningComponent: () => <CardTopPdf />,
    bottomClosingComponent: () => <CardBottomPdf />,
    elements: (data) => {
        const paragraphs = helpers.splitDescriptionIntoParagraphs(data);

        if (paragraphs.length <= 0) {
            return [];
        }

        const texts = paragraphs.map((paragraph: string) => (
            <TextWrapper>
                <Text fontSize='normalPDF' splitParagraph={false} color='black'>
                    {paragraph}
                </Text>
            </TextWrapper>
        ));

        return [
            descriptionHeading.elements(data),
            ...texts,
            keyFeatures.elements(data)
        ];
    },
};

const keyFeatures: ComponentPiece = {
    elements: (data) => {
        const { localize } = useContext(AppContext);
        
        return (
            <Show when={data?.features?.length}>
                <StyledKeyFeaturesPDF>
                    <StyledKeyFeaturesHeadingPDF>
                        <Heading tag='h5' variant='tinyGrayPDF'>
                            {localize('key-features', 'Key Features')}
                        </Heading>
                    </StyledKeyFeaturesHeadingPDF>
                    <StyledKeyFeaturesListPDF>
                        <List
                            displayRedVerticalLine={false}
                            fontSize='normalPDF'
                            items={data?.features.map((feature: Feature) => feature?.text)}
                            colorText={{
                                color: '#000',
                                name: 'black'
                            }}
                        />
                    </StyledKeyFeaturesListPDF>
                </StyledKeyFeaturesPDF>
            </Show>
        );
    },
};

const specifications: ComponentPiece = {
    canBeBrokenUp: true,
    topOpeningComponent: () => {
        const { localize } = useContext(AppContext);

        return (
            <StyledPdfSpecificationTop>
                <StyledExpandableContainerRowPDF>
                    <div style={{ 'text-transform': 'uppercase'}}>
                        <Heading tag='h5' variant='tinyGrayPDF'>
                            {localize('specifications', 'Specifications')}
                        </Heading>
                    </div>
                </StyledExpandableContainerRowPDF>
                
                <StyledProductSpecificationsTablePDF>

                    <StyledTableHeaderPDF align={'left'}>{
                        localize('name', 'Name')
                    }</StyledTableHeaderPDF>
                    <StyledTableHeaderPDF align={'right'}>{
                        localize('specifications', 'Specifications')
                    }</StyledTableHeaderPDF>
                    <StyledTableHeaderPDF align={'right'}>{
                        localize('ref-nr', 'Reference number')
                    }</StyledTableHeaderPDF>
                    <StyledTableHeaderPDF align={'right'}>{
                        localize('quantity', 'Quantity')
                    }</StyledTableHeaderPDF>

                </StyledProductSpecificationsTablePDF>
                <StyledDividerLinePDF />
            </StyledPdfSpecificationTop>
        );
    },
    bottomClosingComponent: () => <StyledPdfSpecificationBottom />,
    elements: (data) => {

        const isLast = (index: number) => index === data?.specifications?.length - 1;

        const specs = data?.specifications?.map((spec: Specification, index: number) => (
            <StyledPdfProductSpecificationsBody last={isLast(index)}>

                <StyledTableColumnPDF align={'left'}>
                    <Text fontSize='smallPDF'>
                        { spec?.name }
                    </Text>
                </StyledTableColumnPDF>
                <StyledTableColumnPDF align={'right'}>
                    <Text fontSize='smallPDF'>
                        { spec?.description }
                    </Text>
                </StyledTableColumnPDF>
                <StyledTableColumnPDF align={'right'}>
                    <Text fontSize='smallPDF'>
                        { spec?.refNumber }
                    </Text>
                </StyledTableColumnPDF>
                <StyledTableColumnPDF align={'right'}>
                    <Text fontSize='smallPDF'>
                        { spec?.quantity }
                    </Text>
                </StyledTableColumnPDF>

            </StyledPdfProductSpecificationsBody>
        )) ?? [];

        return [
            ...specs,
        ];
    },
};

const supportingMaterial: ComponentPiece = {
    canBeBrokenUp: true,
    topOpeningComponent: () => {
        const { localize } = useContext(AppContext);

        return (
            <StyledPdfSpecificationTop>
                <StyledExpandableContainerRowPDF>
                    <div style={{ 'text-transform': 'uppercase'}}>
                        <Heading tag='h5' variant='tinyGrayPDF'>
                            {localize('supporting-material', 'Supporting Material')}
                        </Heading>
                    </div>
                </StyledExpandableContainerRowPDF>
                <StyledGrayEmptySpacePDF />
            </StyledPdfSpecificationTop>
        );
    },
    bottomClosingComponent: () => <StyledPdfSpecificationBottom />,
    elements: (data) => {
        const isLast = (index: number) => index === data?.documents?.length - 1;

        const documents = data?.documents?.map((doc: DocumentType, index: number) => {
            return (
                <StyledPdfProductSupportingMaterialBody last={isLast(index)}>
                    <DocumentPDF doc={doc} />
                </StyledPdfProductSupportingMaterialBody>
            );
        });

        return [
            ...documents,
        ];
    },
};

export const components = [
    intro,
    description,
    specifications,
    supportingMaterial,
];