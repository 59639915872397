import { styled } from 'solid-styled-components';
import { fontFamilyAtosBold, fontFamilyAtosRegular, theme } from '../../style/theme';
 

export const StyledPopupHeading = styled.h3`
    font-family: ${fontFamilyAtosBold};
    font-weight: 800;
    color: ${theme.colors.text.h3};
    font-size: 28px;
`;

export const StyledShareIcons = styled.div`
`;

export const StyledShareIconsRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4rem;
    margin-bottom: 2rem;
`;

export const StyledShareIcon = styled.div`
    background: ${theme.colors.neutral.background};
    border-radius: 50px;
    padding: 0.5rem;
    height: 75px;
    width: 75px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

export const StyledPopupText = styled.p`
    font-family: ${fontFamilyAtosRegular};
    font-size: 20px;
    text-align: left;
`;

export const StyledShareUrl = styled.div`
    background: ${theme.colors.neutral.background};
    color: ${theme.colors.text.pink};
    padding: 2rem;
    border-radius: 10px;
    font-size: 20px;
    font-family: ${fontFamilyAtosBold};
    font-weight: 800;
    cursor: pointer;
    transition: ease all 0.3s;

    &:hover {
        background: ${theme.colors.neutral.lightThree};
        color: ${theme.colors.text.red};
    }

    ${theme.mediaQueries.mobileMax} {
        font-size: 1.4rem;
    }
`;

export const StyledCopyButtonRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 1.5rem;
`;