import { EventData } from '../../event/event-types';
import { PostCard } from '../../post-card/post-card';
import { isPastEvent } from './is-past-event';

export const createGroupedEventCard = (props: { event: EventData; largeCard?: boolean }) => {
    return createEventCard({
        event: props.event,
        inGrouping: true,
        largeCard: props.largeCard,
    });
};



export const createEventCard = (props: { event: EventData; inGrouping?: boolean; largeCard?: boolean }) => {
    const content = {
        listCardContent: {
            cardText: props.event?.listCardContent?.cardText,
            title: props.event?.listCardContent?.title || props.event?.post_title,
            image: props.event?.listCardContent?.image,
        },
        type: 'event',
        permalink: props.event?.permalink,
        url: props.event?.url,
        eventDate: props.event?.eventDate,
        eventExpireDate: props.event?.eventExpireDate,
        isHealthcareProfessional: props.event?.isHealthcareProfessional,
        isVisibleForPublic: props.event?.isVisibleForPublic,
        eventLocation: props.event?.eventLocation,
        stale: isPastEvent(props.event),
        isFullyBooked: props.event?.isFullyBooked,
    };
    
    return <PostCard orientation={'horizontal'} data={content} hideTypeFlag={false} inGrouping={props.inGrouping} largeCard={props.largeCard} />;
};
