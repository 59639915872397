import { For, Match, Show, Switch, createEffect, createSignal, on, onMount, useContext } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { Grid } from '../../grid-system/grid/grid';
import { TopCategory, eventsStore, setAvailableCategories, setAvailableLanguages, setAvailableTags } from '../../stores/events-store';
import theme from '../../style/theme';
import { ErrorCatcher } from '../../tools/error-catcher';
import { Heading } from '../../ui-components/heading/heading';
import { Text } from '../../ui-components/text/text';
import { StyledFlexRow } from '../../ui-components/utility-style-components/flex';
import { ViewStyleButton } from '../../ui-components/view-style-button/view-style-button';
import { EventData, Tag } from '../event/event-types';
import { EventsContainerProps } from './events-types';
import { StyledEventsContainer, StyledEventsList } from './events.styles';
import { extractTopCategories } from './helpers/categories-helper';
import { createGroupedEventCard } from './helpers/events-card-helper';
import { getUniqueLanguages, languageCodes } from './helpers/languages-helper';
import { getUniqueTags } from './helpers/tags-helper';
import { ListViewEventCard } from './list-view-event-card';
import { isPastEvent } from './helpers/is-past-event';

export const EventsContainer = (props: EventsContainerProps) => {
    const { viewport, localize } = useContext(AppContext);

    const [viewStyle, setViewStyle] = createSignal<string>('gallery');

    // When opening page check if there are upcoming events. If yes show them, if no show all events.
    // But only if user didn't land on the page with search params, as this means the
    // selected filters are intentional and should be respected.
    onMount(() => {
        if (eventsStore.landedWithSearchParams) return;

        const upcomingEvents = filterEvents();

        if (upcomingEvents.length > 0 && eventsStore.status.slug !== 'upcoming') {
            handleOnClickStatus('upcoming');
        }

        if (upcomingEvents.length === 0 && eventsStore.status.slug === 'upcoming') {
            handleOnClickStatus('all');
        }
    });

    const isMobile = () => viewport.width <= theme.breakpoints.MOBILE;

    const breadcrumbs = () => `${localize('events', 'Events')} ${eventsStore.status?.name ? `/ ${eventsStore.status?.name}` : ''}`;

    const { handleOnClickStatus } = props.clickHelper;

    const filterEvents = () => {
        const status = eventsStore.status?.slug || 'all';
        const areaOfCare = eventsStore.areaOfCare?.slug || 'all';

        if (status === 'all') {
            const past = filtration('past', areaOfCare);
            const upcoming = filtration('upcoming', areaOfCare);

            return [...upcoming, ...past];
        }

        return filtration(status, areaOfCare);
    };

    const filtration = (status: string, areaOfCare: string) => {
        const filtered = props?.events
            ?.filter((event: EventData) => {
                if (!props.isInternationalSite) return true;

                if (event?.hideFromGlobalEventsListing) return false;
                return true;
            })

            ?.filter((event: EventData) => {
                const isPast = isPastEvent(event);
                const isUpcoming = !isPast;

                if (status === 'upcoming') return isUpcoming;

                if (status === 'past') return isPast;
            })
            ?.filter((event: EventData) => {
                if (areaOfCare === 'all') {
                    return true;
                }

                return areaOfCare === event.areaOfCare;
            })
            ?.filter((event: EventData) => {
                if (eventsStore?.selectedCategories?.length === 0) return true;

                const allCategorySlugs = eventsStore?.selectedCategories?.map((category: TopCategory) => category.slug);
                return !!event?.tags?.find((tag: string) => allCategorySlugs.includes(tag));
            })
            ?.filter((event: EventData) => {
                if (eventsStore?.selectedTags?.length === 0) return true;

                const allTagSlugs = eventsStore?.selectedTags?.map((tag: Tag) => tag.slug);
                return event?.wordpressTags?.find((tag: Tag) => allTagSlugs.includes(tag.slug));
            })
            ?.filter((event: EventData) => {
                if (eventsStore?.selectedLanguages?.length === 0) return true;

                return eventsStore?.selectedLanguages?.includes(languageCodes[event?.eventLanguage]);
            });

        if (status === 'past') filtered?.reverse();
            
        return filtered;
    };

    const filteredEvents = () => filterEvents();

    createEffect(() => {
        const extractedUniqueLanguages = getUniqueLanguages(props.events);
        setAvailableLanguages(extractedUniqueLanguages);
    });

    const extractAndSetCategoriesAndTags = () => {
        const extractedTopCategories = extractTopCategories(filteredEvents());
        setAvailableCategories(extractedTopCategories);

        const extractedEventTags = getUniqueTags(filteredEvents());
        setAvailableTags(extractedEventTags);
    };
    extractAndSetCategoriesAndTags();

    createEffect(
        on(
            () => filteredEvents(),
            () => extractAndSetCategoriesAndTags()
        )
    );

    // for list view use
    const groupedEvents = () => {
        return filteredEvents().reduce((result: Record<string, EventData[]>, event) => {
            const date = new Date(event?.eventDate);
            const month = date.toLocaleString('en-US', { month: 'long' });
            const year = date.getFullYear();

            let key;
            if (month === 'Invalid Date' && Number.isNaN(year)) {
                key = 'Unsorted';
            } else {
                key = month;

                // if year is not current year, add year to key
                if (year !== new Date().getFullYear()) {
                    key = `${month} '${year.toString().substring(2, 4)}`;
                }
            }

            if (!result[key]) {
                result[key] = [];
            }

            result[key].push(event);

            return result;
        }, {});
    };

    return (
        <ErrorCatcher componentName="Events container">
            <StyledEventsContainer>
                <Show when={!isMobile()}>
                    <Heading tag="h2" variant="breadcrumb">
                        {breadcrumbs()}
                    </Heading>
                </Show>

                <StyledFlexRow>
                    <ViewStyleButton
                        text={props.labels?.listView || 'List view'}
                        type="list"
                        onClick={() => setViewStyle('list')}
                        isActive={viewStyle() === 'list'}
                    />
                    <ViewStyleButton
                        text={props.labels?.galleryView || 'Gallery view'}
                        type="gallery"
                        onClick={() => setViewStyle('gallery')}
                        isActive={viewStyle() === 'gallery'}
                    />
                </StyledFlexRow>

                <StyledEventsList>
                    <Switch>
                        <Match when={viewStyle() === 'gallery'}>
                            <Grid templateShorthand={[4, 4, 4]} responsive={{ mobile: [12], tablet: [12], smallDesktop: [6, 6] }} inheritParentGrid={true}>
                                <For
                                    each={filteredEvents()}
                                    fallback={
                                        <Text fontSize="small" displayRedVerticalLine={false}>
                                            {localize('no-events-found', 'No events found...')}
                                        </Text>
                                    }
                                >
                                    {(event: EventData) => {
                                        return createGroupedEventCard({ event });
                                    }}
                                </For>
                            </Grid>
                        </Match>
                        <Match when={viewStyle() === 'list'}>
                            <For
                                each={Object.keys(groupedEvents())}
                                fallback={
                                    <Text fontSize="small" displayRedVerticalLine={false}>
                                        {localize('no-events-found', 'No events found...')}
                                    </Text>
                                }
                            >
                                {(eventMonth) => {
                                    return (
                                        <>
                                            <Heading tag="h3" noBlockSpacing={true}>
                                                {eventMonth.split('-')[0]}
                                            </Heading>
                                            <For each={groupedEvents()[eventMonth]}>
                                                {(event) => {
                                                    return <ListViewEventCard event={event} />;
                                                }}
                                            </For>
                                        </>
                                    );
                                }}
                            </For>
                        </Match>
                    </Switch>
                </StyledEventsList>
            </StyledEventsContainer>
        </ErrorCatcher>
    );
};
