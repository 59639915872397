import { styled } from 'solid-styled-components';
import { Link } from '@solidjs/router';
import theme, { fontFamilyAtosBold, fontFamilyAtosRegular } from '../../../../../style/theme';

export const StyledShortcutsMenu = styled.div`
    max-width: 25.71rem;
    margin-bottom: 4rem;
`;

export const StyledShortcutsMenuLink = styled(Link)`
    color: white;
    font-family: ${fontFamilyAtosBold};
    font-weight: 800;
    font-size: 1.43rem;
`;

export const StyledShortcutsMenuLinkItems = styled.ul`
    padding: 2rem 0 0 6rem;
`;

export const StyledShortcutsMenuLinkItem = styled.li`
    cursor: pointer;
    list-style: none;
    position: relative;
    padding-bottom: 1.5rem;
`;

export const StyledShortcutsMenuHeading = styled.span`
    font-size: 2rem;
    font-family: ${fontFamilyAtosRegular};
    color: ${theme.palette.white};
`;

export const StyledShortcutsMenuButton = styled.button`
    width: 100%;
    border-bottom: 1px solid ${theme.palette.white};
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 2;
`;

export const StyledAngleUpIconContainer = styled.div`
    position: relative;
    top: 1.14rem;
    right: 1.75rem;
`;

export const StyledAngleDownIconContainer = styled.div`
    width: auto;
    display: flex;
    justify-content: end;
    padding: 0 0.5rem;
`;
