import { SvgIcon } from './svg-icon';
import { SvgIconProps } from './svg-icon.d';
import { mergeProps } from 'solid-js';

const defaultProps: SvgIconProps = {
    // viewBox: '0 0 29.992 30',
    // width: 29.992
};

export const AngleDownIcon = (componentProps: SvgIconProps) => {
    const props = mergeProps(defaultProps, componentProps);

    return (
        <SvgIcon {...props}>
            <path
                d="M45.364,179.462l-12.955,12.2a1.843,1.843,0,0,1-1.406.631,2.046,2.046,0,0,1-1.406-.559L16.64,179.462a2.047,2.047,0,1,1,2.813-2.975L31,187.422l11.549-10.944a2.044,2.044,0,0,1,2.893.08A2.066,2.066,0,0,1,45.364,179.462Z"
                transform="translate(-16.001 -175.918)"
            />
        </SvgIcon>
    );
};
