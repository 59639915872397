import { Show, useContext } from 'solid-js';
import { Text } from '../../ui-components/text/text';
import { AppContext } from '../../app-context-provider/app-context-provider';
import theme from '../../style/theme';
import { OrderDetails } from './user-order-history';
import { StyledHistoryOrderLine, StyledHistoryOrderLineWrapper, StyledImageHolder, StyledTextContent } from './user-order-history.styles';
import { ImageComponent } from '../image-component/image-component';
import { Link } from '../../ui-components/link/link';
import { StyledVerticalSpace } from '../../ui-components/utility-style-components/spacing';
import menuStore from '../../stores/menu-store';
import { useNavigate } from '@solidjs/router';



const OrderHistoryOrderline = ({ orderDetails }: { orderDetails: OrderDetails }) => {
    const { viewport, localize, productsEntityName } = useContext(AppContext);
    const { closeLoginMenu } = menuStore;

    const navigate = useNavigate();

    const isDesktop = () => viewport.width > theme.breakpoints.ORIGINAL_TABLET;

    return (
        <StyledHistoryOrderLineWrapper>
            <StyledHistoryOrderLine>
                
                <StyledImageHolder>
                    <Show when={orderDetails.imageUrl}>
                        <ImageComponent
                            src={orderDetails.imageUrl!}
                            height={'AUTO'}
                            width={300}
                            altText={'Product image for ' + orderDetails.skuName}
                            title={'Product image for' + orderDetails.skuName}
                            aspectRatio={'original'}
                            ratio={orderDetails.imageRatio}
                        />
                    </Show>
                </StyledImageHolder>

                <StyledTextContent>
                    <Link 
                        url=''
                        asButton
                        label={`${orderDetails.amount} x ${orderDetails.skuName}`}
                        noBlockSpacing 
                        onClick={() => {
                            navigate(`/${productsEntityName}/${orderDetails.slug}`);
                            closeLoginMenu();
                        }}
                    />
                    <StyledVerticalSpace size={0.5} />
                    <Text fontSize='small' noBlockSpacing color='gray' lineLimit={isDesktop() ? 2 : 3}>{
                        orderDetails.skuDescription
                    }</Text>
                    <Text fontSize='small' color='gray' noBlockSpacing>{
                        `${orderDetails.skuQuantity ? `${localize('units-per-pack', 'Units per pack')}: ${orderDetails.skuQuantity}` : ''}`
                    }</Text>
                </StyledTextContent>

            </StyledHistoryOrderLine>
        </StyledHistoryOrderLineWrapper>
    );
};

export default OrderHistoryOrderline;
