import { gql } from 'graphql-request';
import { onMount, createSignal, useContext } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { Button } from '../../ui-components/button/button';
import { Modal } from '../../ui-components/modal/modal';
import { Text } from '../../ui-components/text/text';
import { StyledVerticalSpace } from '../../ui-components/utility-style-components/spacing';
import { createStore } from 'solid-js/store';

export const GET_ANNOUNCEMENT_QUERY = gql`
    query {
        settings: resource(externalId: "announcement-settings") {
            content
        }
    }
`;

export const Announcement = () => {
    const { localize, announcement, registerUserHasSeenAnnouncement, createCachedResource } = useContext(AppContext);
    const [ showModal, setShowModel ] = createSignal(false);
    const [ message, setMessage ] = createStore({
        title: '',
        body: '',
        buttonText: '',
    });

    const [ announcementSettings ] = createCachedResource(GET_ANNOUNCEMENT_QUERY, {}, true);

    onMount(() => {
        const settings = announcementSettings()?.settings?.content;

        if (!settings) {
            return;
        }
        const { enabled, title, body, buttonText } = settings;
        const noMessage = !title && !body;

        if (!enabled || noMessage) {
            return;
        }

        if (announcement.seen) {
            return;
        }

        setMessage({ title, body, buttonText });
        setTimeout(() => {
            setShowModel(true);
        }, 4000);
    });

    const closeAnnouncement = () => {
        saveCookie();
        registerUserHasSeenAnnouncement();
        setShowModel(false);
    };

    const saveCookie = async () => {
        await fetch('/set-announcement-seen', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ seenAnnouncement: true }),
        });
    };

    return (
        <Modal visible={showModal()} onClose={() => closeAnnouncement()} size='small' darkenBackground={true}>
            <StyledVerticalSpace size={5} />
            <Text fontSize='large' fontStyle='bold'>
                {message.title}
            </Text>

            <Text fontSize='normal'>
                {message.body}
            </Text>
            <Button
                label={message.buttonText || localize('close', 'Close')} onClick={closeAnnouncement} />
        </Modal>
    );
};
