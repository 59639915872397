import { createContext, createSignal } from 'solid-js';

type EventContextType = {
    gotoEventId?: string;
}

export const EventContext = createContext<EventContextType>({
    gotoEventId: undefined,
});

type EventContextProviderProps = {
    children: any;
    gotoEventId?: string;
}

export const EventContextProvider = (props: EventContextProviderProps) => {    
    const [ gotoEventId ] = createSignal(props.gotoEventId);

    return (
        <EventContext.Provider value={{ 
            gotoEventId: gotoEventId()
        }}>
            {props.children}
        </EventContext.Provider>
    );
};
