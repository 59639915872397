/* eslint-disable indent */
import { Link } from '@solidjs/router';
import { gql } from 'graphql-request';
import { For, Match, Show, Switch, onCleanup, onMount, useContext } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { Grid } from '../../grid-system/grid/grid';
import headerStore from '../../stores/header-store';
import menuStore from '../../stores/menu-store';
import theme from '../../style/theme';
import { ErrorCatcher } from '../../tools/error-catcher';
import urlMatches from '../../tools/url-match';
import { Heading } from '../../ui-components/heading/heading';
import { AtosLogo } from '../icons-library/atos-logo-desktop';
import { MenuBarsIcon } from '../icons-library/menu-bars';
import { HeaderNavigationShortcuts } from './header-navigation-shortcuts';
import {
    FrostedBackground,
    InnerHeader,
    StyledHeader,
    StyledLink,
    StyledLogo,
    StyledMenuButton,
    StyledMenuText,
    StyledMiddleColumn,
    StyledRightColumn,
} from './header.style';
import { removeTrailingSlash } from '../../tools/remove-trailing-slash';
import removeAmpersand from '../../tools/remove-ampersand';

const GET_HEADER_QUERY = gql`
    query GetHeader($key: String!) {
        header: resource(key: $key) {
            content
            key
        }
    }
`;

type HeaderLink = {
    title: string;
    url: string;
};

export const HeaderContainer = () => {
    const { createCachedResource, viewport, localize } = useContext(AppContext);
    const [headerContent] = createCachedResource(GET_HEADER_QUERY, () => ({ key: '/wp-json/rawb/v1/header' }), true);
    const { open } = menuStore;
    const { floatHeader, setFloatHeader } = headerStore;
    let headerRef: any;
    onMount(() => {
        const pageAtTopObserver = new IntersectionObserver(
            (payload) => {
                const pageAtTop = payload[0].isIntersecting;
                setFloatHeader(!pageAtTop);
            },
            {
                threshold: 1,
                // Else the floating header will be triggered too soon if we don't have a hero at the top.
                rootMargin: '-65px',
            }
        );

        if (headerRef) {
            pageAtTopObserver.observe(headerRef);
        }

        // TODO: shall be unobserve I think?
        onCleanup(() => pageAtTopObserver.observe(headerRef));
    });

    return (
        <ErrorCatcher componentName="Header">
            <StyledHeader floatHeader={floatHeader()}>
                <Show when={headerContent()?.header?.content?.headerLinks}>

                    <Grid templateShorthand={[12]} customCss={'height: inherit;'} isLayoutGrid={true}>
                        <InnerHeader>
                            <FrostedBackground floatHeader={floatHeader()} />
                            <div>
                                <Link href="/">
                                    <Switch>
                                        <Match when={viewport.width >= theme.breakpoints.TABLET}>
                                            <StyledLogo>
                                                <Show when={floatHeader()} fallback={<AtosLogo width={11} altText="Atos Medical Logo" aria-label="Atos Medical Logo" />}>
                                                    <AtosLogo width={7.5} aria-label={localize('atos_medical_logotype', 'Atos Medical Logo')} />
                                                </Show>
                                            </StyledLogo>
                                        </Match>
                                        <Match when={viewport.width <= theme.breakpoints.TABLET}>
                                            <StyledLogo>
                                                <AtosLogo width={9.36} aria-label={localize('atos_medical_logotype', 'Atos Medical Logo')} />
                                            </StyledLogo>
                                        </Match>
                                    </Switch>
                                </Link>
                            </div>
                            <StyledMiddleColumn>
                                <For each={headerContent().header.content.headerLinks}>
                                    {(link: HeaderLink) => {
                                        if (!link || !link.title || !link.url) {
                                            return null;
                                        }

                                        return (
                                            <StyledLink active={urlMatches(link?.url)}>
                                                <Link href={removeTrailingSlash(link.url)}>
                                                    {link?.title ? removeAmpersand(link.title) : null}
                                                </Link>
                                            </StyledLink>
                                        );
                                    }}
                                </For>
                            </StyledMiddleColumn>
                            <StyledRightColumn>
                                <HeaderNavigationShortcuts />

                                <StyledMenuButton onClick={open} tabIndex="0">
                                    <MenuBarsIcon
                                        altText="Open menu"
                                        width={3.67}
                                        {...(viewport.width <= theme.breakpoints.MOBILE
                                            ? {
                                                height: 2.27,
                                            }
                                            : {})}
                                    />
                                    <StyledMenuText>
                                        <Heading tag="span" variant="medium" noBlockSpacing={true}>
                                            {localize('menu', 'Menu')}
                                        </Heading>
                                    </StyledMenuText>
                                </StyledMenuButton>
                            </StyledRightColumn>
                        </InnerHeader>
                    </Grid>
                </Show>
            </StyledHeader>
            <div ref={headerRef} />
        </ErrorCatcher>
    );
};
