import { styled } from 'solid-styled-components';
import { Link } from '@solidjs/router';
import theme, { fontFamilyAtosBold } from '../../../../../style/theme';

export const StyledFirstLevelMenu = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StyledFirstLevelMenuItem = styled.button<StyledFirstLevelMenuItemProps>`
    text-align: start;
    border-bottom: 1px solid ${theme.palette.red};
    font-size: 1.8rem;
    color: ${(p) => p.color};
    font-family: ${fontFamilyAtosBold};
    font-weight: 800;
    text-decoration: none;
    cursor: pointer;
    padding-top: 2rem;
    padding-bottom: 1rem;
    display: flex;
    gap: 0.5rem;

    &:hover {
        color: ${theme.palette.red};

        svg {
            path {
                fill: ${theme.palette.red};
            }
        }
    }
`;
type StyledFirstLevelMenuItemProps = {
    color: string;
};

export const StyledSecondaryMenuItem = styled(Link)`
    display: block;
    color: ${theme.palette.gray};
    font-family: ${fontFamilyAtosBold};
    font-weight: 800;
    font-size: 1.29rem;
    text-decoration: none;
    border-bottom: 1px solid ${theme.palette.gray};
    padding: 1.5rem 3rem;

    &:hover {
        color: ${theme.palette.pink};
    }
`;

export const StyledSecondaryMenuItemParentLink = styled(StyledSecondaryMenuItem)`
    color: ${theme.palette.darkGray};
    border-color: ${theme.palette.darkGray};

    &:hover {
        color: ${theme.palette.red};
    }
`;

export const StyledUnorderedList = styled.ul`
    width: 100%;
    display: flex;
    flex-direction: column;
`;
