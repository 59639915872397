import { Link } from '@solidjs/router';
import { styled } from 'solid-styled-components';
import theme, { fontFamilyAtosBold, fontFamilyAtosRegular } from '../../style/theme';

export const StyledTopRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
`;

export const StyledDocumentsList = styled.ul`
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

export const StyledDocument = styled(Link)`
    color: ${theme.palette.red};
    text-decoration: underline;
    font-family: ${fontFamilyAtosBold};
    font-weight: 800;
    font-size: 1.43rem;
`;

export const StyledDocumentRow = styled.div`
    display: flex;
    flex-direction: row;
`;



export const StyledSearchInput = styled.input`
    border: 1px solid ${theme.palette.gray};
    padding: 1rem;
    border-radius: 1.1rem;
    width: 26.86rem;
    height: 5.29;
    font-size: 1.43rem;
    font-family: ${fontFamilyAtosRegular};
    font-weight: 400;
    color: ${theme.palette.darkGray};

    ${theme.mediaQueries.mobileMax} {
        width: 100%;
        margin-inline: 1.5rem;
    }
`;

export const StyledHeadingWrapper = styled.div`
    padding-inline: 1.5rem;
`;
