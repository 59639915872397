import { createSignal, Show, useContext } from 'solid-js';
import { AppContext } from '../../../app-context-provider/app-context-provider';
import theme from '../../../style/theme';
import { StyledRelativeContainer } from '../../../ui-components/utility-style-components/positioning';
import { AngleDownIcon } from '../../icons-library/angle-down';
import { AngleUpIcon } from '../../icons-library/angle-up';
import type { ExpandableContainerProps } from './expandable-container-types';
import {
    StyledChildren,
    StyledChildrenContent,
    StyledDividerLine,
    StyledExpandableContainer,
    StyledExpandableContainerHeading,
    StyledExpandableContainerRow,
} from './expandable-container.styles';

export const ExpandableContainer = (props: ExpandableContainerProps) => {
    const AppState = useContext(AppContext);
    const [open, setOpen] = createSignal(props.open || false);
    const iconSize = () => (AppState.viewport.width <= theme.breakpoints.MOBILE ? 1.5 : 2.14);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setTimeout(() => {
            setOpen(false);
        });
    };

    return (
        <StyledExpandableContainer onClick={() => !open() && handleOpen()} id={props.id}>
            <StyledExpandableContainerRow open={open()} onClick={() => open() && handleClose()}>
                <StyledExpandableContainerHeading>{props.heading}</StyledExpandableContainerHeading>
                <Show
                    when={open()}
                    fallback={
                        <StyledRelativeContainer top={8}>
                            <AngleDownIcon fill={theme.palette.red} width={iconSize()} height={iconSize()} />
                        </StyledRelativeContainer>
                    }
                >
                    <StyledRelativeContainer top={8}>
                        <AngleUpIcon fill={theme.palette.red} width={iconSize()} height={iconSize()} />
                    </StyledRelativeContainer>
                </Show>
            </StyledExpandableContainerRow>
            <Show when={open()}>
                <StyledDividerLine />
                <StyledExpandableContainerHeading>
                    <StyledChildren>
                        <StyledChildrenContent id={`${Math.random()}-childrenContent`}>{props.children}</StyledChildrenContent>
                    </StyledChildren>
                </StyledExpandableContainerHeading>
            </Show>
        </StyledExpandableContainer>
    );
};
