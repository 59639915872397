import { For, Show, createSignal, onMount, useContext } from 'solid-js';
import { Portal } from 'solid-js/web';
import { AppContext } from '../../../app-context-provider/app-context-provider';
import { TopCategory, eventsStore } from '../../../stores/events-store';
import { ErrorCatcher } from '../../../tools/error-catcher';
import { FilterList } from '../../../ui-components/filter-list/filter-list';
import { Heading } from '../../../ui-components/heading/heading';
import { Radio } from '../../../ui-components/inputs/radio/radio';
import { Tag } from '../../event/event-types';
import { FilterIcon } from '../../icons-library/filter';
import { MobileCategoryTag } from '../../products/mobile/mobile-category-tag';
import {
    StyledCategoryTagsContainer,
    StyledFilterIconAndTextWrapper,
    StyledFilterIconWrapper,
    StyledMobileFilterButton,
    StyledMobileFilterButtonContainer,
} from '../../products/mobile/mobile-sidebar.style';
import { EventsSidebarMobileProps } from '../events-types';
import { MobileFilterModal } from './mobile-filters-modal';

export const EventsSidebarMobile = (props: EventsSidebarMobileProps) => {
    const { localize } = useContext(AppContext);

    const [mobileFilterViewOpen, setMobileFilterViewOpen] = createSignal<boolean>(false);
    const [body, setBody] = createSignal<HTMLBodyElement>();

    const { 
        handleOnClickStatus,
        handleOnClickCategory,
        handleOnClickTag,
        handleOnClickLanguage,
    } = props.clickHelper;

    onMount(() => {
        const bodyElement = document.getElementsByTagName('body')[0];
        setBody(bodyElement);
    });

    let filterListRef: HTMLDivElement | undefined;

    const handleOnClickOpenModal = () => {
        setMobileFilterViewOpen(true);
        if (body() !== undefined) {
            body()!.style.overflow = 'hidden';
        }
    };

    const handleOnClickCloseModal = () => {
        setMobileFilterViewOpen(false);
        if (body() !== undefined) {
            body()!.style.overflow = 'auto';
        }
    };

    return (
        <ErrorCatcher componentName="Events sidebar mobile">
            <FilterList
                isMobile={true}
                isDropdown={true}
                initialOpen={false}
                listHeading={eventsStore.status.slug === 'all' ? localize('status', 'Status') : eventsStore.status.name}
                ref={filterListRef}
            >
                <For each={eventsStore.availableStatuses}>
                    {(status: any) => (
                        <li>
                            <Radio
                                value={status.slug}
                                whenClicked={() => {
                                    handleOnClickStatus(status.slug);
                                    filterListRef?.click();
                                }}
                                name="status"
                                readableName={status.name}
                                isChecked={eventsStore.status?.slug === status.slug}
                            />
                        </li>
                    )}
                </For>
            </FilterList>

            <Show when={eventsStore.availableLanguages.length > 0}>
                <StyledMobileFilterButtonContainer onClick={handleOnClickOpenModal}>
                    <StyledMobileFilterButton>
                        <StyledFilterIconAndTextWrapper>
                            <StyledFilterIconWrapper>
                                <FilterIcon />
                            </StyledFilterIconWrapper>
                            <Heading tag="h5" variant="medium" noBlockSpacing={true}>
                                {localize('filters', 'Filters')}
                            </Heading>
                        </StyledFilterIconAndTextWrapper>
                    </StyledMobileFilterButton>
                </StyledMobileFilterButtonContainer>
            </Show>

            <StyledCategoryTagsContainer>
                <MobileCategoryTag
                    category={eventsStore.status.slug === 'all' ? undefined : eventsStore.status}
                    onClick={() => handleOnClickStatus(eventsStore.availableStatuses[0].slug)} /* all */
                />
                <For each={eventsStore.selectedLanguages}>
                    {(language: string) => <MobileCategoryTag category={{ name: language }} onClick={() => handleOnClickLanguage(language)} />}
                </For>
                <For each={eventsStore.selectedCategories}>
                    {(category: TopCategory) => <MobileCategoryTag category={category} onClick={() => handleOnClickCategory(category)} />}
                </For>
                <For each={eventsStore.selectedTags}>
                    {(tag: Tag) => <MobileCategoryTag category={tag} onClick={() => handleOnClickTag(tag)} />}
                </For>
            </StyledCategoryTagsContainer>

            <Show when={mobileFilterViewOpen()}>
                {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
                <Portal mount={document.getElementById('root')!}>
                    <MobileFilterModal
                        closeModal={() => handleOnClickCloseModal()}
                        labels={props.labels}
                        eventsCategoriesHeading={localize('categories', 'Categories')}
                        eventsTagsHeading={localize('tags', 'Tags')}
                        eventsLanguagesHeading={localize('languages', 'Languages')}
                        handleOnClickCategory={handleOnClickCategory}
                        handleOnClickTag={handleOnClickTag}
                        handleOnClickLanguage={handleOnClickLanguage}
                    />
                </Portal>
            </Show>
        </ErrorCatcher>
    );
};
