import theme, { fontFamilyAtosBold } from '../../style/theme';
import { styled } from 'solid-styled-components';
import { LockIcon } from '../../components/icons-library/lock';
import menuStore from '../../stores/menu-store';
import { Show, useContext } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';

export const StyledLockedContentOverlay = styled.div`
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    background: ${theme.palette.white};
    border-radius: inherit;
    opacity: 0.9;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    pointer-events: auto;
    cursor: pointer;
`;

export const StyledLockedContentOverlayColumn = styled.div`
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    background: ${theme.palette.white};
    border-radius: inherit;
    opacity: 0.9;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    pointer-events: auto;
    cursor: pointer;
    justify-content: center;
`;

export const StyledLockedContentOverlayInner = styled.div<{ hasHero?: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10rem;
    margin-top: ${({ hasHero }) => (hasHero ? '24rem' : '12rem')};
`;

export const StyledLockedContentOverlayInnerColumn = styled.div<{}>`
    display: flex;
    gap: 1.5rem;
`;

export const StyledUnlockGatedContent = styled.div`
    text-decoration: underline;
    font-size: 1.7rem;
    letter-spacing: 0;
    line-height: 1.93rem;
    font-family: ${fontFamilyAtosBold};
    font-weight: 800;
    margin: 1.43rem 0;
    color: ${theme.palette.red};
    transition: 0.2s color;
    max-width: 270px;
    text-align: center;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const LockedContent = (props: { hasHero?: boolean, isColumn?: boolean, text?: string; }) => {
    const { localize } = useContext(AppContext);
    const { openLoginMenu } = menuStore;

    if (props.isColumn) {
        return (
            <StyledLockedContentOverlayColumn onClick={openLoginMenu}>
                <StyledLockedContentOverlayInnerColumn>
                    <LockIcon fill="red" size="xlarge" />
                    <StyledUnlockGatedContent>
                        <Show
                            fallback={localize('unlock-gated-content', 'Unlock gated content')}
                            when={props.text}
                        >
                            {props.text}
                        </Show>
                    </StyledUnlockGatedContent>
                </StyledLockedContentOverlayInnerColumn>
            </StyledLockedContentOverlayColumn>
        );
    }

    return (
        <StyledLockedContentOverlay onClick={openLoginMenu}>
            <StyledLockedContentOverlayInner hasHero={props.hasHero}>
                <LockIcon fill="red" size="xlarge" />
                <StyledUnlockGatedContent>
                    {localize('unlock-gated-content', 'Unlock gated content')}
                </StyledUnlockGatedContent>
            </StyledLockedContentOverlayInner>
        </StyledLockedContentOverlay>
    );
};
