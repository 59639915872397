import { Show, useContext } from 'solid-js';
import { Heading } from '../../../ui-components/heading/heading';
import { ProductData } from '../../product/product-types';
import {
    StyledBottomIconContainer,
    StyledProductCardInner,
    StyledProductCardWrapper,
    StyledThumbnailWrapper,
} from './product-card.style';
import { ImageComponent } from '../../image-component/image-component';
import { Text } from '../../../ui-components/text/text';
import { ProductIconTab } from '../../product/product-icon-tab/product-icon-tab';
import theme from '../../../style/theme';
import { ErrorCatcher } from '../../../tools/error-catcher';
import { AppContext } from '../../../app-context-provider/app-context-provider';
import { LockedContent } from '../../../ui-components/layouts/locked-content';
import { useLocation } from '@solidjs/router';
import { MyContentHandler } from '../../my-content-handler/my-content-handler';
import { isServer } from 'solid-js/web';
import { removeTrailingSlash } from '../../../tools/remove-trailing-slash';

export type ProductCardProps = {
    data: ProductData;
    inGrouping?: boolean;
};

export const ProductCard = (props: ProductCardProps) => {
    const { viewport, userState, pageRegistration, productsListingPage } = useContext(AppContext);

    const imageWidth = () => {
        if (viewport.width <= theme.breakpoints.TABLET && viewport.width > theme.breakpoints.MOBILE) {
            return 500; // Tablet
        }
        return 225; // Mobile and desktop
    };

    const isHiddenForPublic = () => {
        return props?.data?.isHealthcareProfessional 
            && !props?.data?.isVisibleForPublic 
            && !userState.user 
            && pageRegistration.url !== '';
    };

    const featuredImage = () => {
        const featuredImage = props?.data?.images?.find((image: { isFeatured: boolean }) => image.isFeatured);

        if (featuredImage) {
            return featuredImage.file?.url;
        }

        return props?.data?.images?.[0]?.file?.url;
    };

    const productsPageQuery = () => {
        if (isServer) {
            return '';
        }

        if (useLocation().pathname !== productsListingPage.url) {
            return '';
        }

        return useLocation().search;
    };

    return (
        <ErrorCatcher componentName="Product card">
            <div>
                <Show when={props?.data?.url}>
                    <StyledProductCardWrapper
                        href={isHiddenForPublic() ? useLocation().pathname : removeTrailingSlash(props?.data?.url)}
                        rel="noopener noreferrer"
                        isHiddenForPublic={isHiddenForPublic()}
                        inGrouping={props.inGrouping}
                        isServer={isServer}
                        state={{ productsPageQuery: productsPageQuery() }}
                    >
                        <StyledProductCardInner>
                            <StyledThumbnailWrapper>
                                <ProductIconTab iconName={props?.data?.icon} width="5rem" top="2rem" />
                                <Show when={props?.data?.images?.[0]} fallback={<div></div>}>
                                    <ImageComponent
                                        src={featuredImage()}
                                        height={'AUTO'}
                                        width={imageWidth()}
                                        altText={props?.data?.images[0].file?.alt || ''}
                                        title={props?.data?.images[0].file?.name || ''}
                                        imageText={''}
                                        aspectRatio={'standard'}
                                        roundCorners={false}
                                        spacer={false}
                                        ratio={16/10}
                                    />
                                </Show>
                            </StyledThumbnailWrapper>
                            <div>
                                <Heading tag="h5" variant="productNames">
                                    {props?.data?.post_title}
                                </Heading>
                                <Text fontSize="normal" lineLimit={6}>
                                    {props?.data?.shortDescription}
                                </Text>
                            </div>
                        </StyledProductCardInner>

                        <StyledBottomIconContainer>
                            <MyContentHandler
                                action='add'
                                type={'product'}
                                permalink={props?.data?.permalink}
                                title={props?.data?.post_title}
                                onlyIcon={true}
                            />
                        </StyledBottomIconContainer>
                        <Show when={isHiddenForPublic()}>
                            <LockedContent />
                        </Show>
                    </StyledProductCardWrapper>
                </Show>
                <MyContentHandler action='remove' permalink={props?.data?.permalink} />
            </div>
        </ErrorCatcher>
    );
};
