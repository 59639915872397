import { SvgIcon } from './svg-icon';
import { SvgIconProps } from './svg-icon.d';
import { mergeProps } from 'solid-js';

const defaultProps: SvgIconProps = {
    viewBox: '0 0 138 67',
    width: 9.86,
    height: 4.79,
};

export const AtosLogoWhite = (componentProps: SvgIconProps) => {
    const props = mergeProps(defaultProps, componentProps);

    return (
        <SvgIcon {...props}>
            <g id="Atos_Logo_Large-Tagline_Color_RGB" transform="translate(-7 -7.09)">
                <path id="Path_21670" data-name="Path 21670" d="M13.478,123.756a2.172,2.172,0,0,1-2.363,2.127H8V118.36h2.829c1.425,0,2.388.682,2.388,1.968A1.763,1.763,0,0,1,12.043,122a1.881,1.881,0,0,1,1.435,1.753ZM9.24,119.5V121.6h1.394a1.174,1.174,0,0,0,1.281-1.1c0-.63-.482-1.025-1.312-1.025Zm2.9,4.172a1.025,1.025,0,0,0-1.174-1.025H9.24v2.05h1.681a1.107,1.107,0,0,0,1.215-1.025Z" transform="translate(-0.488 -54.247)" fill="#fff"/>
                <path id="Path_21671" data-name="Path 21671" d="M21.59,128.232v-4.9h1.153v.892a1.537,1.537,0,0,1,1.312-1,2.1,2.1,0,0,1,.553.067v1.256a3.494,3.494,0,0,0-.512-.051,1.363,1.363,0,0,0-1.3,1.507v2.234Z" transform="translate(-7.113 -56.616)" fill="#fff"/>
                <path id="Path_21672" data-name="Path 21672" d="M31.315,123.26a2.255,2.255,0,0,1,2.4,2.455,4.2,4.2,0,0,1,0,.43H30.151c.041.7.641,1.174,1.614,1.174a3.183,3.183,0,0,0,1.676-.471v1.107a3.588,3.588,0,0,1-1.737.41,2.493,2.493,0,0,1-2.757-2.562,2.419,2.419,0,0,1,2.368-2.542Zm1.194,2.05a1.086,1.086,0,0,0-1.163-1.076,1.168,1.168,0,0,0-1.163,1.076Z" transform="translate(-10.698 -56.635)" fill="#fff"/>
                <path id="Path_21673" data-name="Path 21673" d="M44.131,127.561a1.789,1.789,0,0,1-1.614.815,2.455,2.455,0,0,1-2.45-2.562,2.409,2.409,0,0,1,2.409-2.527,1.809,1.809,0,0,1,1.625.81v-.7h1.163v4.869H44.131Zm-1.455-.21a1.537,1.537,0,0,0,0-3.018,1.425,1.425,0,0,0-1.4,1.5,1.445,1.445,0,0,0,1.384,1.522Z" transform="translate(-16.12 -56.647)" fill="#fff"/>
                <path id="Path_21674" data-name="Path 21674" d="M53.08,121.942v-.682h1.107v1.133h1.322v.974H54.187v2.3c0,.512.277.671.707.671a2.014,2.014,0,0,0,.615-.138v1.081a3.638,3.638,0,0,1-.984.138c-1.112,0-1.537-.564-1.537-1.676v-2.357H52.06v-.974h.63C53.054,122.393,53.08,122.305,53.08,121.942Z" transform="translate(-21.968 -55.66)" fill="#fff"/>
                <path id="Path_21675" data-name="Path 21675" d="M65.576,122.737v3.126H64.321V123.1a.969.969,0,0,0-.989-1.1,1.189,1.189,0,0,0-1.107,1.184v2.685H61v-7.5h1.2V121.7a1.824,1.824,0,0,1,3.352,1.025Z" transform="translate(-26.326 -54.247)" fill="#fff"/>
                <path id="Path_21676" data-name="Path 21676" d="M72.449,118.937a.723.723,0,0,1,.764-.769.712.712,0,0,1,.753.769.733.733,0,0,1-.784.753.717.717,0,0,1-.733-.753Zm.154,6.852v-4.915h1.209v4.894Z" transform="translate(-31.907 -54.153)" fill="#fff"/>
                <path id="Path_21677" data-name="Path 21677" d="M82.642,125.062v3.126H81.36v-2.767c0-.682-.364-1.1-.984-1.1a1.2,1.2,0,0,0-1.112,1.266v2.6h-1.2v-4.9h1.163v.8a1.819,1.819,0,0,1,3.418.974Z" transform="translate(-34.643 -56.572)" fill="#fff"/>
                <path id="Path_21678" data-name="Path 21678" d="M94.334,128.244a2.516,2.516,0,0,1-2.726,2.281,3.664,3.664,0,0,1-1.947-.594v-1.24a3.075,3.075,0,0,0,1.927.779,1.537,1.537,0,0,0,1.6-1.332v-.594a1.8,1.8,0,0,1-1.6.794,2.455,2.455,0,0,1-2.45-2.562,2.409,2.409,0,0,1,2.409-2.527,1.809,1.809,0,0,1,1.619.815v-.7h1.163Zm-1.168-2.414a1.409,1.409,0,1,0-.665,1.32,1.409,1.409,0,0,0,.665-1.32Z" transform="translate(-40.042 -56.628)" fill="#fff"/>
                <path id="Path_21679" data-name="Path 21679" d="M102.32,127.314v-.984h4.828v.984Z" transform="translate(-46.471 -58.132)" fill="#fff"/>
                <path id="Path_21680" data-name="Path 21680" d="M117.33,119.27c-.861,0-1.373.43-1.373.943,0,.651.641.83,1.414,1.1,1.112.384,2.465.774,2.465,2.327,0,1.307-1.153,2.209-2.737,2.209a3.956,3.956,0,0,1-2.316-.661v-1.414a3.439,3.439,0,0,0,2.306.9c.682,0,1.466-.313,1.466-.984s-.794-1.025-1.748-1.3-2.127-.733-2.127-2.188c0-1.286,1.174-2.1,2.629-2.1a3.469,3.469,0,0,1,2.147.62v1.327a3.68,3.68,0,0,0-2.127-.784Z" transform="translate(-52.496 -54.121)" fill="#fff"/>
                <path id="Path_21681" data-name="Path 21681" d="M132.707,125.784a2.45,2.45,0,0,1-2.45,2.562,1.845,1.845,0,0,1-1.573-.743v3.075H127.51v-7.313h1.163v.7a1.83,1.83,0,0,1,1.625-.81,2.409,2.409,0,0,1,2.409,2.527Zm-1.215.031a1.409,1.409,0,1,0-1.389,1.537,1.44,1.44,0,0,0,1.389-1.537Z" transform="translate(-58.751 -56.633)" fill="#fff"/>
                <path id="Path_21682" data-name="Path 21682" d="M141.815,123.26a2.255,2.255,0,0,1,2.4,2.455,4.193,4.193,0,0,1,0,.43h-3.562c.041.7.641,1.174,1.614,1.174a3.183,3.183,0,0,0,1.676-.471v1.107a3.588,3.588,0,0,1-1.737.41,2.493,2.493,0,0,1-2.757-2.562,2.419,2.419,0,0,1,2.368-2.542Zm1.194,2.05a1.086,1.086,0,0,0-1.163-1.076,1.168,1.168,0,0,0-1.163,1.076Z" transform="translate(-64.569 -56.635)" fill="#fff"/>
                <path id="Path_21683" data-name="Path 21683" d="M154.586,127.561a1.789,1.789,0,0,1-1.614.815,2.455,2.455,0,0,1-2.455-2.562,2.409,2.409,0,0,1,2.414-2.527,1.829,1.829,0,0,1,1.625.81v-.7h1.158v4.869h-1.127Zm-1.455-.21a1.537,1.537,0,0,0,0-3.018,1.42,1.42,0,0,0-1.4,1.5,1.44,1.44,0,0,0,1.4,1.522Z" transform="translate(-69.966 -56.647)" fill="#fff"/>
                <path id="Path_21684" data-name="Path 21684" d="M166.649,125.863l-2.024-2.286v2.286h-1.2v-7.5h1.2v4.7l1.917-2.086h1.594l-2.209,2.378,2.291,2.527Z" transform="translate(-76.258 -54.247)" fill="#fff"/>
                <path id="Path_21685" data-name="Path 21685" d="M174.439,118.937a.723.723,0,0,1,.764-.769.712.712,0,0,1,.753.769.733.733,0,0,1-.784.753.717.717,0,0,1-.733-.753Zm.159,6.852v-4.915h1.2v4.894Z" transform="translate(-81.629 -54.153)" fill="#fff"/>
                <path id="Path_21686" data-name="Path 21686" d="M184.661,125.116v3.126H183.4v-2.767c0-.682-.364-1.1-.984-1.1a1.2,1.2,0,0,0-1.112,1.266v2.6h-1.2v-4.9h1.163v.8a1.824,1.824,0,0,1,3.4.974Z" transform="translate(-84.39 -56.626)" fill="#fff"/>
                <path id="Path_21687" data-name="Path 21687" d="M196.324,128.243a2.514,2.514,0,0,1-2.721,2.281,3.669,3.669,0,0,1-1.953-.594v-1.24a3.075,3.075,0,0,0,1.932.779,1.537,1.537,0,0,0,1.6-1.332v-.594a1.8,1.8,0,0,1-1.6.794,2.455,2.455,0,0,1-2.455-2.562,2.409,2.409,0,0,1,2.409-2.527,1.809,1.809,0,0,1,1.625.81v-.7h1.163Zm-1.163-2.414a1.409,1.409,0,1,0-.665,1.32,1.409,1.409,0,0,0,.665-1.32Z" transform="translate(-89.765 -56.627)" fill="#fff"/>
                <path id="Path_21688" data-name="Path 21688" d="M204.31,127.314v-.984h4.833v.984Z" transform="translate(-96.193 -58.132)" fill="#fff"/>
                <path id="Path_21689" data-name="Path 21689" d="M222.352,124.71v1.153H217.56v-7.5h1.25v6.35Z" transform="translate(-102.653 -54.247)" fill="#fff"/>
                <path id="Path_21690" data-name="Path 21690" d="M229.089,118.937a.723.723,0,0,1,.764-.769.712.712,0,0,1,.753.769.733.733,0,0,1-.784.753.717.717,0,0,1-.733-.753Zm.154,6.852v-4.915h1.2v4.894Z" transform="translate(-108.272 -54.153)" fill="#fff"/>
                <path id="Path_21691" data-name="Path 21691" d="M235.072,123.47l1.3,3.654,1.317-3.654h1.363l-2.05,4.9h-1.281l-1.973-4.9Z" transform="translate(-110.546 -56.738)" fill="#fff"/>
                <path id="Path_21692" data-name="Path 21692" d="M245.809,118.937a.762.762,0,1,1,1.522,0,.738.738,0,0,1-.789.753.717.717,0,0,1-.733-.753Zm.159,6.852v-4.915h1.2v4.894Z" transform="translate(-116.424 -54.153)" fill="#fff"/>
                <path id="Path_21693" data-name="Path 21693" d="M255.981,125.116v3.126H254.72v-2.767c0-.682-.364-1.1-.984-1.1a1.2,1.2,0,0,0-1.112,1.266v2.6h-1.2v-4.9h1.163v.8a1.824,1.824,0,0,1,3.4.963Z" transform="translate(-119.16 -56.626)" fill="#fff"/>
                <path id="Path_21694" data-name="Path 21694" d="M267.694,128.243a2.514,2.514,0,0,1-2.721,2.281,3.644,3.644,0,0,1-1.947-.594v-1.24a3.075,3.075,0,0,0,1.927.779,1.537,1.537,0,0,0,1.6-1.332v-.594a1.8,1.8,0,0,1-1.6.794,2.455,2.455,0,0,1-2.455-2.562,2.409,2.409,0,0,1,2.409-2.527,1.809,1.809,0,0,1,1.625.81v-.7h1.163Zm-1.174-2.434a1.4,1.4,0,1,0-1.414,1.5,1.43,1.43,0,0,0,1.414-1.481Z" transform="translate(-124.559 -56.627)" fill="#fff"/>
                <path id="Path_21695" data-name="Path 21695" d="M96.936,18.406V8.32h9.532V18.406h8.876v7.979h-8.876V40.621c0,3.013,2.05,4.038,4.694,4.038a14.3,14.3,0,0,0,4.33-1.025v8.8a28.33,28.33,0,0,1-7.487,1.025c-8.072-.143-11.274-3.961-11.274-12.253V26.385H90.32V18.406Z" transform="translate(-40.62 -0.6)" fill="#fff"/>
                <path id="Path_21696" data-name="Path 21696" d="M145.86,44.654c0-10.762,8.2-17.834,18.567-17.834s18.639,7.047,18.639,17.834-8.292,17.829-18.639,17.829S145.86,55.442,145.86,44.654Zm27.377-.077a8.319,8.319,0,0,0-8.661-8.656c-4.771,0-8.881,3.29-8.881,8.656a8.791,8.791,0,0,0,8.712,9.1,8.9,8.9,0,0,0,8.83-9.1Z" transform="translate(-67.697 -9.619)" fill="#fff"/>
                <path id="Path_21697" data-name="Path 21697" d="M237.4,54.491c2.347,0,3.89-1.1,3.89-2.788,0-2.05-1.322-2.562-5.724-4.038-6.016-1.978-9.983-3.3-9.983-10.639,0-5.8,4.843-10.5,13.212-10.5,7.851,0,10.859,2.275,10.859,2.275v8.809a18.526,18.526,0,0,0-9.906-3.075c-2.788,0-4.259,1.025-4.259,2.275,0,1.466.958,2.5,5.212,3.741,6.314,1.912,10.865,4.33,10.865,11.008,0,5.284-4.187,10.865-14.385,10.865a24.973,24.973,0,0,1-11.449-2.644V49.94A18.4,18.4,0,0,0,237.4,54.491Z" transform="translate(-106.563 -9.477)" fill="#fff"/>
                <path id="Path_21698" data-name="Path 21698" d="M16.737,52.142l2.2-10.342h18.69l2.2,10.342h9.706L42.94,20.6C40.89,10.816,35,7.09,28.273,7.09c-6.555,0-13.032,3.69-14.667,13.509L7,52.142Zm6.242-30.749c.835-3.587,2.983-4.812,5.294-4.812,2.2,0,4.382.958,5.294,4.8l2.158,11.454H20.837Z" transform="translate(0 0)" fill="#fff"/>
            </g>
        </SvgIcon>
    );
};
