import { Match, Switch, useContext } from 'solid-js';
import { Title } from 'solid-meta';
import { AppContext } from '../../app-context-provider/app-context-provider';
import DynamicModuleGenerator from '../../dynamic-module-generator';
import { ErrorCatcher } from '../../tools/error-catcher';
import { GET_PAGE_QUERY } from '../page/page';
import { SeoSettings } from '../seo-settings/seo-settings';
import { Styled404 } from './404.styles';
import { Heading } from '../../ui-components/heading/heading';
import { Button } from '../../ui-components/button/button';

export const NotFound = () => {
    const { createCachedResource, page404, localize, siteInfo } = useContext(AppContext);

    const [page404Content] = createCachedResource(GET_PAGE_QUERY, () => ({ key: page404.url }), true);

    const pageTitle = () => {
        return siteInfo.siteType === 'atos-care' ? 'Atos Care' : 'Atos Medical';
    };
    
    return (
        <ErrorCatcher componentName='Not Found'>
            <Switch>
                <Match when={!page404Content() || !page404Content()?.page}>
                    <Title>{`${localize('page-not-found', 'Page not found')} - ${pageTitle()}`}</Title>
                    <Styled404>
                        <Heading tag='h1' variant='xlarge'>{localize('page-not-found', 'Page not found')}</Heading>
                        <Button label={localize('back-to-home', 'Back to home')} isLink url='/' />
                    </Styled404>
                </Match>

                <Match when={page404?.url && page404.url !== ''}>
                    <SeoSettings
                        seo={page404Content()?.page?.content?.seo}
                        title={page404Content()?.page?.content?.post_title}
                    />
                    <DynamicModuleGenerator
                        content={page404Content()?.page?.content.blocks}
                        permalink={page404Content()?.page?.content.permalink}
                        pageTitle={page404Content()?.page?.content.post_title}
                    />
                </Match>
            </Switch>
        </ErrorCatcher>
    );
};
