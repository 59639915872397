import { Component, Show } from 'solid-js';
import { StyledModalBody, StyledModalContent, StyledModalHeader, StyledModalWrapper } from './modal.styles';
import { ModalProps } from './modal.d';
import { Section } from '../../grid-system/section/section';
import theme from '../../style/theme';
import { ErrorCatcher } from '../../tools/error-catcher';
import { CrossIcon } from '../../components/icons-library/cross';
import { Portal } from 'solid-js/web';

export const Modal: Component<ModalProps> = (props: ModalProps) => {
    let modalRef: HTMLDivElement | undefined;

    const handleModalClose = () => {
        document.body.style.overflowY = 'auto';
        props.onClose();
    };

    const handleOverlayClick = (e: MouseEvent) => {
        if (!modalRef?.contains(e.target as Node)) {
            handleModalClose();
            return;
        }
    };
    
    return (
        <ErrorCatcher componentName='Modal'>
            <Portal mount={document.getElementById('root')!}>
                <Show when={props.visible}>
                    <StyledModalWrapper darkenBackground={props.darkenBackground} onClick={handleOverlayClick}>
                        <Section
                            widthType={'bgFull'}
                            templateShorthand={[12]}
                            equalHeight={true}
                            removeSpacingBlock={true}
                        >
                            <StyledModalContent size={props.size} ref={modalRef}>
                                <StyledModalBody>
                                    <StyledModalHeader>
                                        <CrossIcon fill={theme.palette.lightGray} size={'medium'} onClick={handleModalClose} />
                                    </StyledModalHeader>
                                    <div>
                                        {props.children}
                                    </div>
                                </StyledModalBody>
                            </StyledModalContent>
                        </Section>
                    </StyledModalWrapper>
                </Show>
            </Portal>
        </ErrorCatcher>
    );
};
