import { createStore } from 'solid-js/store';
import { gql } from 'graphql-request';
import { Show, useContext } from 'solid-js';
import { Grid } from '../../grid-system/grid/grid';
import { Section } from '../../grid-system/section/section';
import { ErrorCatcher } from '../../tools/error-catcher';
import { gridSettings } from '../../ui-components/layouts/sidebar.style';
import { EventsContainer } from './events-container';
import { EventsSidebar } from './events-sidebar';
import { EventsProps } from './events-types';
import { fetchEvents } from './helpers/fetch-events';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { useNavigate } from '@solidjs/router';
import createClickHelper from './helpers/filters-handle-on-click-helper';
import { EventData } from '../event/event-types';
import { LoadingPlaceHolder } from '../loading-place-holder/loading-place-holder';

const GET_EVENTS_QUERY = gql`
    query GetEvents(
            $siteId: String,
            $type: [String],
            $tags: [String],
            $limit: Int,
            $fromDate: String,
            $toDate: String
        ) {
        events: resources(
                siteId: $siteId,
                type: $type,
                tags: $tags,
                limit: $limit,
                order: DESC,
                fromDate: $fromDate,
                toDate: $toDate
            ) {
            content
        }
    }
`;


export const Events = (props: EventsProps) => {
    const { siteDomains, createCachedResource, siteInfo } = useContext(AppContext);
    const [rawData, setRawData] = createStore<{ list: EventData[]}>({ list: []});
    const navigate = useNavigate();

    const clickHelper = createClickHelper(navigate);

    const isInternationalSite = !!siteDomains.find((site) => site.siteId === siteInfo.siteId && site.country === 'international');

    const eventsData = fetchEvents({});

    for (const site of siteDomains) {
        const [siteEvents] = createCachedResource(GET_EVENTS_QUERY, () => ({ siteId: site.siteId, type: ['event'] }), true) as any;
        
        const siteEventsObject = {
            siteDomain: site,
            siteEvents,
        };

        setRawData('list', (l: any) => [...l, siteEventsObject]);
    }

    const getWorldwideEvents = () => {        
        const loaded = rawData.list.reduce((acc: any, site: any) => {
            return acc && Boolean(site.siteEvents());
        }, true);

        if (!loaded) {
            return [];
        }

        const flatList = rawData.list.reduce((acc: any, site: any) => {
            const siteEvents = site.siteEvents().events.map((event: any) => {
                const baseUrl = site.siteDomain.domains[ siteInfo.target ];
                const url = event?.content.url;
    
                const newEvent = {
                    ...event?.content,
                    url: `${siteInfo.protocol}://${baseUrl}/${url}`,
                };

                return newEvent;
            });

            return [...acc, ...siteEvents];
        }, []);

        flatList.sort((a: any, b: any) => {
            const dateA = new Date(a?.eventDate).getTime();
            const dateB = new Date(b?.eventDate).getTime();
            return dateA - dateB;
        });

        return flatList;
    };

    const filterByAudience = (post: EventData) => {
        switch (props.audience) {
            case 'all': return true;
            case 'patients': return !post?.isHealthcareProfessional;
            case 'hcp': return post?.isHealthcareProfessional;
            default: return true;
        };
    };

    const filteredEvents = () => {
        const events: EventData[] = isInternationalSite ? getWorldwideEvents() : eventsData();

        const filteredByAudience = events?.filter(filterByAudience);

        return filteredByAudience;
    };
  
    return (
        <ErrorCatcher componentName="Events list">
            <Show when={filteredEvents()} fallback={<LoadingPlaceHolder />}>
                <Section
                    templateShorthand={[12]}
                    widthType={'bgFull'}
                    heightType={'fill'}
                    backgroundType={'color'}
                    backgroundValue={'white'}
                    removeSpacingBlock={true}
                    customCss={'padding-top: 0; padding-bottom: 0;'}
                >
                    <Grid {...gridSettings.container}>
                        <Show when={filteredEvents().length}>
                            <EventsSidebar
                                events={filteredEvents()}
                                labels={props?.labels}
                                clickHelper={clickHelper}
                                isInternationalSite={isInternationalSite}
                            />

                            <EventsContainer
                                events={filteredEvents()}
                                labels={props?.labels}
                                clickHelper={clickHelper}
                                isInternationalSite={isInternationalSite}
                            />
                        </Show>
                    </Grid>
                </Section>
            </Show>
        </ErrorCatcher>
    );
};

Events.parseProps = (atts: any) => {
    return {
        tag: atts.tag,
        labels: atts.labels,
        audience: atts.audience,
    };
};
