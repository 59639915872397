import { Link, useLocation } from '@solidjs/router';
import clsx from 'clsx';
import { Show, createSignal, onCleanup, onMount, useContext } from 'solid-js';
import { AppContext } from '../../../app-context-provider/app-context-provider';
import { Grid } from '../../../grid-system/grid/grid';
import headerStore from '../../../stores/header-store';
import theme from '../../../style/theme';
import { ErrorCatcher } from '../../../tools/error-catcher';
import { AtosCareLogo } from '../../icons-library/atos-care-logo';
import { CalendarIcon } from '../../icons-library/calendar';
import { GET_PRIMARY_MENU_QUERY } from '../../menu/menu';
import { PrimaryMenuData } from '../../menu/types/primary-menu-types';
import { GET_PAGE_QUERY } from '../../page/page';
import { HeaderAtosCareDropdownMenu } from './header-atos-care-dropdown-menu';
import { HeaderAtosCareNavigationShortcuts } from './header-atos-care-navigation-shortcuts';
import { FrostedBackground, StyledHeader, StyledIcon } from './header-atos-care.style';
import urlMatches from '../../../tools/url-match';
import removeAmpersand from '../../../tools/remove-ampersand';
import { BasketContext } from '../../basket/basket-context-provider';

export const HeaderAtosCareContainer = () => {
    const location = useLocation();
    const { createCachedResource, viewport, blogsNewsListingPage, eventsListingPage } = useContext(AppContext);
    const { basket } = useContext(BasketContext);
    const { floatHeader, setFloatHeader } = headerStore;

    const [scrollDirection, setScrollDirection] = createSignal('down');
    
    const [pageContent] = createCachedResource(GET_PAGE_QUERY, () => ({ key: location.pathname }), true);
    const isHcpPage = () => {
        if (pageContent()?.page?.content?.isHealthcareProfessional) {
            return true;
        }
        return false;
    };

    const menuLocation = isHcpPage() ? 'primary_menu_hcp' : 'primary_menu';

    const [primaryMenuContent] = createCachedResource(GET_PRIMARY_MENU_QUERY, () => ({ key: `/wp-json/content/v1/menus/${menuLocation}` }), true);
    const primaryMenuData = (): PrimaryMenuData => primaryMenuContent()?.menu?.content;

    const isOriginalTablet = () => viewport.width <= theme.breakpoints.ORIGINAL_TABLET;

    let headerRef: any;
    onMount(() => {
        const pageAtTopObserver = new IntersectionObserver(
            (payload) => {
                const pageAtTop = payload[0].isIntersecting;
                setFloatHeader(!pageAtTop);
            },
            {
                threshold: 1,
                // Else the floating header will be triggered too soon if we don't have a hero at the top.
                rootMargin: '-65px',
            }
        );

        if (headerRef) {
            pageAtTopObserver.observe(headerRef);
        }

        onCleanup(() => {
            pageAtTopObserver.unobserve(headerRef);
        });
    });

    onMount(() => {
        let lastScrollY = window.scrollY;

        const scrollHandler = () => {
            if (lastScrollY > window.scrollY) {
                setScrollDirection('up');
            } else {
                setScrollDirection('down');
            }

            lastScrollY = window.scrollY;
        };

        window.addEventListener('scroll', scrollHandler);
    });

    return (
        <ErrorCatcher componentName="Header">
            <StyledHeader floatHeader={floatHeader()} isOriginalTablet={isOriginalTablet()}>
                <Grid templateShorthand={[12]} customCss={'height: inherit;'} isLayoutGrid={true}>
                    <div class="flex justify-between items-center relative h-full z-[3] gap-8">
                        <FrostedBackground />
                        <div class="left-column">
                            <div
                                class={clsx('w-[12.182rem] h-[6.1rem] ease-in-out duration-200', {
                                    'w-[8rem] h-[4rem]': floatHeader() || isOriginalTablet(),
                                })}
                            >
                                <Link href="/">
                                    <AtosCareLogo width="100%" height="100%" aria-label="Atos Care Logo" altText="Atos Care Logo" />
                                </Link>
                            </div>
                        </div>

                        <div class={clsx('right-column flex flex-col w-full')}>
                            <div
                                class={clsx('first-row', {
                                    // transition is defined in header-atos-care-tranisition.css
                                    'is-open': basket.order.orderDetails.length > 0 || !floatHeader() || scrollDirection() === 'up' || isOriginalTablet(),
                                    'border-b border-solid border-gray-dark': (!floatHeader() || scrollDirection() === 'up') && !isOriginalTablet(),
                                })}
                            >
                                <HeaderAtosCareNavigationShortcuts />
                            </div>
                            <Show when={!isOriginalTablet()}>
                                <div class={clsx('second-row text-center flex justify-between w-full py-[8px]')}>
                                    <HeaderAtosCareDropdownMenu primaryMenuData={primaryMenuData()} />

                                    <nav class="flex gap-4 items-top">
                                        <Show when={blogsNewsListingPage.url}>
                                            <Link href={blogsNewsListingPage.url}>
                                                <StyledIcon active={urlMatches(blogsNewsListingPage.url)}>
                                                    <span class="uppercase">{removeAmpersand(blogsNewsListingPage.title)}</span>
                                                </StyledIcon>
                                            </Link>
                                        </Show>
                                        <Show when={eventsListingPage?.url}>
                                            <Link href={eventsListingPage.url}>
                                                <StyledIcon active={urlMatches(eventsListingPage.url)}>
                                                    <div class="absolute left-0">
                                                        <CalendarIcon height={2.25} width={1.7} />
                                                    </div>
                                                    <span class="uppercase">{removeAmpersand(eventsListingPage.title)}</span>
                                                </StyledIcon>
                                            </Link>
                                        </Show>
                                    </nav>
                                </div>
                            </Show>
                        </div>
                    </div>
                </Grid>
            </StyledHeader>
            <div ref={headerRef} data-intersectionObserver />
        </ErrorCatcher>
    );
};
