/* eslint-disable indent */

import { styled } from 'solid-styled-components';
import theme, { palette } from '../../style/theme';
import { overlayStyle } from '../../ui-components/layouts/background-image-overlay';
import { HeroComponentStyleProps } from './hero-types';
import { containerQueries } from '../../tools/check-container-support';

export const StyledHeroWrapper = styled.div<HeroComponentStyleProps | undefined>`
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100vh;
    min-height: 65rem;

    ${(p) =>
        p.backgroundImageSrcData && p.backgroundImageSrcData.original !== ''
            ? `
                background-image: url(${p.backgroundImageSrcData.original});
                background-repeat: no-repeat;
                background-size: cover;
                background-position: ${p.focusPosition || 'center'};
                ${overlayStyle(p.overlayType)}
            `
            : ''
    }
    h2 {
        max-width: 57.1rem;
    }

    p {
        margin: 1.43rem 0;
        max-width: 50rem;
        padding-bottom: 0 !important;
    }

    ${theme.mediaQueries.mobileMax} {
        background: none;
    }

    @media only screen and (max-height: 700px) {
        h2 {
            max-width: 70rem;
        }
    }
`;

export const StyledEmptyColumn = styled.div`
    ${containerQueries('mobileMax')} {
        display: none;
    }
`;

export const StyledInnerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    z-index: 1;
    ${theme.mediaQueries.mobileMax} {
        justify-content: flex-start;
    }
`;

export const StyledContentWrapper = styled.div<{ isAtosCare?: boolean }>`
    margin-top: 23vh;
    margin-bottom: 0vh;
    display: block;
    padding-inline: 1.5rem;

    ${containerQueries('mobileMax')} {
        margin-top: 27%;
        margin-bottom: 10%;
    }

    ${(p) =>
        p.isAtosCare
            ? `
        margin-top: 32vh;
        ${containerQueries('mobileMax')} {
            margin-top: calc(82px + 27%);
            margin-bottom: 10%;
        }
    `
            : ''}

    

    
`;

export const StyledHeadings = styled.div`
    ${theme.mediaQueries.mobileMax} {
        text-align: center;
        margin-bottom: 3rem;
    }
`;

export const StyledLinkWrapper = styled.div`
    padding: 2.14rem 0;
`;

export const StyledScrollDownContainer = styled.div`
    margin: 0 auto;
    margin-top: auto;
    margin-bottom: 3rem;
    ${theme.mediaQueries.mobileMax} {
        display: none;
    }
    svg {
        cursor: pointer;
        width: 2.14rem;
        height: 2.14rem;
        fill: ${palette.white};
    }
    & > button {
        border: none;
        background: transparent;
    }
`;

export const StyledMobileImageContainer = styled.div<HeroComponentStyleProps>`
    display: none;
    ${theme.mediaQueries.mobileMax} {
        display: flex;
        flex-grow: 1;
        justify-content: center;
        margin-inline: -1.14rem;

        ${(p) =>
            p.backgroundImageSrcData && p.backgroundImageSrcData.original !== ''
                ? `
                background-image: url(${p.backgroundImageSrcData.original});
                background-position: ${p.focusPosition || 'center'};
                background-repeat: no-repeat;
                background-size: cover;
            `
                : ''}
    }

    #learnMoreMobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        align-self: flex-end;
        margin-bottom: 1rem;
    }
`;

export const StyledIconWrapper = styled.div`
    margin-top: 0.7rem;
`;

export const StyledSwirlMobile = styled.div`
    position: absolute;
    width: 100%;
    bottom: -8rem;
    pointer-events: none;
    & svg {
        width: 100%;
    }
`;

export const StyledSwirlDesktop = styled.div`
    position: absolute;
    width: 100%;
    bottom: calc(0px - 0.3 * 100vw / 2);
    pointer-events: none;
`;

export const StyledHeroAndSwirlContainer = styled.div`
    position: relative;
`;

export const StyledScrollDownButton = styled.button`
    width: 56px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
`;