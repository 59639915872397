import { onMount, Show, useContext } from 'solid-js';
import { Link, Meta, Title } from 'solid-meta';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { ErrorCatcher } from '../../tools/error-catcher';
import { getBackgroundImageSrcData, ImageSrcData } from '../image-component/image-component';
import { Seo } from './seo-settings-types';
import { useLocation } from '@solidjs/router';
import { removeTrailingSlash } from '../../tools/remove-trailing-slash';
import removeAmpersand from '../../tools/remove-ampersand';

type SeoSettingsProps = {
    seo?: Seo;
    title?: string;
};

// this is a test

export const SeoSettings = (props: SeoSettingsProps) => {
    const { productContactFormUrl, imagesServiceUrl, supportedImageFormats, siteDomains, siteInfo } = useContext(AppContext);
    const location = useLocation();
    
    onMount(() => {
        // make title update manually for chrome
        document.title = customPageTitle();
    });

    const canonicalUrl = () => {
        if (props.seo && props.seo.useCanonicalUrl && props.seo.canonicalUrl) {
            return props.seo.canonicalUrl;
        }

        const currentSite = siteDomains.find(site => site.siteId === siteInfo.siteId);
        const currentDomain = currentSite?.domains[siteInfo.target];

        if (location.pathname === productContactFormUrl) {
            const canonical = 'https://' + currentDomain + productContactFormUrl;    
            return canonical;
        } 

        const defaultCanonical = `https://${currentDomain}${location.pathname}`;

        return defaultCanonical;
    };
    
    const customPageTitle = () => {
        const siteName = siteInfo.siteType === 'atos-care' ? 'Atos Care' : 'Atos Medical';
        let title = siteName;

        if (props.seo && props.seo.useCustomPageTitle && props.seo.pageTitle) {
            title = `${props.seo.pageTitle.replace(`- ${siteName}`, '')} - ${siteName}`;
        } else if (props.title) {
            title = `${props.title} - ${siteName}`;
        }

        return removeAmpersand(title);
    };

    let backgroundImageSrcData = null;
    if (props.seo && props.seo.use_og_image && props.seo.og_image) {
        const backgroundImage: ImageSrcData = getBackgroundImageSrcData({
            imagesServiceUrl,
            supportedImageFormats,
            src: typeof props.seo?.og_image === 'string' ? props.seo?.og_image : props.seo?.og_image?.url,
            width: 1200,
            height: 'AUTO',
        });

        backgroundImageSrcData = backgroundImage;
    }

    return (
        <ErrorCatcher componentName="SEO settings">
            <Title>{customPageTitle()}</Title>
            <Show when={props.seo && props.seo.useMetaDescription && props.seo.metaDescription}>
                <Meta name="description" content={props.seo?.metaDescription || ''} />
                <Meta name="og:description" content={props.seo?.metaDescription || ''} />
            </Show>
            <Show
                when={props.seo && props.seo.use_og_title && props.seo.og_title}
                fallback={<Meta name="title" property="og:title" content={customPageTitle()} />}
            >
                <Meta name="twitter:title" content={props.seo?.og_title || ''} />
                <Meta name="title" property="og:title" content={props.seo?.og_title || ''} />
            </Show>
            <Show when={props.seo && props.seo.use_og_description && props.seo.og_description}>
                <Meta name="og:description" content={props.seo?.og_description || ''} />
                <Meta name="twitter:description" content={props.seo?.og_description || ''} />
            </Show>
            <Show when={canonicalUrl()}>
                <Link rel="canonical" href={removeTrailingSlash(canonicalUrl())} />
            </Show>
            <Show when={props.seo && props.seo.use_og_image && props.seo.og_image}>
                <Meta name="image" property="og:image" content={backgroundImageSrcData?.original} />
                <Meta name="twitter:image" content={backgroundImageSrcData?.original} />
            </Show>
            <Show when={props.seo && props.seo.noIndex}>
                <Meta name="robots" content="none" /> {/* Equivalent to noindex, nofollow. */}
            </Show>
        </ErrorCatcher>
    );
};
